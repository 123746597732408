import React, { useState, useEffect, useParams, Fragment } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

import { Divider, Typography, Box, DialogContentText, Link } from '@mui/material';

import LargeLine from './LargeLine';
import CheckEdLanStatusApiCall from './CheckEdLanStatusApiCall';
import CheckEdHealthStatusApiCall from './CheckEdHealthStatusApiCall';
import CheckWanInterfaceStatusApiCall from './CheckWanInterfaceStatusApiCall';
import CheckWanIpReachableStatusApiCall from './CheckWanIpReachableStatusApiCall';
import CheckDgIpReachableStatusApiCall from './CheckDgIpReachableStatusApiCall';
import CheckSiteDownStatusApiCall from './CheckSiteDownStatusApiCall';
import CheckRechabilityOverInternetApiCall from './CheckRechabilityOverInternetApiCall';
import CheckEdLanStatusApiCall2 from './CheckEdLanStatusApiCall2';


export default function TroubleshootComponent(props) {
    let gwInternetTimer;
    const { openTroubleshoot, setOpenTroubleshoot, ispParams } = props;

    const custId = localStorage.getItem("cust_id");

    const [edAddedStatus, setEdAddedStatus] = useState(false);

    const [gwInternetStatus, setGwInternetStatus] = useState(false);
    const [siteDownStatus, setSiteDownStatus] = useState(false);
    const [edLanStatus, setEdLanStatus] = useState(false);
    const [edPortStatus, setEdPortStatus] = useState(false);
    const [edHealthStatus, setEdHealthStatus] = useState(false);
    const [edInterfaceStatus, setEdInterfaceStatus] = useState(false);
    const [wanIpLanStatus, setWanIpLanStatus] = useState(false);
    const [internetRechStatus, setInternetRechStatus] = useState(false);
    const [dgLanStatus, setDgLanStatus] = useState(false);

    const [gotGwInternetStatus, setGotGwInternetStatus] = useState(false);
    const [gotSiteDownStatus, setGotSiteDownStatus] = useState(false);
    const [gotEdLanStatus, setGotEdLanStatus] = useState(false);
    const [gotEdPortStatus, setGotEdPortStatus] = useState(false);
    const [gotEdHealthStatus, setGotEdHealthStatus] = useState(false);
    const [gotEdInterfaceStatus, setGotEdInterfaceStatus] = useState(false);
    const [gotWanIpLanStatus, setGotWanIpLanStatus] = useState(false);
    const [gotInternetRechStatus, setGotInternetRechStatus] = useState(false);
    const [gotDgLanStatus, setGotDgLanStatus] = useState(false);

    const [continueSiteDownStatus, setContinueSiteDownStatus] = useState(false);
    const [continueEdLanStatus, setcontinueEdLanStatus] = useState(false);
    const [continueEdPortStatus, setContinueEdPortStatus] = useState(false)
    const [continueEdHealthStatus, setContinueEdHealthStatus] = useState(false);
    const [continueEdInterfaceStatus, setContinueEdInterfaceStatus] = useState(false);
    const [continueWanIpLanStatus, setContinueWanIpLanStatus] = useState(false);
    const [continueInternetRechStatus, setContinueInternetRechStatus] = useState(false);
    const [continueDgLanStatus, setContinueDgLanStatus] = useState(false);

    const [code, setCode] = useState(-1);

    const [siteDownStatusMessage, setSiteDownStatusMessage] = useState("");
    const [edLanStatusMessage, setEdLanStatusMessage] = useState("");
    const [fwedLanStatusMessage, fwsetEdLanStatusMessage] = useState("");
    const [edPortStatusMessage, setEdPortStatusMessage] = useState("");
    const [edHealthStatusMessage, setEdHealthStatusMessage] = useState("");
    const [edInterfaceMessage, setEdInterfaceMessage] = useState("");
    const [wanIpReachableMessage, setWanIpReachableMessage] = useState("");
    const [dgIpReachableMessage, setDgIpReachableMessage] = useState("");
    const [internetRechStatusMessage, setInternetRechStatusMessage] = useState("");

    const [intDgLatency, setIntDgLatency] = useState("");
    const [intDgPacketLoss, setIntDgPacketLoss] = useState("");

    const [extDgLatency, setExtDgLatency] = useState("");
    const [extDgPacketLoss, setExtDgPacketLoss] = useState("");

    const [detailSiteDownStatusMessage, setDetailSiteDownStatusMessage] = useState("");
    const [detailEdLanStatusMessage, setDetailEdLanStatusMessage] = useState("");
    const [fwdetailEdLanStatusMessage, fwsetDetailEdLanStatusMessage] = useState("");
    const [detailEdPortStatusMessage, setDetailEdPortStatusMessage] = useState("");
    const [detailEdHealthStatusMessage, setDetailEdHealthStatusMessage] = useState("");
    const [detailEdInterfaceMessage, setDetailEdInterfaceMessage] = useState("");
    const [detailWanIpReachableMessage, setDetailWanIpReachableMessage] = useState("");
    const [detailDgIpReachableMessage, setDetailDgIpReachableMessage] = useState("");
    const [detailInternetRechStatusMessage, setDetailInternetRechStatusMessage] = useState("");

    const [rcaSiteDownStatusMessage, setRcaSiteDownStatusMessage] = useState("");
    const [rcaEdLanStatusMessage, setRcaEdLanStatusMessage] = useState("");
    const [fwrcaEdLanStatusMessage, fwsetRcaEdLanStatusMessage] = useState("");
    const [rcaEdPortStatusMessage, setRcaEdPortStatusMessage] = useState("");
    const [rcaEdHealthStatusMessage, setRcaEdHealthStatusMessage] = useState("");
    const [rcaEdInterfaceMessage, setRcaEdInterfaceMessage] = useState("");
    const [rcaWanIpReachableMessage, setRcaWanIpReachableMessage] = useState("");
    const [rcaDgIpReachableMessage, setRcaDgIpReachableMessage] = useState("");
    const [rcaInternetRechStatusMessage, setRcaInternetRechStatusMessage] = useState("");
    const [greenPass, setGreenPass] = useState(false);
    const [redFail, setRedFail] = useState(false);
    const [redFails, setRedFails] = useState(false);
    const [greenAuth, setGreenAuth] = useState(false);
    const [redFailAuth, setRedFailAuth] = useState(false);
    const [redFailAuths, setRedFailAuths] = useState(false);
    const [greenInternet, setGreenInternet] = useState(false);
    const [redFailInternet, setRedFailInternet] = useState(false);
    const [redFailsInternet, setRedFailsInternet] = useState(false);
    const [checkStatus, setCheckStatus] = useState(false);
    const [checkStates, setCheckStates] = useState(false);
    const [checkWanStatus, setCheckWanStatus] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [internetReachableMeraki, setInternetReachableMeraki] = useState(false);
    const [wanPriority, setWanPriority] = useState(false)
    const [wanCode, setWanCode] = useState(-1)

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleClose = () => {
        setOpenTroubleshoot(false);
    }

    const checkDgLanStatus = () => {
        CheckDgIpReachableStatusApiCall(ispParams.isp_wan_id, setDgLanStatus, setGotDgLanStatus, setDgIpReachableMessage, setIntDgLatency, setIntDgPacketLoss, setDetailDgIpReachableMessage, setRcaDgIpReachableMessage, setCheckStates);
    }

    useEffect(() => {
        if (ispParams.link_type === 'ILL' || ispParams.link_type === 'Broadband' || ispParams.link_type === 'MPLS' || ispParams.link_type === 'P2P') {

            if (continueDgLanStatus) {
                // if(internetRechStatusMessage === "Internet is Reachable" && wanCode !== 3)
                if (internetRechStatusMessage === "Internet is Reachable") {
                    setIntDgLatency("0")
                    setIntDgPacketLoss("100")
                    setDgLanStatus(true)
                    setGotDgLanStatus(true)
                    setDgIpReachableMessage("Provider Equipment(PE) is reachable from Customer Equipment(CE)")
                    setDetailDgIpReachableMessage("Provider Equipment(PE) is reachable from Customer Equipment(CE)")
                    setRcaDgIpReachableMessage("Provider Equipment(PE) is reachable from Customer Equipment(CE)")
                    setCheckStates(true)

                }
                else {
                    checkDgLanStatus();
                }

            }
        }

    }, [continueDgLanStatus])

    const checkWanIpLanStatus = async () => {
        CheckWanIpReachableStatusApiCall(ispParams.isp_wan_id, setWanIpLanStatus, setGotWanIpLanStatus, setWanIpReachableMessage, setContinueInternetRechStatus, setDetailWanIpReachableMessage, setRcaWanIpReachableMessage, setWanPriority, setWanCode);
    }

    useEffect(() => {
        if (continueWanIpLanStatus && ispParams.ed_vendor_id !== 10) {
            checkWanIpLanStatus();
        }
    }, [continueWanIpLanStatus])

    const checkEdInterfaceStatus = async () => {
        await CheckWanInterfaceStatusApiCall(ispParams.isp_wan_id, setEdInterfaceStatus, setGotEdInterfaceStatus, setEdInterfaceMessage, setContinueWanIpLanStatus, setDetailEdInterfaceMessage, setRcaEdInterfaceMessage, ispParams.ed_vendor_id, setContinueInternetRechStatus, setCheckWanStatus)
    }

    useEffect(() => {
        if (continueEdInterfaceStatus) {
            checkEdInterfaceStatus();
        }
    }, [continueEdInterfaceStatus])



    const checkEdLanStatus = async () => {
        await CheckEdLanStatusApiCall(ispParams.isp_wan_id, setEdLanStatus, setGotEdLanStatus, setEdLanStatusMessage, setContinueEdHealthStatus, setEdAddedStatus, setDetailEdLanStatusMessage, setEdPortStatus, setGotEdPortStatus, setContinueEdPortStatus, setCode, setRcaEdLanStatusMessage, fwsetEdLanStatusMessage, fwsetDetailEdLanStatusMessage, fwsetRcaEdLanStatusMessage, setGreenPass, setRedFail, setRedFails);

    }
    const checkPortLanStatus = async () => {
        await CheckEdLanStatusApiCall2(ispParams.isp_wan_id, setEdLanStatus, setGotEdLanStatus, setEdLanStatusMessage, setContinueEdHealthStatus, setEdAddedStatus, setDetailEdLanStatusMessage, setEdPortStatus, setGotEdPortStatus, setContinueEdPortStatus, setCode, setRcaEdLanStatusMessage, fwsetEdLanStatusMessage, fwsetDetailEdLanStatusMessage, fwsetRcaEdLanStatusMessage, setContinueEdInterfaceStatus, setGreenAuth, setRedFailAuth, setRedFailAuths)

    }


    const checkSiteDownStatus = async () => {
        await CheckSiteDownStatusApiCall(ispParams.isp_wan_id, setSiteDownStatus, setGotSiteDownStatus, setSiteDownStatusMessage, setcontinueEdLanStatus, setDetailSiteDownStatusMessage, setRcaSiteDownStatusMessage)
    }

    useEffect(() => {
        if (continueEdLanStatus) {
            checkEdLanStatus();
        }
    }, [continueEdLanStatus])
    useEffect(() => {
        if (continueEdPortStatus) {
            checkPortLanStatus();
        }
    }, [continueEdPortStatus])
    const checkRechabilityOverInternet = async () => {
        await CheckRechabilityOverInternetApiCall(ispParams.isp_wan_id, setInternetRechStatus, setGotInternetRechStatus, setInternetRechStatusMessage, setContinueDgLanStatus, setDetailInternetRechStatusMessage, setRcaInternetRechStatusMessage, setGreenInternet, setRedFailInternet, setRedFailsInternet, setCheckStatus, ispParams.link_type, setCheckStates, setInternetReachableMeraki)
    }

    useEffect(() => {
        if (continueInternetRechStatus) {
            checkRechabilityOverInternet();
        }
    }, [continueInternetRechStatus])



    useEffect(() => {
        gwInternetTimer = setTimeout(() => {
            setGotGwInternetStatus(true);
            if (ispParams.dg_reach_status === 1) {
                setGwInternetStatus(ispParams.dg_reach_status === 1);
                setExtDgLatency(ispParams.dg_latency);
                setExtDgPacketLoss(ispParams.dg_packet_loss);
                setContinueSiteDownStatus(true);
                checkSiteDownStatus();
            }
            if (ispParams.link_type !== "ILL" && ispParams.link_type !== "Broadband" && ispParams.link_type !== 'MPLS' && ispParams.link_type !== 'P2P') {
                setContinueSiteDownStatus(true);
                checkSiteDownStatus();
            }
            setTimeout(() => {
                clearTimeout(gwInternetTimer)
            }, 1000)
        }, 1000);
        // return () => clearTimeout(timer);
    }, []);

    return (
        <Dialog
            open={openTroubleshoot}
            onClose={handleClose}
            maxWidth="xl"
        >
            <DialogTitle>Troubleshoot</DialogTitle>
            <Divider />
            <DialogContent>
                <List
                    sx={{ width: '1400px', maxWidth: '2400px', bgcolor: 'background.paper' }}
                >
                    {
                        (ispParams.link_type === 'ILL' || ispParams.link_type === 'Broadband' || ispParams.link_type === 'MPLS' || ispParams.link_type === 'P2P') && (
                            <>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <ChevronRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Checking Provider Equipment(PE) reachability" />
                                    <ListItemIcon>
                                        <ListItemIcon>
                                            {!gotGwInternetStatus && !gwInternetStatus && <i className="fa fa-spinner fa-spin" style={{ fontSize: '30px' }} />}
                                            {gotGwInternetStatus && gwInternetStatus && <CheckIcon style={{ color: "green" }} />}
                                            {gotGwInternetStatus && !gwInternetStatus && <ClearIcon style={{ color: "red", margin: "20px" }} />}
                                        </ListItemIcon>
                                        <ListItemIcon>

                                            <ListItemText
                                                primary={gotGwInternetStatus && (gwInternetStatus ? `Provider Equipment(PE) is reachable (Latency: ${extDgLatency}ms & Packet Loss: ${extDgPacketLoss}%)` : `Provider Equipment(PE) is not reachable`)}
                                                secondary={
                                                    gotGwInternetStatus && !gwInternetStatus && (
                                                        <Link component="button" variant="body2" onClick={handleOpenDialog}>
                                                            Click here for more Details
                                                        </Link>
                                                    )
                                                }
                                            />
                                        </ListItemIcon>
                                    </ListItemIcon>
                                </ListItemButton>
                                <Divider />
                            </>
                        )
                    }

                    <ListItemButton>
                        <ListItemIcon>
                            <ChevronRightIcon />
                        </ListItemIcon>
                        <ListItemText primary="Checking site status" />
                        <ListItemIcon>
                            <ListItemIcon>
                                {continueSiteDownStatus && !gotSiteDownStatus && gotGwInternetStatus && <i className="fa fa-spinner fa-spin" style={{ fontSize: '30px' }} />}
                                {continueSiteDownStatus && gotSiteDownStatus && gotGwInternetStatus && !siteDownStatus && <CheckIcon style={{ color: "green" }} />}
                                {continueSiteDownStatus && gotSiteDownStatus && gotGwInternetStatus && siteDownStatus && <ClearIcon style={{ color: "red", margin: "20px" }} />}
                            </ListItemIcon>
                            <ListItemText
                                primary={continueSiteDownStatus && gotSiteDownStatus && gotGwInternetStatus && (siteDownStatus ? "Site is Down" : "Site is Up")}
                                secondary={
                                    gotSiteDownStatus && siteDownStatus && (
                                        <Link component="button" variant="body2" onClick={handleOpenDialog}>
                                            Click here for more Details
                                        </Link>
                                    )
                                }
                            />
                        </ListItemIcon>
                    </ListItemButton>
                    <Divider />
                    <ListItemButton>
                        <ListItemIcon>
                            <ChevronRightIcon />
                        </ListItemIcon>
                        <ListItemText primary="Checking Customer Equipment(CE) reachability status" />
                        <ListItemIcon>
                            <ListItemIcon>
                                {continueEdLanStatus && !gotEdLanStatus && gotSiteDownStatus && <i className="fa fa-spinner fa-spin" style={{ fontSize: '30px' }} />}
                                {continueEdLanStatus && gotEdLanStatus && gotSiteDownStatus && edLanStatus && greenPass && <CheckIcon style={{ color: "green" }} />}
                                {continueEdLanStatus && gotEdLanStatus && gotSiteDownStatus && edLanStatus && redFail && <PriorityHighIcon style={{ color: "orange", margin: "5px" }} />}
                                {continueEdLanStatus && gotEdLanStatus && gotSiteDownStatus && !edLanStatus && redFails && <ClearIcon style={{ color: "red", margin: "5px" }} />}


                            </ListItemIcon>

                            <ListItemText
                                primary={continueEdLanStatus && gotEdLanStatus && gotSiteDownStatus && (edLanStatus ? fwedLanStatusMessage : fwedLanStatusMessage)}
                                secondary={
                                    gotEdLanStatus && !edLanStatus && (
                                        <Link component="button" variant="body2" onClick={handleOpenDialog}>
                                            Click here for more Details
                                        </Link>
                                    )
                                }
                            />

                        </ListItemIcon>
                    </ListItemButton>
                    <Divider />
                    <ListItemButton>
                        <ListItemIcon>
                            <ChevronRightIcon />
                        </ListItemIcon>
                        <ListItemText primary="Checking Customer Equipment(CE) authentication status" />
                        <ListItemIcon>
                            <ListItemIcon>
                                {continueEdPortStatus && !gotEdPortStatus && gotEdLanStatus && <i className="fa fa-spinner fa-spin" style={{ fontSize: '30px' }} />}
                                {continueEdPortStatus && gotEdPortStatus && gotEdLanStatus && edPortStatus && greenAuth && <CheckIcon style={{ color: "green" }} />}
                                {gotEdPortStatus && edPortStatus && redFailAuth && <PriorityHighIcon style={{ color: "orange", margin: "5px" }} />}
                                {gotEdPortStatus && !edPortStatus && redFailAuths && <ClearIcon style={{ color: "red", margin: "5px" }} />}
                            </ListItemIcon>
                            <ListItemText
                                primary={gotEdPortStatus && (edPortStatus ? edLanStatusMessage : edLanStatusMessage)}
                                secondary={
                                    gotEdPortStatus && !edPortStatus && (
                                        <Link component="button" variant="body2" onClick={handleOpenDialog}>
                                            Click here for more Details
                                        </Link>
                                    )
                                }
                            />

                        </ListItemIcon>
                    </ListItemButton>
                    <Divider />
                    <ListItemButton>
                        <ListItemIcon>
                            <ChevronRightIcon />
                        </ListItemIcon>
                        <ListItemText primary="Checking WAN interface status on Customer Equipment(CE)" />
                        <ListItemIcon>
                            <ListItemIcon>
                                {continueEdInterfaceStatus && !gotEdInterfaceStatus && gotEdPortStatus && <i className="fa fa-spinner fa-spin" style={{ fontSize: '30px' }} />}
                                {continueEdInterfaceStatus && gotEdInterfaceStatus && gotEdPortStatus && edInterfaceStatus && <CheckIcon style={{ color: "green" }} />}
                                {continueEdInterfaceStatus && gotEdInterfaceStatus && gotEdPortStatus && !edInterfaceStatus && <ClearIcon style={{ color: "red", margin: "20px" }} />}
                            </ListItemIcon>
                            <ListItemText
                                primary={continueEdInterfaceStatus && gotEdInterfaceStatus && gotEdPortStatus && (edInterfaceStatus ? edInterfaceMessage : edInterfaceMessage)}
                                secondary={
                                    gotEdInterfaceStatus && !edInterfaceStatus && (
                                        <Link component="button" variant="body2" onClick={handleOpenDialog}>
                                            Click here for more Details
                                        </Link>
                                    )
                                }
                            />
                        </ListItemIcon>
                    </ListItemButton>


                    {ispParams.ed_vendor_id !== 10 && (
                        <>
                            <Divider />
                            <ListItemButton>
                                <ListItemIcon>
                                    <ChevronRightIcon />
                                </ListItemIcon>
                                <ListItemText primary="Checking WAN IP(CE-IP) configuration & functionality on Customer Equipment(CE)" />
                                <ListItemIcon>
                                    <ListItemIcon>
                                        {continueWanIpLanStatus && !gotWanIpLanStatus && gotEdInterfaceStatus && <i className="fa fa-spinner fa-spin" style={{ fontSize: '30px' }} />}
                                        {continueWanIpLanStatus && gotWanIpLanStatus && gotEdInterfaceStatus && wanIpLanStatus && <CheckIcon style={{ color: "green" }} />}

                                        {continueWanIpLanStatus && gotWanIpLanStatus && gotEdInterfaceStatus && !wanIpLanStatus && wanPriority && <PriorityHighIcon style={{ color: "orange", margin: "5px" }} />}

                                        {continueWanIpLanStatus && gotWanIpLanStatus && gotEdInterfaceStatus && !wanIpLanStatus && !wanPriority && <ClearIcon style={{ color: "red", margin: "20px" }} />}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={continueWanIpLanStatus && gotWanIpLanStatus && gotEdInterfaceStatus && (wanIpLanStatus ? wanIpReachableMessage : wanIpReachableMessage)}
                                        secondary={
                                            gotWanIpLanStatus && !wanIpLanStatus && !wanPriority && (
                                                <Link component="button" variant="body2" onClick={handleOpenDialog}>
                                                    Click here for more Details
                                                </Link>
                                            )
                                        }
                                    />
                                </ListItemIcon>
                            </ListItemButton>
                        </>
                    )}
                    <>
                        <Divider />
                        {ispParams.ed_vendor_id !== 10 ? (
                            <ListItemButton>
                                <ListItemIcon>
                                    <ChevronRightIcon />
                                </ListItemIcon>
                                <ListItemText primary="Checking internet reachability from Customer Equipment(CE)" />
                                <ListItemIcon>
                                    <ListItemIcon>
                                        {continueInternetRechStatus && !gotInternetRechStatus && gotWanIpLanStatus && <i className="fa fa-spinner fa-spin" style={{ fontSize: '30px' }} />}
                                        {continueInternetRechStatus && gotInternetRechStatus && gotWanIpLanStatus && internetRechStatus && greenInternet && <CheckIcon style={{ color: "green" }} />}
                                        {continueInternetRechStatus && gotInternetRechStatus && gotWanIpLanStatus && internetRechStatus && redFailInternet && <PriorityHighIcon style={{ color: "orange", margin: "5px" }} />}
                                        {continueInternetRechStatus && gotInternetRechStatus && gotWanIpLanStatus && !internetRechStatus && redFailsInternet && <ClearIcon style={{ color: "red", margin: "5px" }} />}

                                    </ListItemIcon>
                                    <ListItemText
                                        primary={continueInternetRechStatus && gotInternetRechStatus && gotWanIpLanStatus && (internetRechStatus ? internetRechStatusMessage : internetRechStatusMessage)}
                                        secondary={
                                            gotInternetRechStatus && !internetRechStatus && (
                                                <Link component="button" variant="body2" onClick={handleOpenDialog}>
                                                    Click here for more Details
                                                </Link>
                                            )
                                        }
                                    />
                                </ListItemIcon>
                            </ListItemButton>) : (
                            <ListItemButton>
                                <ListItemIcon>
                                    <ChevronRightIcon />
                                </ListItemIcon>
                                <ListItemText primary="Checking internet reachability from Customer Equipment(CE)" />
                                <ListItemIcon>
                                    <ListItemIcon>
                                        {continueInternetRechStatus && !gotInternetRechStatus && gotEdInterfaceStatus && !checkWanStatus && (
                                            <i className="fa fa-spinner fa-spin" style={{ fontSize: '30px' }} />
                                        )}
                                        {continueInternetRechStatus && gotInternetRechStatus && gotEdInterfaceStatus && internetRechStatus && greenInternet && checkWanStatus && <CheckIcon style={{ color: "green" }} />}
                                        {continueInternetRechStatus && gotInternetRechStatus && gotEdInterfaceStatus && internetRechStatus && redFailInternet && checkWanStatus && <PriorityHighIcon style={{ color: "orange", margin: "5px" }} />}
                                        {continueInternetRechStatus && gotInternetRechStatus && gotEdInterfaceStatus && !internetRechStatus && redFailsInternet && checkWanStatus && <ClearIcon style={{ color: "red", margin: "5px" }} />}

                                    </ListItemIcon>
                                    <ListItemText
                                        primary={continueInternetRechStatus && gotInternetRechStatus && gotEdInterfaceStatus && checkWanStatus && (internetRechStatus ? internetRechStatusMessage : internetRechStatusMessage)}
                                        secondary={
                                            gotInternetRechStatus && !internetRechStatus && (
                                                <Link component="button" variant="body2" onClick={handleOpenDialog}>
                                                    Click here for more Details
                                                </Link>
                                            )
                                        }
                                    />
                                </ListItemIcon>
                            </ListItemButton>)
                        }
                    </>
                    {(ispParams.link_type === 'ILL' || ispParams.link_type === 'Broadband' || ispParams.link_type === 'MPLS' || ispParams.link_type === 'P2P') && ispParams.ed_vendor_id !== 10 && (
                        <>
                            <Divider />
                            <ListItemButton>
                                <ListItemIcon>
                                    <ChevronRightIcon />
                                </ListItemIcon>
                                <ListItemText primary="Checking Provider Equipment(PE) reachability from Customer Equipment(CE)" />
                                <ListItemIcon>
                                    <ListItemIcon>
                                        {continueDgLanStatus && !gotDgLanStatus && gotWanIpLanStatus && <i className="fa fa-spinner fa-spin" style={{ fontSize: '30px' }} />}
                                        {continueDgLanStatus && gotDgLanStatus && gotWanIpLanStatus && dgLanStatus && <CheckIcon style={{ color: "green" }} />}
                                        {continueDgLanStatus && gotDgLanStatus && gotWanIpLanStatus && !dgLanStatus && <ClearIcon style={{ color: "red", margin: "20px" }} />}

                                    </ListItemIcon>
                                    <ListItemText
                                        // primary={gotDgLanStatus && gotWanIpLanStatus && (!dgLanStatus ? `${dgIpReachableMessage} (Latency: ${intDgLatency}ms & Packet Loss: ${intDgPacketLoss}%)` : dgIpReachableMessage)}
                                        primary={gotDgLanStatus && gotWanIpLanStatus && (!dgLanStatus ? dgIpReachableMessage : dgIpReachableMessage)}
                                        secondary={
                                            gotDgLanStatus && gotWanIpLanStatus && !dgLanStatus && (
                                                <Link component="button" variant="body2" onClick={handleOpenDialog}>
                                                    Click here for more Details
                                                </Link>
                                            )
                                        }
                                    />
                                </ListItemIcon>
                            </ListItemButton>
                        </>
                    )}

                </List>
                <Divider />

                {
                    checkStates && checkStatus && ispParams.ed_vendor_id !== 10 && internetReachableMeraki && (
                        <>
                            <Box sx={{ textAlign: 'center', width: "100%", paddingTop: "40px", color: "red" }}>
                                <Typography variant='h6'>
                                    All appears well at the Customer End, suggesting the problem resides in the middle mile rather than the last mile.
                                    Please reach out to the service provider for resolution
                                </Typography>
                            </Box>
                        </>
                    )
                }
                {
                    checkStates && !checkStatus && ispParams.ed_vendor_id !== 10 && internetReachableMeraki && (
                        <>
                            <Box sx={{ textAlign: 'center', width: "100%", paddingTop: "40px", color: "green" }}>
                                <Typography variant='h6'>
                                    Congratulations, Your Link just Aced the Troubleshooting like a Boss!
                                </Typography>
                            </Box>
                        </>
                    )
                }
                {
                    checkStatus && internetReachableMeraki && ispParams.ed_vendor_id === 10 && checkWanStatus &&
                    (
                        <>
                            <Box sx={{ textAlign: 'center', width: "100%", paddingTop: "40px", color: "red" }}>
                                <Typography variant='h6'>
                                    All appears well at the Customer End, suggesting the problem resides in the middle mile rather than the last mile.
                                    Please reach out to the service provider for resolution
                                </Typography>
                            </Box>
                        </>
                    )
                }
                {
                    !checkStatus && internetReachableMeraki && ispParams.ed_vendor_id === 10 && (
                        <>
                            <Box sx={{ textAlign: 'center', width: "100%", paddingTop: "40px", color: "green" }}>
                                <Typography variant='h6'>
                                    Congratulations, Your Link just Aced the Troubleshooting like a Boss!
                                </Typography>
                            </Box>
                        </>
                    )
                }
                {/* For Checking Site Status  */}
                {(ispParams.link_type === 'ILL' || ispParams.link_type === 'Broadband' || ispParams.link_type === 'MPLS' || ispParams.link_type === 'P2P') && gotGwInternetStatus && !gwInternetStatus &&
                    <>
                        <Box sx={{ textAlign: 'center', width: "100%", paddingTop: "40px", color: "red" }}>
                            <Typography>
                                <strong>RCA:</strong>Provider Equipment(PE) is Not Reachable<br />
                                <strong>Plan of Action:</strong> Please contact the site manager to check for physical connectivity issues, firewall configuration issues or restrictions, and IP conflicts
                            </Typography>
                        </Box>

                        <Dialog
                            open={openDialog}
                            onClose={handleCloseDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Detailed information"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    <Typography component="div" variant='h6' >
                                        Provider Equipment(PE) is Not Reachable. Please reachout to the Service Provider
                                    </Typography>
                                </DialogContentText>

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog} color="primary" autoFocus>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                }


                {/* For Checking Site Status  */}
                {gotSiteDownStatus && siteDownStatus &&
                    <>
                        <Box sx={{ textAlign: 'center', width: "100%", paddingTop: "40px", color: "red" }}>
                            <Typography>
                                {rcaSiteDownStatusMessage === '' || rcaSiteDownStatusMessage === null ? (
                                    'Analysing...'
                                ) : (
                                    rcaSiteDownStatusMessage.split('.').map((line, index) => {
                                        const trimmedLine = line.trim();
                                        if (index === 0) {
                                            return (
                                                <Fragment key={index}>
                                                    <strong>RCA:</strong> {trimmedLine}
                                                    <br />
                                                </Fragment>
                                            );
                                        } if (index === 1) {
                                            return (
                                                <Fragment key={index}>
                                                    <strong>Plan of Action:</strong> {trimmedLine}
                                                </Fragment>
                                            );
                                        }
                                        return null; // Ignore lines beyond the second one
                                    })
                                )}
                            </Typography>
                        </Box>

                        <Dialog
                            open={openDialog}
                            onClose={handleCloseDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Detailed information"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {detailSiteDownStatusMessage.split('\n').map((line, index) => (
                                        <Typography key={index} component="div" variant='h6' >
                                            {line}
                                        </Typography>
                                    ))
                                    }
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog} color="primary" autoFocus>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                }

                {/* For Edge Device Reachability */}
                {gotEdLanStatus && !edLanStatus &&
                    <>
                        <Box sx={{ textAlign: 'center', width: "100%", paddingTop: "40px", color: "red" }}>
                            <Typography>
                                {fwrcaEdLanStatusMessage === '' || fwrcaEdLanStatusMessage === null ? (
                                    'Analysing...'
                                ) : (
                                    fwrcaEdLanStatusMessage.split('.').map((line, index) => {
                                        const trimmedLine = line.trim();
                                        if (index === 0) {
                                            return (
                                                <Fragment key={index}>
                                                    <strong>RCA:</strong> {trimmedLine}
                                                    <br />
                                                </Fragment>
                                            );
                                        } if (index === 1) {
                                            return (
                                                <Fragment key={index}>
                                                    <strong>Plan of Action:</strong> {trimmedLine}
                                                </Fragment>
                                            );
                                        }
                                        return null; // Ignore lines beyond the second one
                                    })
                                )}
                            </Typography>
                        </Box>

                        <Dialog
                            open={openDialog}
                            onClose={handleCloseDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Detailed information"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {fwdetailEdLanStatusMessage.split('\n').map((line, index) => (
                                        <Typography key={index} variant='h6'>
                                            {line}
                                        </Typography>
                                    ))
                                    }

                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog} color="primary" autoFocus>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                }

                {
                    gotEdPortStatus && !edPortStatus &&
                    <>
                        <Box sx={{ textAlign: 'center', width: "100%", paddingTop: "40px", color: "red" }}>
                            <Typography>
                                {rcaEdLanStatusMessage === '' || rcaEdLanStatusMessage === null ? (
                                    'Analysing...'
                                ) : (
                                    rcaEdLanStatusMessage.split('.').map((line, index) => {
                                        const trimmedLine = line.trim();
                                        if (index === 0) {
                                            return (
                                                <Fragment key={index}>
                                                    <strong>RCA:</strong> {trimmedLine}
                                                    <br />
                                                </Fragment>
                                            );
                                        } if (index === 1) {
                                            return (
                                                <Fragment key={index}>
                                                    <strong>Plan of Action:</strong> {trimmedLine}
                                                </Fragment>
                                            );
                                        }
                                        return null; // Ignore lines beyond the second one
                                    })
                                )}
                            </Typography>
                        </Box>

                        <Dialog
                            open={openDialog}
                            onClose={handleCloseDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Detailed information"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {detailEdLanStatusMessage.split('\n').map((line, index) => (
                                        <Typography key={index} variant='h6'>
                                            {line}
                                        </Typography>
                                    ))
                                    }

                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog} color="primary" autoFocus>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                }
                {/* For WAN Inteface Status on Edge Device */}
                {gotEdInterfaceStatus && !edInterfaceStatus &&
                    <>
                        <Box sx={{ textAlign: 'center', width: "100%", paddingTop: "40px", color: "red" }}>
                            <Typography>
                                {rcaEdInterfaceMessage === '' || rcaEdInterfaceMessage === null ? (
                                    'Analysing...'
                                ) : (
                                    rcaEdInterfaceMessage.split('.').map((line, index) => {
                                        const trimmedLine = line.trim();
                                        if (index === 0) {
                                            return (
                                                <Fragment key={index}>
                                                    <strong>RCA:</strong> {trimmedLine}
                                                    <br />
                                                </Fragment>
                                            );
                                        } if (index === 1) {
                                            return (
                                                <Fragment key={index}>
                                                    <strong>Plan of Action:</strong> {trimmedLine}
                                                </Fragment>
                                            );
                                        }
                                        return null; // Ignore lines beyond the second one
                                    })
                                )}
                            </Typography>

                        </Box>
                        <Dialog
                            open={openDialog}
                            onClose={handleCloseDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Detailed information"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {detailEdInterfaceMessage.split('\n').map((line, index) => (
                                        <Typography key={index} variant='h6'>
                                            {line}
                                        </Typography>
                                    ))
                                    }
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog} color="primary" autoFocus>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>


                    </>
                }

                {/* for WAN IP Configuration on Edge Devices */}
                {gotWanIpLanStatus && !wanIpLanStatus && ispParams.ed_vendor_id !== 10 &&
                    <>
                        <Box sx={{ textAlign: 'center', width: "100%", paddingTop: "40px", color: "red" }}>
                            <Typography>
                                {rcaWanIpReachableMessage === '' || rcaWanIpReachableMessage === null ? (
                                    'Analysing...'
                                ) : (
                                    rcaWanIpReachableMessage.split('.').map((line, index) => {
                                        const trimmedLine = line.trim();
                                        if (index === 0) {
                                            return (
                                                <Fragment key={index}>
                                                    <strong>RCA:</strong> {trimmedLine}
                                                    <br />
                                                </Fragment>
                                            );
                                        } if (index === 1) {
                                            return (
                                                <Fragment key={index}>
                                                    <strong>Plan of Action:</strong> {trimmedLine}
                                                </Fragment>
                                            );
                                        }
                                        return null; // Ignore lines beyond the second one
                                    })
                                )}
                            </Typography>
                        </Box>

                        <Dialog
                            open={openDialog}
                            onClose={handleCloseDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Detailed information"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {detailWanIpReachableMessage.split('\n').map((line, index) => (
                                        <Typography key={index} variant='h6'>
                                            {line}
                                        </Typography>
                                    ))
                                    }
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog} color="primary" autoFocus>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>

                    </>
                }

                {/* For Internet Reachable  */}
                {gotInternetRechStatus && !internetRechStatus &&
                    <>
                        <Box sx={{ textAlign: 'center', width: "100%", paddingTop: "40px", color: "red" }}>
                            <Typography>
                                {rcaInternetRechStatusMessage === '' || rcaInternetRechStatusMessage === null ? (
                                    'Analysing...'
                                ) : (
                                    rcaInternetRechStatusMessage.split('.').map((line, index) => {
                                        const trimmedLine = line.trim();
                                        if (index === 0) {
                                            return (
                                                <Fragment key={index}>
                                                    <strong>RCA:</strong> {trimmedLine}
                                                    <br />
                                                </Fragment>
                                            );
                                        } if (index === 1) {
                                            return (
                                                <Fragment key={index}>
                                                    <strong>Plan of Action:</strong> {trimmedLine}
                                                </Fragment>
                                            );
                                        }
                                        return null; // Ignore lines beyond the second one
                                    })
                                )}
                            </Typography>
                            
                        </Box>

                        <Dialog
                            open={openDialog}
                            onClose={handleCloseDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Detailed information"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {detailInternetRechStatusMessage.split('\n').map((line, index) => (
                                        <Typography key={index} variant='h6'>
                                            {line}
                                        </Typography>
                                    ))
                                    }
                                    {/* {detailInternetRechStatusMessage} */}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog} color="primary" autoFocus>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>

                    </>
                }



                {/* For WAN Gateway Reachable From LAN Options */}
                {(ispParams.link_type === 'ILL' || ispParams.link_type === 'Broadband' || ispParams.link_type === 'MPLS' || ispParams.link_type === 'P2P') && ispParams.ed_vendor_id !== 10 && gotDgLanStatus && !dgLanStatus && (dgIpReachableMessage !== "Invalid Credentials") &&
                    <>
                        <Box sx={{ textAlign: 'center', width: "100%", paddingTop: "40px", color: "red" }}>
                            <Typography>
                                {rcaDgIpReachableMessage === '' || rcaDgIpReachableMessage === null ? (
                                    'Analysing...'
                                ) : (
                                    rcaDgIpReachableMessage.split('.').map((line, index) => {
                                        const trimmedLine = line.trim();
                                        if (index === 0) {
                                            return (
                                                <Fragment key={index}>
                                                    <strong>RCA:</strong> {trimmedLine}
                                                    <br />
                                                </Fragment>
                                            );
                                        } if (index === 1) {
                                            return (
                                                <Fragment key={index}>
                                                    <strong>Plan of Action:</strong> {trimmedLine}
                                                </Fragment>
                                            );
                                        }
                                        return null; // Ignore lines beyond the second one
                                    })
                                )}
                            </Typography>
                        </Box>

                        <Dialog
                            open={openDialog}
                            onClose={handleCloseDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Detailed information"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {detailDgIpReachableMessage.split('\n').map((line, index) => (
                                        <Typography key={index} variant='h6'>
                                            {line}
                                        </Typography>
                                    ))
                                    }
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog} color="primary" autoFocus>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>

                    </>
                }




                {gotDgLanStatus && !dgLanStatus && (dgIpReachableMessage === "Invalid Credentials") &&
                    <Box sx={{ textAlign: 'center', width: "100%", paddingTop: "40px", color: "red" }}>
                        <Typography variant='h6'>
                            Invalid credentials for edge device, DG Reachability cannot be checked
                        </Typography>
                    </Box>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}
