




// import React, { useState, useEffect } from 'react';
// import { Typography, Grid, Box, Tab, Tabs } from '@mui/material';
// import ReactApexChart from 'react-apexcharts';
// import merge from 'lodash/merge';
// import { BaseOptionChart } from '../chart';

// const AppRegionGraph = ({ regionData, selectedRegion, time }) => {
//   const [value, setValue] = useState(0);

//   const [chartData, setChartData] = useState({
//     defaultMetrics: {
//       jitter: [0],
//       latency: [0],
//       packetLoss: [0],
//       dnsResolutionTime: [0],
//       timeLabels: [0],
//     },
//     portMetrics: [],
//   });

//   useEffect(() => {
//     if (regionData?.regions?.length > 0) {
//       console.log('Region Data:', regionData);
//       const region = selectedRegion;
//       const tcpPorts = region.port_details?.tcp_ports || [];

//       // Map through all TCP ports and aggregate their data
//       const portMetrics = tcpPorts.map((port) => ({
//         portLatency: [
//           {
//             name: `TCP Port Latency(${port.tcp_port_num})`,
//             data: port.tcp_port_latency || [],
//           },
//         ],
//         portJitter: [
//           {
//             name: `TCP Port Jitter(${port.tcp_port_num})`,
//             data: port.tcp_port_jitter || [],
//           },
//         ],
//         portPacketLoss: [
//           {
//             name: `TCP Port Packet Loss(${port.tcp_port_num})`,
//             data: port.tcp_port_packet_loss || [],
//           },
//         ],
//         portTimeLabels: port.ent || [],
//         portNumber: port.tcp_port_num,
//       }));

//       setChartData({
//         defaultMetrics: {
//           jitter: [{ name: 'Jitter', data: region.jitter }],
//           latency: [{ name: 'Latency', data: region.latency }],
//           packetLoss: [{ name: 'Packet Loss', data: region.packet_loss }],
//           responseTime: [{ name: 'Response Time', data: region.response_time }],
//           dnsResolutionTime: [{ name: 'DNS Resolution Time', data: region.dns_resolution_time }],
//           timeLabels: region.ent,
//         },
//         portMetrics,
//       });
//     }
//   }, [regionData, selectedRegion]);

//   const handleTabChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   const getChartOptions = (annotationY, yAxisTitle, labels) => {
//     return merge(BaseOptionChart(), {
//       plotOptions: { bar: { columnWidth: '16%' } },
//       labels,
//       xaxis: {
//         type: 'categories',
//         tickAmount: 4,
//       },
//       yaxis: {
//         title: {
//           text: yAxisTitle,
//         },
//       },
//       legend: {
//         position: 'bottom',
//         showForSingleSeries: true,
//       },
//       tooltip: {
//         shared: true,
//         y: {
//           formatter: (y) => (typeof y !== 'undefined' ? `${y}` : y),
//         },
//       },
//       annotations: {
//         yaxis: [
//           {
//             y: annotationY,
//             borderColor: 'red',
//             label: {
//               borderColor: 'red',
//               style: {
//                 color: '#fff',
//                 background: 'red',
//               },
//               text: `Base Value (${annotationY})`,
//             },
//           },
//         ],
//       },
//     });
//   };

//   const latencyOptions = getChartOptions(150, 'Milliseconds', chartData.defaultMetrics.timeLabels);
//   const jitterOptions = getChartOptions(30, 'Milliseconds', chartData.defaultMetrics.timeLabels);
//   const packetLossOptions = getChartOptions(5, 'Percentage', chartData.defaultMetrics.timeLabels);
//   const responseTimeOptions = getChartOptions(1, 'Milliseconds', chartData.defaultMetrics.timeLabels);
//   const dnsResolutionOptions = getChartOptions(1, 'Milliseconds', chartData.defaultMetrics.timeLabels);

//   return (
//     <Grid container spacing={1} sx={{ p: 1 }}>
//       <Grid item xs={12}>
//         <Tabs value={value} onChange={handleTabChange} aria-label="Graphs Tabs">
//           <Tab label="Latency" />
//           <Tab label="Jitter" />
//           <Tab label="Packet Loss" />
//           <Tab label="Response Time" />
//           <Tab label="DNS Resolution Time" />
//         </Tabs>
//         <Box sx={{ p: 2 }}>
//           {value === 0 && (
//             <ReactApexChart
//               options={latencyOptions}
//               series={[
//                 ...chartData.defaultMetrics.latency,
//                 ...chartData.portMetrics.flatMap((port) => port.portLatency),
//               ]}
//               type="line"
//               height={400}
//             />
//           )}
//           {value === 1 && (
//             <ReactApexChart
//               options={jitterOptions}
//               series={[
//                 ...chartData.defaultMetrics.jitter,
//                 ...chartData.portMetrics.flatMap((port) => port.portJitter),
//               ]}
//               type="line"
//               height={400}
//             />
//           )}
//           {value === 2 && (
//             <ReactApexChart
//               options={packetLossOptions}
//               series={[
//                 ...chartData.defaultMetrics.packetLoss,
//                 ...chartData.portMetrics.flatMap((port) => port.portPacketLoss),
//               ]}
//               type="line"
//               height={400}
//             />
//           )}
//           {value === 3 && (
//             <ReactApexChart
//               options={responseTimeOptions}
//               series={chartData.defaultMetrics.responseTime}
//               type="line"
//               height={400}
//             />
//           )}
//           {value === 4 && (
//             <ReactApexChart
//               options={dnsResolutionOptions}
//               series={chartData.defaultMetrics.dnsResolutionTime}
//               type="line"
//               height={400}
//             />
//           )}
//         </Box>
//       </Grid>
//     </Grid>
//   );
// };

// export default AppRegionGraph;


import React, { useState, useEffect } from 'react';
import { Typography, Grid, Box, Tab, Tabs } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import merge from 'lodash/merge';
import { BaseOptionChart } from '../chart';

const AppRegionGraph = ({ regionData , selectedRegion}) => {
  const [value, setValue] = useState(0);
console.log(selectedRegion,"selectedRegion");

  const [chartData, setChartData] = useState({
    defaultMetrics: {
      jitter: [0],
      latency: [0],
      packetLoss: [0],
      dnsResolutionTime: [0],
      timeLabels: [0],
    },
    portMetrics: [],
  });

  
  // useEffect(() => {
  //   if (regionData?.regions?.length > 0) {
      
  //     // const region = selectedRegion;  
  //     const region = regionData.regions[0];  
  //     const tcpPorts = region.port_details?.tcp_ports || [];

  //     // Map through all TCP ports and aggregate their data
  //     const portMetrics = tcpPorts.map((port) => ({
  //       portLatency: [
  //         {
  //           name: `TCP Port Latency(${port.tcp_port_num})`,
  //           data: port.tcp_port_latency || [],
  //         },
  //       ],
  //       portJitter: [
  //         {
  //           name: `TCP Port Jitter(${port.tcp_port_num})`,
  //           data: port.tcp_port_jitter || [],
  //         },
  //       ],
  //       portPacketLoss: [
  //         {
  //           name: `TCP Port Packet Loss(${port.tcp_port_num})`,
  //           data: port.tcp_port_packet_loss || [],
  //         },
  //       ],
  //       portTimeLabels: port.ent || [],
  //       portNumber: port.tcp_port_num,
  //     }));

  //     setChartData({
  //       defaultMetrics: {
  //         jitter: [{ name: 'Jitter', data: region.jitter || [] }],
  //         latency: [{ name: 'Latency', data: region.latency || [] }],
  //         packetLoss: [{ name: 'Packet Loss', data: region.packet_loss || [] }],
  //         responseTime: [
  //           { name: 'Response Time', data: region.response_time || [] },
  //         ],
  //         dnsResolutionTime: [
  //           { name: 'DNS Resolution Time', data: region.dns_resolution_time || [] },
  //         ],
  //         timeLabels: region.ent || [],
  //       },
  //       portMetrics,
  //     });
  //   }
  // }, [regionData]); 

  useEffect(() => {
    if (regionData?.regions?.length > 0) {
     
      const region =
        regionData.regions.find((r) => r.region_id === selectedRegion?.region_id) ||
        regionData.regions[0];
  
      const tcpPorts = region.port_details?.tcp_ports || [];
  
      
      const portMetrics = tcpPorts.map((port) => ({
        portLatency: [
          {
            name: `TCP Port Latency(${port.tcp_port_num})`,
            data: port.tcp_port_latency || [],
          },
        ],
        portJitter: [
          {
            name: `TCP Port Jitter(${port.tcp_port_num})`,
            data: port.tcp_port_jitter || [],
          },
        ],
        portPacketLoss: [
          {
            name: `TCP Port Packet Loss(${port.tcp_port_num})`,
            data: port.tcp_port_packet_loss || [],
          },
        ],
        portTimeLabels: port.ent || [],
        portNumber: port.tcp_port_num,
      }));
  
      setChartData({
        defaultMetrics: {
          jitter: [{ name: 'Jitter', data: region.jitter || [] }],
          latency: [{ name: 'Latency', data: region.latency || [] }],
          packetLoss: [{ name: 'Packet Loss', data: region.packet_loss || [] }],
          responseTime: [
            { name: 'Response Time', data: region.response_time || [] },
          ],
          dnsResolutionTime: [
            { name: 'DNS Resolution Time', data: region.dns_resolution_time || [] },
          ],
          timeLabels: region.ent || [],
        },
        portMetrics,
      });
    }
  }, [regionData, selectedRegion]);
  
  
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const getChartOptions = (annotationY, yAxisTitle, labels) => {
    return merge(BaseOptionChart(), {
      plotOptions: { bar: { columnWidth: '16%' } },
      labels,
      xaxis: {
        type: 'categories',
        tickAmount: 4,
      },
      yaxis: {
        title: {
          text: yAxisTitle,
        },
      },
      legend: {
        position: 'bottom',
        showForSingleSeries: true,
      },
      tooltip: {
        shared: true,
        y: {
          formatter: (y) => (typeof y !== 'undefined' ? `${y}` : y),
        },
      },
      annotations: {
        yaxis: [
          {
            y: annotationY,
            borderColor: 'red',
            label: {
              borderColor: 'red',
              style: {
                color: '#fff',
                background: 'red',
              },
              text: `Base Value (${annotationY})`,
            },
          },
        ],
      },
    });
  };

  const latencyOptions = getChartOptions(150, 'Milliseconds', chartData.defaultMetrics.timeLabels);
  const jitterOptions = getChartOptions(30, 'Milliseconds', chartData.defaultMetrics.timeLabels);
  const packetLossOptions = getChartOptions(5, 'Percentage', chartData.defaultMetrics.timeLabels);
  const responseTimeOptions = getChartOptions(1, 'Milliseconds', chartData.defaultMetrics.timeLabels);
  const dnsResolutionOptions = getChartOptions(1, 'Milliseconds', chartData.defaultMetrics.timeLabels);

  return (
    <Grid container spacing={1} sx={{ p: 1 }}>
      <Grid item xs={12}>
        <Tabs value={value} onChange={handleTabChange} aria-label="Graphs Tabs">
          <Tab label="Latency" />
          <Tab label="Jitter" />
          <Tab label="Packet Loss" />
          <Tab label="Response Time" />
          <Tab label="DNS Resolution Time" />
        </Tabs>
        <Box sx={{ p: 2 }}>
          {value === 0 && (
            <ReactApexChart
              options={latencyOptions}
              series={[
                ...chartData.defaultMetrics.latency,
                ...chartData.portMetrics.flatMap((port) => port.portLatency),
              ]}
              type="line"
              height={400}
            />
          )}
          {value === 1 && (
            <ReactApexChart
              options={jitterOptions}
              series={[
                ...chartData.defaultMetrics.jitter,
                ...chartData.portMetrics.flatMap((port) => port.portJitter),
              ]}
              type="line"
              height={400}
            />
          )}
          {value === 2 && (
            <ReactApexChart
              options={packetLossOptions}
              series={[
                ...chartData.defaultMetrics.packetLoss,
                ...chartData.portMetrics.flatMap((port) => port.portPacketLoss),
              ]}
              type="line"
              height={400}
            />
          )}
          {value === 3 && (
            <ReactApexChart
              options={responseTimeOptions}
              series={chartData.defaultMetrics.responseTime}
              type="line"
              height={400}
            />
          )}
          {value === 4 && (
            <ReactApexChart
              options={dnsResolutionOptions}
              series={chartData.defaultMetrics.dnsResolutionTime}
              type="line"
              height={400}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default AppRegionGraph;
