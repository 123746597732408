import React, { useEffect, useState, forwardRef } from 'react';

import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Toolbar, Typography, OutlinedInput, InputAdornment, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';

import Iconify from '../../Iconify';
import EdgeDeviceCustumTagDialog from './EdgeDeviceCustumTagDialog';
import getCustomTagsApiCall from './getCustomTagsApiCall';
import DeleteCustomTagDialog from './DeleteCustomTagDialog';
import UpdateCustomTag from './UpdateCustomTag';


// import PauseEdgeDeviceApiCall from './PauseEdgeDeviceApiCall';
// import PauseEdgeDeviceDailog from './PauseEdgeDeviceDailog';
// import CheckPortAndAuthStatusDialog from './CheckPortAndAuthStatusDialog';

import SelectCustomerWarningDialog from '../../WarningComponents/SelectCustomerWarningDialog';
import { useGlobalState } from '../../../globalHooks/GlobalState';

const useStyles = makeStyles({
    container: {
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '6px', // Adjust the width as desired
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
        },
    },
});

const headCells = [
    {
        id: 'slNo',
        numeric: true,
        disablePadding: true,
        label: 'Sl No',
    },
    {
        id: 'cust_name',
        numeric: false,
        disablePadding: false,
        label: 'Customer',
    },
    {
        id: 'entity',
        numeric: false,
        disablePadding: false,
        label: 'Entity',
    },
    {
        id: 'data_type',
        numeric: false,
        disablePadding: false,
        label: 'Data Type',

    },
    {
        id: 'tags',
        numeric: false,
        disablePadding: false,
        label: 'Tag Name',
    },
    {
        id: 'is_mandatory',
        numeric: false,
        disablePadding: false,
        label: 'Is Mandatory',
    },
    {
        id: 'default_value',
        numeric: false,
        disablePadding: false,
        label: 'Default Value',
    },

    {
        id: 'Actions',
        numeric: true,
        disablePadding: false,
        label: 'Actions',
    },

];
const RootStyle = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    height: 40,
    marginLeft: 'auto',
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`,
    },
}));

function EnhancedTableHead({ showCustomer }) {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    (headCell.id !== 'cust_name' || showCustomer) &&
                    <TableCell
                        sx={{ paddingLeft: "20px", background: '#f0f0f0', padding: '10px', textAlign: 'center' }}
                        key={headCell.id}
                        // align={headCell.numeric ? 'center' : 'left'}

                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    showCustomer: PropTypes.bool.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar({ filterName, onFilterName, handleUploadClick, handleDeviceCustumTag, isCompact }) {

    return (
        <RootStyle>

            <Typography variant='h4'>
                Custom Tags
            </Typography>
            <Box
                width="75%"
                display="flex"
                justifyContent="flex-end"
                paddingRight="10px"
                position="relative"

            >

                <Box
                    width="10px"
                />
                {/* <Button
                    variant="contained"
                    endIcon={<AddIcon />}
                    sx={{ width: "150px", height: "40px", paddingLeft: "10px" }}
                    onClick={handleAddClick}>
                    New
                </Button> */}

            </Box>
            <Box
                width="75%"
                display="flex"
                justifyContent="flex-end"
                paddingRight="10px"
            >

                <Box
                    width="10px"
                />
                <Button
                    variant="contained"
                    endIcon={<AddIcon />}
                    sx={{ width: "150px", height: "40px", paddingLeft: "10px", }}
                    onClick={handleDeviceCustumTag}>
                    Custom Tag
                </Button>

            </Box>

            <SearchStyle
                value={filterName}
                onChange={onFilterName}
                onKeyDown={onFilterName}
                onKeyUp={onFilterName}
                placeholder="Search..."
                startAdornment={
                    <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                }
            />
        </RootStyle>
    );
}
EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    isCompact: PropTypes.bool.isRequired,
};

export default function CustomTagTable() {
    const { globalState, setGlobalState } = useGlobalState();
    const { buState, setBuState } = useGlobalState();
    const { buName, setBuName } = useGlobalState();
    const [rows, setRows] = useState([]);
    const [filterName, setFilterName] = useState('');

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [customTag, setCustomTag] = useState([])
    const [data, setData] = useState([]);
    const mspClientId = localStorage.getItem("cust_id");
    const regClientId = localStorage.getItem("reg_cust_id");
    const custId = parseInt(localStorage.getItem("cust_id"), 10);
    const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);

    const isEngineer = (localStorage.getItem("role") === "2")
    const classes = useStyles();
    const [selected, setSelected] = useState([]);

    const [openEdgeDeviceCustumTag, setOpenEdgeDeviceCustumTag] = useState(false);
    const [edCustumTagStatus, setEdCustumTagStatus] = useState(0);
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false)
    const [updateStatus, setUpdateStatus] = useState(0)

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState(0);
    const [deleteMessage, setDeleteMessage] = useState("")


    const [edCustumTagMessage, setEdCustumTagMessage] = useState("")

    const [updateMessage, setUpdateMessage] = useState("")
    const [locIdNames, setLocIdNames] = useState([]);



    const [openWarnDialog, setOpenWarnDialog] = useState(false);
    const [openCustomerWarnDialog, setOpenCustomerWarnDialog] = useState(false);
    const [openCheckPortAndAuthStatusDialog, setCheckPortAndAuthStatusDialog] = useState(false);
    const [pauseDeviceIp, setPauseDeviceIp] = useState("");
    const [openPauseDialog, setOpenPauseDialog] = useState(false);
    const [filteredRows, setFilteredRows] = useState([]);
    const [fetchStatus, setFetchStatus] = useState(null);
    const [fetchMessage, setFetchMessage] = useState("");

    const Alert = forwardRef((props, ref) => (
        <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    ));
    const handleOnEdit = (e, row) => {
        resetAll();
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return
        }
        setData(row);
        setOpenUpdateDialog(true);
    }


    const handleDelete = (e, row) => {
        resetAll();
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return
        }
        setData(row);
        setOpenDeleteDialog(true);
    }


    const handleFilterByName = async (event) => {
        const searchedVal = event.target.value.toLowerCase();
        setFilterName(searchedVal);

        const filteredRows = rows.filter((row) => {
            return (
                (row.entity && row.entity.toLowerCase().includes(searchedVal)) ||
                (row.tags && row.tags.toLowerCase().includes(searchedVal)) ||
                (row.data_type && row.data_type.toLowerCase().includes(searchedVal)) ||
                (row.is_mandatory && row.is_mandatory.toLowerCase().includes(searchedVal)) ||
                (row.default_value && row.default_value.toLowerCase().includes(searchedVal))
            );
        });

        setFilteredRows(filteredRows);
    };


    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const _getCustomTagsApiCall = async () => {
        await getCustomTagsApiCall(setRows, setFilteredRows, setCustomTag);
    }

    useEffect(() => {
        _getCustomTagsApiCall();
    }, [updateStatus, globalState, buState, edCustumTagStatus, deleteStatus])


    const resetAll = () => {
        setUpdateStatus(null);
        setUpdateMessage('');
        setDeleteStatus(0);
        setOpenDeleteDialog(false);
        setOpenUpdateDialog(false);
        setUpdateStatus(0);
        setEdCustumTagStatus(null);
        setEdCustumTagMessage('');
        setOpenCustomerWarnDialog(false);
    }

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        resetAll();
    };

    const handleDeviceCustumTag = () => {
        resetAll();
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
            return
        }
        setOpenEdgeDeviceCustumTag(true);

    }

    const handleUploadClick = () => {
        resetAll();
        setData(rows);
    }
    const formatDataType = (dataType) => {
        if (dataType === "Whole Number") return "Integer";
        if (dataType === "Select") return "Dropdown";
        if (dataType ==="DATE") return "DateTime"
        return dataType;
      };
    return (
        < Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }} elevation={15}>
                <EnhancedTableToolbar
                    filterName={filterName}
                    onFilterName={(e) => { handleFilterByName(e) }}
                    handleUploadClick={handleUploadClick}
                    handleDeviceCustumTag={handleDeviceCustumTag}
                />
                <TableContainer className={classes.container}>
                    <Table stickyHeader
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                    >
                        <EnhancedTableHead
                            showCustomer={mspClientId === regClientId && mspStatus === 1}
                            numSelected={selected.length}
                            // rowCount={filteredRows.length}
                            rowCount={filteredRows ? filteredRows.length : 0} // Defensive check
                        />
                        <TableBody>
                            {filteredRows?.map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={row.tag_id}
                                    >
                                        <TableCell align="center">{index + 1}</TableCell>
                                        {mspClientId === regClientId && mspStatus === 1 ? (<TableCell align="left">{row.cust_name}</TableCell>) : null}
                                        <TableCell align="center">{row.entity}</TableCell>
                                        {/* <TableCell align="center">{row.data_type}</TableCell> */}
                                        <TableCell align="center">{formatDataType(row.data_type)}</TableCell>
                                        <TableCell align="center">{row.tags}</TableCell>
                                        <TableCell align="center">{row.is_mandatory}</TableCell>
                                        <TableCell align="center">{row.default_value}</TableCell>
                                        <TableCell align="center">
                                            <Grid direction="row">

                                                {row.tags !== null && <IconButton variant="outlined" color="primary" onClick={(e) => { handleOnEdit(e, row) }}><EditIcon /></IconButton>}
                                                {row.tags !== null && <IconButton variant="outlined" color="error" onClick={(e) => { handleDelete(e, row) }}><DeleteIcon /></IconButton>}

                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {openWarnDialog && <SelectCustomerWarningDialog openWarnDialog={openWarnDialog} setOpenWarnDialog={setOpenWarnDialog} />}
                {openEdgeDeviceCustumTag && (<EdgeDeviceCustumTagDialog openEdgeDeviceCustumTag={openEdgeDeviceCustumTag} setOpenEdgeDeviceCustumTag={setOpenEdgeDeviceCustumTag} setEdCustumTagStatus={setEdCustumTagStatus} setEdCustumTagMessage={setEdCustumTagMessage} />)}

                {openUpdateDialog && <UpdateCustomTag data={data} openUpdateDialog={openUpdateDialog} setOpenUpdateDialog={setOpenUpdateDialog} setUpdateStatus={setUpdateStatus} setUpdateMessage={setUpdateMessage} />}

                {openDeleteDialog && <DeleteCustomTagDialog data={data} openDeleteDialog={openDeleteDialog} setOpenDeleteDialog={setOpenDeleteDialog} setDeleteStatus={setDeleteStatus} setDeleteMessage={setDeleteMessage} />}
            </Paper>

            {edCustumTagStatus === 1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
                    {edCustumTagMessage}
                </Alert>
            </Snackbar>}

            {edCustumTagStatus === -1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
                    Error: {edCustumTagMessage}
                </Alert>
            </Snackbar>}


            {updateStatus === 1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
                    {updateMessage}
                </Alert>
            </Snackbar>}
            {updateStatus === -1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
                    Error: {updateMessage}
                </Alert>
            </Snackbar>}

            {deleteStatus === 1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
                    {deleteMessage}
                </Alert>
            </Snackbar>}
            {deleteStatus === -1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
                <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
                    Error: {deleteMessage}
                </Alert>
            </Snackbar>}


        </Box >
    )
}