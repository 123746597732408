import React, { useState } from 'react'
import {
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { DeleteApplicationAPICall } from './DeleteApplicationAPICall';



export default function DeleteSaaSDialog(props) {

    const { app, appType, openDeleteDialog, setOpenDeletDialog, setDeleteStatus, setDeleteOpen, setSeverStatus,setDeleteStatusMessage } = props;
    const handleClose = (e) => {
        setOpenDeletDialog(false);
    }
    console.log(app,"app");
    const [selectedGroupIds] = useState(
        app.group_id && Array.isArray(app.group_id) ? app.group_id.filter((id) => id !== null) : []
    );

    // Compute monitoring value
    const [monitoringValue] = useState(() => {
        if (app.app_monitoring_type?.includes("Both")) return 3;
        if (app.app_monitoring_type?.includes("Probe")) return 2;
        if (app.app_monitoring_type?.includes("Agent")) return 1;
        return null;
    });
    const handleDelete = (e) => {
        DeleteApplicationAPICall(app.app_id, appType, app.app_url,selectedGroupIds, monitoringValue, setDeleteStatus, setSeverStatus,setDeleteStatusMessage);
        setDeleteOpen(true);
        setOpenDeletDialog(false);
    }

    return (
        <>
            <Dialog
                open={openDeleteDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth maxWidth="sm"
            >
                <DialogTitle sx={{ py: 2 }}>
                    Delete Application
                    <IconButton sx={{ float: 'right' }} onClick={handleClose}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure to delete {app.app_name} ??
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={handleDelete} autoFocus>Yes</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
