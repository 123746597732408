import axios from 'axios';

const GetRegionAppDropdownAPI = async (setProbeRegionsData) => {
  // const baseUrl = "https://tb-api.linkeye.io/api/get_probe_regions.php";
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/get_probe_regions.php`;
  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id": localStorage.getItem("cust_id")
  });

  return axios.post(baseUrl, payload)
    .then((response) => {
        
      if (response.data.code === 1) {
        const regionData = response.data.data;
        setProbeRegionsData(regionData);
      } else {
        setProbeRegionsData([]);
      }
    })
    .catch((error) => {
      console.error("Error fetching probe regions:", error);
      setProbeRegionsData([]);
    });
};

export default GetRegionAppDropdownAPI;
