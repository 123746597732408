import React, { useState, useEffect, Fragment } from 'react';
// @mui
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Grid, Radio, RadioGroup, FormControlLabel, FormControl, TextField, InputLabel, Select, MenuItem, Checkbox, Divider, Typography } from '@mui/material';

import GetTroubleshootInfoApiCall from './GetTroubleshootInfoApiCall';

export default function TroubleshootInsightsDialog(props) {

    const { value, openInsightDialog, setOpenInsightDialog } = props;
    const [infoList, setInfoList] = useState([]);
    const [rcaData, setRcaData] = useState("");
    const [count, setCount] = useState(0);

    useEffect(() => {
        GetTroubleshootInfoApiCall(value.isp_wan_id, setInfoList, setRcaData);
    }, [])
    return (
        <>
            <Dialog open={openInsightDialog} onClose={() => { setOpenInsightDialog(false) }} fullWidth maxWidth="lg" aria-labelledby="responsive-dialog-title">
                <DialogTitle id="responsive-dialog-title" sx={{ py: 2 }}>
                    Co-Pilot insights
                    <IconButton onClick={() => { setOpenInsightDialog(false) }} sx={{ float: 'right' }}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ py: 2, px: 3 }}>
                    {infoList.length !== 0 && infoList.map((value, index) => (
                        <List sx={{ bgcolor: 'background.paper' }}>
                            {value !== "Internet is reachable From Customer Equipment(CE) but the quality is bad." && value !== "Internet is not reachable from Customer Equipment(CE)." && value !== "Customer Equipment (CE) is reachable, however SSH is not enabled on the equipment." && value !== "SNMP authentication was successful for the Customer Equipment (CE), but there was an issue with SSH authentication." ?

                                <ListItemButton>
                                    <ListItemIcon>
                                        {(() => {
                                            if (value.startsWith("Provider Equipment(PE) is not reachable from Customer Equipment (CE)")) {
                                                return <ClearIcon sx={{ color: 'red' }} />;
                                            }
                                            if (value.startsWith("Provider Equipment(PE) is reachable from Customer Equipment (CE)")) {
                                                return <CheckIcon sx={{ color: 'green' }} />;
                                            }
                                            if (index === infoList.length - 1) {
                                                return <ClearIcon sx={{ color: 'red' }} />;
                                            }
                                            return <CheckIcon sx={{ color: 'green' }} />;
                                        })()}
                                    </ListItemIcon>
                                    {(() => {
                                        if (value.startsWith("Provider Equipment(PE) is not reachable from Customer Equipment (CE)")) {
                                            return <ListItemText primary={value} sx={{ color: 'red' }} />;
                                        }
                                        if (value.startsWith("Provider Equipment(PE) is reachable from Customer Equipment (CE)")) {
                                            return <ListItemText primary={value} sx={{ color: 'green' }} />;
                                        }
                                        if (index === infoList.length - 1) {
                                            return <ListItemText primary={value} sx={{ color: 'red' }} />;
                                        }
                                        return <ListItemText primary={value} sx={{ color: 'green' }} />;
                                    })()}
                                </ListItemButton>
                                :
                                <ListItemButton>
                                    {value === "Internet is reachable From Customer Equipment(CE) but the quality is bad." && <> <ListItemIcon> <PriorityHighIcon sx={{ color: 'orange' }} /> </ListItemIcon>  <ListItemText primary={value} sx={{ color: 'orange' }} /></>}
                                    {value === "Customer Equipment (CE) is reachable, however SSH is not enabled on the equipment." && <> <ListItemIcon> <PriorityHighIcon sx={{ color: 'red' }} /> </ListItemIcon>  <ListItemText primary={value} sx={{ color: 'red' }} /></>}
                                    {value === "SNMP authentication was successful for the Customer Equipment (CE), but there was an issue with SSH authentication." && <> <ListItemIcon> <PriorityHighIcon sx={{ color: 'red' }} /> </ListItemIcon>  <ListItemText primary={value} sx={{ color: 'red' }} /></>}
                                    {value === "Internet is not reachable from Customer Equipment(CE)." && <><ListItemIcon><PriorityHighIcon sx={{ color: 'red' }} /></ListItemIcon><ListItemText primary={value} sx={{ color: 'red' }} /></>}
                                </ListItemButton>
                            }
                        </List>
                    ))}
                    <>
                        <Box sx={{ textAlign: 'center', width: "100%", paddingTop: "40px", color: "black" }}>
                            <Typography>
                                {rcaData === '' || rcaData === null ? (
                                    'Analysing...'
                                ) : (
                                    rcaData.split('.').map((line, index) => {
                                        const trimmedLine = line.trim();
                                        if (index === 0) {
                                            return (
                                                <Fragment key={index}>
                                                    <strong>RCA:</strong> {trimmedLine}
                                                    <br />
                                                </Fragment>
                                            );
                                        } if (index === 1) {
                                            return (
                                                <Fragment key={index}>
                                                    <strong>Plan Of Action:</strong> {trimmedLine}
                                                </Fragment>
                                            );
                                        }
                                        return null; // Ignore lines beyond the second one
                                    })
                                )}
                            </Typography>
                        </Box>
                    </>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => { setOpenInsightDialog(false) }}>OK</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
