import React, { useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    Button,
    FormHelperText,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Checkbox,

    Autocomplete,
    Chip,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import { useNavigate } from 'react-router-dom';
import { useGlobalState } from '../../../globalHooks/GlobalState';
import GetSiteGroupDetailsApiCall from '../../SiteGroupComponents/Components/GetSiteGroupDetailsApiCall';
import { AddApplicationAPICall } from './AddApplicationAPICall';
import GetSaasAppDropdownListAPICall from './GetSaasAppDropdownListAPICall'
import GetRegionAppDropdownAPI from './GetRegionAppDropdownAPI';


export default function AddNewApplicationDialog(props) {
    const { openAddAppDialog, setOpenAddAppDialog, setAddAppStatus, setAddAppOpen, setSeverStatus, setAppStatusMessage } = props;

    const { globalState, setGlobalState } = useGlobalState();

    const custId = parseInt(localStorage.getItem("cust_id"), 10);
    const mspStatus = parseInt(localStorage.getItem("msp_status"), 10);

    const [groups, setGroups] = useState([]);
    const [groupNames, setGroupNames] = useState([]);
    const [selectedGroupIds, setSelectedGroupIds] = useState([]);
    const [selectedGroupNames, setSelectedGroupNames] = useState([]);

    const [saasAppData, setSaasAppData] = useState([]);
    const [appId, setAppId] = useState(-1);
    const [selectedSaasApp, setSelectedSaasApp] = useState({
        app_id: null,
        app_name: "",
        app_url: "",
        port_data: []
    });

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    const [openWarnDialog, setOpenWarnDialog] = useState(false);
    const [AppUrl, setAppUrl] = useState("");
    const [AppName, setAppName] = useState("");
    const [AppType, setAppType] = useState(1);
    const [protocol, setProtocol] = useState('');
    const [protocolDetails, setProtocolDetails] = useState(2);

    const [PortType, setPortType] = useState("");
    const [ports, setPorts] = useState([]);
    const [portNumber, setPortNumber] = useState('');
    const [portDetailsNumber, setPortDetailsNumber] = useState('');
    const [portCriticality, setPortCriticality] = useState("HIGH");
    const [portCriticalityDetails, setPortCriticalityDetails] = useState("HIGH");
    const [portFunction, setPortFunction] = useState('');
    const [isPortFunctionRequired, setIsPortFunctionRequired] = useState(false);

    const [showPortFields, setShowPortFields] = useState(false);
    const [isRequired, setIsRequired] = useState(false);
    const [AppTypeHelperText, setAppTypeHelperText] = useState("");
    const [AppUrlHelperText, setAppUrlHelperText] = useState("");
    const [AppNameHelperText, setAppNameHelperText] = useState("");
    const [portNumberHelperText, setPortNumberHelperText] = useState("");
    const [defaultPortNumberHelperText, setDefaultPortNumberHelperText] = useState("");
    const [portDetailsNumberHelperText, setportDetailsNumberHelperText] = useState("");
    const [portFunctionHelperText, setPortFunctionHelperText] = useState("");
    const [portCriticalityHelperText, setPortCriticalityHelperText] = useState("");
    const [isSubmitSuccess, setIsSubmitSuccess] = useState(null);
    const [submissionMessage, setSubmissionMessage] = useState('');
    const [threasholds, setThreasholds] = useState([])
    const [warning, setWarning] = useState("");
    const [critical, setCritical] = useState("");
    const [applicationType, setApplicationType] = useState('');
    const [monitoringOptions, setMonitoringOptions] = useState([]);
    const [monitoringValue, setMonitoringValue] = useState(null);
    const [chosenSaasApp, setChosenSaasApp] = useState('');
    const [chosenGroups, setChosenGroups] = useState([]);
    const [chosenRegion, setChosenRegion] = useState([]);
    const [selectedRegionIds, setSelectedRegionIds] = useState([]);
    const [regionHelperText, setRegionHelperText] = useState('');
    const [latency, setLatency] = useState(150);
    const [jitter, setJitter] = useState(30);

    const [latencyHelperText, setLatencyHelperText] = useState('');
    const [jitterHelperText, setJitterHelperText] = useState('');
    const [probeRegionsData, setProbeRegionsData] = useState([]);


    // AddApplicationAPICall(selectedGroupIds, AppUrl, AppName, AppType, ports, setAddAppStatus, setSeverStatus, protocol, portNumber,monitoringValue,selectedRegionIds,latency,jitter setAppStatusMessage);


    const [selectedGroupHelperText, setSelectedGroupHelperText] = useState("");
    const [protocolHelperText, setProtocolHelperText] = useState("");
    const [protocolTypeHelperText, setProtocolTypeHelperText] = useState("");

    const [submitWithoutPort, setSubmitWithoutPort] = useState(false)
    const [atLeastOnePortAdded, setAtLeastOnePortAdded] = useState(false);

    const navigate = useNavigate();

    const appurlRegex = /^(www\.)?([-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})\b([-a-zA-Z0-9@:%_~#?&//=]*)$/;
    const ipRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
    const appNameRegex = /^[^"']*[A-Za-z0-9]$/;
    const portNoRegex = /^(?:[1-9]\d{0,3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/;
    const portFunctionRegex = /^[^"']*[A-Za-z0-9]$/;
    const portNumberRegex = /^(?:[1-9]\d{0,3}|[1-5]\d{4}|6[0-5]\d{0,3}|66000)$/;

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case "app_url": {
                if (!value.match(appurlRegex)) {
                    setAppUrlHelperText("App Url Should be Valid e.g. linkeye.io or www.google.com or 8.8.8.8");
                } else {
                    setAppUrlHelperText("");
                }
                setAppUrl(value);
                break;
            }
            case "protocol_Type": {
                const newProtocol = value;
                if (newProtocol === null || newProtocol === undefined || newProtocol === "") {
                    setProtocolHelperText("Select the Protocol type");
                } else {
                    setProtocolHelperText("");
                }
                setProtocol(newProtocol);
                break;
            }
            case "protocol_details_Type": {
                const newProtocol = value;
                if (newProtocol === null || newProtocol === undefined || newProtocol === "") {
                    setProtocolTypeHelperText("Select the Protocol type");
                } else {
                    setProtocolTypeHelperText("");
                }
                setProtocolDetails(newProtocol);
                break;
            }
            case "app_name": {
                if (!value.match(appNameRegex)) {
                    setAppNameHelperText("App Name Should be Valid");
                } else {
                    setAppNameHelperText("");
                }
                setAppName(value);
                break;
            }
            case "port_details_number": {
                if (!value.match(portNoRegex)) {
                    setportDetailsNumberHelperText("Port Number Should be Valid");
                } else {
                    setportDetailsNumberHelperText("");
                }
                setPortDetailsNumber(value);
                break;
            }
            case "port_number": {
                if (!value.match(portNoRegex)) {
                    setDefaultPortNumberHelperText("Port Number Should be Valid");
                } else {
                    setDefaultPortNumberHelperText("");
                }
                setPortNumber(value);
                break;
            }
            case "port_function": {
                if (!value.match(portFunctionRegex)) {
                    setPortFunctionHelperText("Port Function Should be Valid");
                } else {
                    setPortFunctionHelperText("");
                }
                setPortFunction(value);
                break;
            }

            case "default_port_number": {
                if (protocol === 1) { // Only validate if TCP protocol is selected
                    if (!value.match(portNumberRegex)) {
                        setDefaultPortNumberHelperText("Port Number should be valid");
                    } else {
                        setDefaultPortNumberHelperText("");
                    }
                }
                setPortNumber(value); // Update portNumber state
                break;
            }
            default: {
                setAppName(value);
                break;
            }


        }
    };

    useEffect(() => {
        // Fetch probe regions data on component mount
        GetRegionAppDropdownAPI(setProbeRegionsData);
    }, []);

    useEffect(() => {
        // Enable the Submit button based on these conditions
        setIsSubmitDisabled(!AppUrl || !AppName || !latency || !jitter || ports.length === 0);
    }, [AppUrl, AppName, latency, jitter, ports]);

    const handleOnSubmit = (e) => {
        e.preventDefault();

        if (AppType === 0) {
            if (!AppName.match(appNameRegex)) {
                setAppNameHelperText("App Name Should be Valid");
                return;
            }
            if (!AppUrl.match(appurlRegex)) {
                setAppUrlHelperText("App Url Should be Valid e.g. linkeye.io or www.google.com or 8.8.8.8");
                return;
            }
            if (protocol === null || protocol === undefined || protocol === "") {
                setProtocolHelperText("Select the Protocol Type");
                return;
            }
            if (protocol === 1 && !portNumber.match(portNumberRegex)) {
                setDefaultPortNumberHelperText("Port Number should be valid");
                return;
            }

            if (isRequired) {
                setSelectedGroupHelperText("Please select at least one group.");
                return;
            }

            if ((protocol === 2 || protocol === 3) && ports.length === 0) {
                setSubmitWithoutPort(true)
                setPortNumberHelperText("Please add at least one port.");
                return;
            }
            if (isPortFunctionRequired && !portFunction.match(portFunctionRegex)) {
                setPortFunctionHelperText("Port Function Should be Valid");
                return;
            }
            // AddApplicationAPICall(selectedGroupIds, AppUrl, AppName, AppType, ports, setAddAppStatus, setSeverStatus, protocol, portNumber, setAppStatusMessage);
            AddApplicationAPICall(selectedGroupIds, AppUrl, AppName, AppType, ports, setAddAppStatus, setSeverStatus, protocol, portNumber, monitoringValue, selectedRegionIds, latency, jitter, setAppStatusMessage);
        }
        if (AppType === 1) {
            // AddApplicationAPICall(selectedGroupIds, AppUrl, AppName, AppType, ports, setAddAppStatus, setSeverStatus, protocol, portNumber, setAppStatusMessage);
            AddApplicationAPICall(selectedGroupIds, AppUrl, AppName, AppType, ports, setAddAppStatus, setSeverStatus, protocol, portNumber, monitoringValue, selectedRegionIds, latency, jitter, setAppStatusMessage);
        }
        setAddAppOpen(true);
        setOpenAddAppDialog(false);
    };
    const handleSaasSelect = (event) => {
        const selectedSaasApp = event.target.value;
        const selectedSaas = saasAppData.find(saas => saas.app_name === selectedSaasApp);
        if (selectedSaas) {
            setSelectedSaasApp(selectedSaas);
            setAppId(selectedSaas.app_id);
            setAppUrl(selectedSaas.app_url);
            setAppName(selectedSaas.app_name);
            setPorts(selectedSaas.port_data);
        }
        setIsSubmitDisabled(AppType === 1 && (!selectedSaasApp || selectedSaasApp === "" || selectedGroupNames.length === 0));

    }

    
    

   
    
    

    const handleGroupSelectionChange = (event, values) => {
        const selectedIds = values.map((groupName) => {
            const selectedGroup = groups.find((group) => group.group_name === groupName);
            return selectedGroup ? selectedGroup.group_id : null;
        });

        const filteredIds = selectedIds.filter((id) => id !== null);

        setSelectedGroupIds(filteredIds);
        setSelectedGroupNames(values);
        setSelectedGroupHelperText("");
        setIsRequired(AppType === 0 && filteredIds.length === 0);

        // setIsSubmitDisabled(AppType === 1 && (!selectedSaasApp.app_name || selectedSaasApp === "" || filteredIds.length === 0));
        setIsSubmitDisabled(
            (AppType === 1 && (!selectedSaasApp.app_name || selectedSaasApp === "" || filteredIds.length === 0)) ||
            (AppType === 0 && filteredIds.length === 0)
        );

    };

    useEffect(() => {
        setGroupNames(groups.map(group => group.group_name));
        setIsRequired(AppType === 0 && selectedGroupNames.length === 0);
    }, [groups, AppType, selectedGroupNames]);

    const _GetSiteGroupDetailsApiCall = async () => {
        await GetSiteGroupDetailsApiCall(setGroups, setWarning, setCritical);

        setGroupNames(groups.map(group => group.group_name));
    }
    const _GetSaasAppDropdownListAPICall = async () => {
        await GetSaasAppDropdownListAPICall(setSaasAppData);
    }
    useEffect(() => {
        _GetSiteGroupDetailsApiCall();
        _GetSaasAppDropdownListAPICall();
    }, [])

    useEffect(() => {
        if (groups.length > 0) {
            setGroupNames(groups.map(group => group.group_name));
        }
    }, [groups])

    const handleOnChange1 = (e) => {
        const { name, value } = e.target;
        if (name === 'latency') {
            setLatency(value);
        } else if (name === 'jitter') {
            setJitter(value);
        } else {
            // handle other fields
        }
    };


    const handleOnCancel = () => {
        setOpenAddAppDialog(false);
    };

    const handleAppTypeChange = (e) => {
        setAppType(e.target.value)
        setSelectedGroupHelperText("");
         setIsRequired(e.target.value === 0 && selectedGroupNames.length === 0);
         setIsSubmitDisabled(e.target.value === 1 && (!selectedSaasApp.app_name || selectedSaasApp === "" || selectedGroupNames.length === 0));
    };
    const handlePortTypeChange = (e) => {
        setPortType(e.target.value)
    };
    const handlePortCriticalityChange = (e) => {
        setPortCriticality(e.target.value)
    };
    const handlePortCriticalityDetailsChange = (e) => {
        setPortCriticalityDetails(e.target.value)
    };
    const togglePortFields = () => {
        if (protocol === null || protocol === undefined || protocol === "") {
            setProtocolHelperText("Select the Protocol Type");
        }
        else {
            setShowPortFields(!showPortFields);
        }

    };
    const handleApplicationTypeChange = (event) => {
        setApplicationType(event.target.value);
    };



    // const handleRegionSelection = (event) => {
    //     const { value } = event.target;
    //     setChosenRegion(typeof value === 'string' ? value.split(',') : value);
    // };

    // const handleRegionSelection = (event) => {
    //     const value = event.target.value;
    //     setChosenRegion(typeof value === 'string' ? value.split(',') : value);
    //     setRegionHelperText(value ? '' : 'Region is required');
    // };

    const handleRegionSelection = (event) => {
        const selectedRegions = event.target.value;

        setChosenRegion(selectedRegions);


        const selectedIds = probeRegionsData
            .filter((region) => selectedRegions.includes(region.region_name))
            .map((region) => region.id);

        setSelectedRegionIds(selectedIds);
    };

    const handleSaasAppSelection = (event) => {
        const selectedAppName = event.target.value;
        // const selectedApp = saasAppList.find(app => app.app_name === selectedAppName);
        // setChosenSaasApp(selectedApp);
    };

    const handleGroupSelection = (event, value) => {
        setChosenGroups(value);
    };

    // const handleRegionSelection = (event) => {
    //     setChosenRegion(event.target.value);
    // };

    const handleMonitoringOptionsChange = (event) => {
        setMonitoringOptions(event.target.value);
    };

    const handleMonitoringChange = (event) => {
        const value = event.target.value;


        if (value.includes("Agent") && value.includes("Probe")) {
            setMonitoringOptions(["Both"]);
            setMonitoringValue(3);
        } else if (value.includes("Probe")) {
            setMonitoringOptions(["Probe"]);
            setMonitoringValue(2);
        } else if (value.includes("Agent")) {
            setMonitoringOptions(["Agent"]);
            setMonitoringValue(1);
        } else {
            setMonitoringOptions([]);
            setMonitoringValue(null);
        }
    };


    useEffect(() => {
        if (globalState === custId && mspStatus === 1) {
            setOpenWarnDialog(true);
        }
    }, [globalState]);

    useEffect(() => {
        setPorts([]);
    }, [AppType]);

    return (
        <>
            <Dialog open={openAddAppDialog} onClose={handleOnCancel} fullWidth maxWidth="md" aria-labelledby="responsive-dialog-title" >
                <DialogTitle sx={{ py: 2 }}>
                    Add Application
                    <IconButton sx={{ float: 'right' }} onClick={handleOnCancel}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ py: 3, px: 3 }}>
                    <form onSubmit={handleOnSubmit}>
                        <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: '1px' }}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="App-Type-lable" required>Select Application Type</InputLabel>
                                    <Select
                                        labelId="App-Type"
                                        id="App-Type"
                                        label="Select Application Type"
                                        style={{ width: "400px" }}
                                        value={AppType}
                                        onChange={handleAppTypeChange}
                                        helperText={<FormHelperText error>{AppTypeHelperText}</FormHelperText>}
                                        required
                                    >
                                        <MenuItem value={1}>SAAS</MenuItem>
                                        <MenuItem value={0}>INTERNAL</MenuItem>
                                    </Select>

                                </FormControl>
                            </Grid>
                            {AppType === 1 && (
                                <>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="side-group-label" required>
                                                Select SaaS app
                                            </InputLabel>
                                            <Select
                                                labelId="saas-group-label"
                                                id="saas-id"
                                                label="select saas app"
                                                style={{ width: "400px" }}
                                                value={selectedSaasApp ? selectedSaasApp.app_name : ""}
                                                onChange={handleSaasSelect}
                                                required
                                            >
                                                {saasAppData.map(saas => (
                                                    <MenuItem key={saas.app_id} value={saas.app_name}>
                                                        {saas.app_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="monitoring-options-label">Monitoring Type</InputLabel>
                                            <Select
                                                labelId="monitoring-options-label"
                                                label="Monitoring Type"
                                                id="monitoring-options"
                                                multiple
                                                style={{ width: "400px" }}
                                                value={monitoringOptions.includes("Both") ? ["Agent", "Probe"] : monitoringOptions}
                                                onChange={handleMonitoringChange}
                                                renderValue={(selected) => (
                                                    <div>
                                                        {selected.map((value) => (
                                                            <Chip key={value} label={value} style={{ margin: 2 }} />
                                                        ))}
                                                    </div>
                                                )}
                                            >
                                                <MenuItem value="Agent">
                                                    <Checkbox checked={monitoringOptions.includes("Agent") || monitoringOptions.includes("Both")} />
                                                    Agent
                                                </MenuItem>
                                                <MenuItem value="Probe">
                                                    <Checkbox checked={monitoringOptions.includes("Probe") || monitoringOptions.includes("Both")} />
                                                    Probe
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>






                                    {(monitoringOptions.includes("Agent") || monitoringOptions.includes("Both")) && (

                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    multiple
                                                    options={groupNames}
                                                    disableCloseOnSelect
                                                    value={selectedGroupNames}
                                                    onChange={handleGroupSelectionChange}
                                                    getOptionLabel={(option) => option}
                                                    renderOption={(props, option, { selected }) => (
                                                        <li {...props}>
                                                            <Checkbox
                                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                                style={{ marginRight: 8 }}
                                                                checked={selected}
                                                            />
                                                            {option}
                                                        </li>
                                                    )}
                                                    renderTags={(value, getTagProps) =>
                                                        value.map((option, index) => (
                                                            <Chip
                                                                label={option}
                                                                {...getTagProps({ index })}
                                                                style={{ margin: 2 }}
                                                            />
                                                        ))
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField {...params} variant="outlined" label="Select Site Group" />
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>

                                    )}


                                    {(monitoringOptions.includes("Probe") || monitoringOptions.includes("Both")) && (
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <InputLabel id="region-select-label">Select Regions</InputLabel>
                                                <Select
                                                    labelId="region-select-label"
                                                    label="Select Regions"
                                                    id="region-select"
                                                    multiple
                                                    value={chosenRegion}
                                                    onChange={handleRegionSelection}
                                                    renderValue={(selected) => (
                                                        <div style={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
                                                            {selected.map((region) => (
                                                                <Chip key={region} label={region} style={{ margin: 2 }} />
                                                            ))}
                                                        </div>
                                                    )}
                                                >
                                                    {probeRegionsData.map((region) => (
                                                        <MenuItem key={region.id} value={region.region_name}>
                                                            <Checkbox checked={chosenRegion.includes(region.region_name)} />
                                                            {region.region_name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>


                                    )}
                                </>
                            )}
                            {AppType === 0 && (
                                <>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Name"
                                            id="app_name"
                                            name='app_name'
                                            helperText={AppNameHelperText}
                                            error={!!AppNameHelperText}
                                            variant="outlined"
                                            style={{ width: "400px" }}
                                            onChange={handleOnChange}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Url/IP"
                                            id="app_url"
                                            name='app_url'
                                            helperText={AppUrlHelperText}
                                            error={!!AppUrlHelperText}
                                            variant="outlined"
                                            style={{ width: "400px" }}
                                            onChange={handleOnChange}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="monitoring-options-label">Monitoring Type</InputLabel>
                                            <Select
                                                labelId="monitoring-options-label"
                                                label="Monitoring Type"
                                                id="monitoring-options"
                                                multiple
                                                style={{ width: "400px" }}
                                                value={monitoringOptions.includes("Both") ? ["Agent", "Probe"] : monitoringOptions}
                                                onChange={handleMonitoringChange}
                                                renderValue={(selected) => (
                                                    <div>
                                                        {selected.map((value) => (
                                                            <Chip key={value} label={value} style={{ margin: 2 }} />
                                                        ))}
                                                    </div>
                                                )}
                                            >
                                                <MenuItem value="Agent">
                                                    <Checkbox checked={monitoringOptions.includes("Agent") || monitoringOptions.includes("Both")} />
                                                    Agent
                                                </MenuItem>
                                                <MenuItem value="Probe">
                                                    <Checkbox checked={monitoringOptions.includes("Probe") || monitoringOptions.includes("Both")} />
                                                    Probe
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <FormControl fullWidth error={!!protocolHelperText}>
                                            <InputLabel id="protocol-Type-label" required>Defualt Protocol</InputLabel>
                                            <Select
                                                labelId="protocol-Type-label"
                                                name="protocol_Type"
                                                label="Defualt Protocol"
                                                style={{ width: "400px" }}
                                                value={protocol}
                                                onChange={handleOnChange}
                                                required
                                            >
                                                <MenuItem value={0}>ICMP</MenuItem>
                                                <MenuItem value={1}>TCP</MenuItem>
                                            </Select>
                                            <FormHelperText error={!!protocolHelperText} sx={{ color: 'red' }}>{protocolHelperText}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    {/* <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                multiple
                                                options={groupNames}
                                                disableCloseOnSelect
                                                value={selectedGroupNames}
                                                onChange={handleGroupSelectionChange}
                                                getOptionLabel={(option) => option}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                            style={{ marginRight: 8 }}
                                                            key={option}
                                                            value={option}
                                                            checked={selected}
                                                        />
                                                        {option}
                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label="Select Site Group"
                                                        required={isRequired}
                                                        error={isRequired && selectedGroupHelperText.length > 0}
                                                        helperText={<FormHelperText sx={{ color: 'red' }} error={isRequired}>{selectedGroupHelperText}</FormHelperText>}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Grid> */}

                                    {(monitoringOptions.includes("Agent") || monitoringOptions.includes("Both")) && (

                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <Autocomplete
                                                    multiple
                                                    options={groupNames}
                                                    disableCloseOnSelect
                                                    value={selectedGroupNames}
                                                    onChange={handleGroupSelectionChange}
                                                    getOptionLabel={(option) => option}
                                                    renderOption={(props, option, { selected }) => (
                                                        <li {...props}>
                                                            <Checkbox
                                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                                style={{ marginRight: 8 }}
                                                                checked={selected}
                                                            />
                                                            {option}
                                                        </li>
                                                    )}
                                                    renderTags={(value, getTagProps) =>
                                                        value.map((option, index) => (
                                                            <Chip
                                                                label={option}
                                                                {...getTagProps({ index })}
                                                                style={{ margin: 2 }}
                                                            />
                                                        ))
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField {...params} variant="outlined" label="Select Site Group" />
                                                    )}
                                                />
                                            </FormControl>
                                        </Grid>

                                    )}


                                    {(monitoringOptions.includes("Probe") || monitoringOptions.includes("Both")) && (
                                        <Grid item xs={6}>
                                            <FormControl fullWidth>
                                                <InputLabel id="region-select-label">Select Regions</InputLabel>
                                                <Select
                                                    labelId="region-select-label"
                                                    label="Select Regions"
                                                    id="region-select"
                                                    multiple
                                                    value={chosenRegion}
                                                    onChange={handleRegionSelection}
                                                    renderValue={(selected) => (
                                                        <div style={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
                                                            {selected.map((region) => (
                                                                <Chip key={region} label={region} style={{ margin: 2 }} />
                                                            ))}
                                                        </div>
                                                    )}
                                                >
                                                    {probeRegionsData.map((region) => (
                                                        <MenuItem key={region.id} value={region.region_name}>
                                                            <Checkbox checked={chosenRegion.includes(region.region_name)} />
                                                            {region.region_name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>


                                    )}

                                    {protocol === 1 &&
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Default Port Number"
                                                id="port_number"
                                                name='port_number'
                                                variant="outlined"
                                                style={{ width: '400px' }}
                                                value={portNumber}
                                                helperText={defaultPortNumberHelperText}
                                                error={!!defaultPortNumberHelperText}
                                                onChange={handleOnChange}
                                                required
                                            />
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            color={showPortFields ? "error" : "primary"}
                                            onClick={togglePortFields}
                                            style={{ backgroundColor: showPortFields ? 'rgba(255, 0, 0, 0.7)' : '' }}
                                            startIcon={showPortFields ? <CancelIcon /> : <AddIcon />}
                                        >
                                            {showPortFields ? "Cancel" : "Add More Ports"}
                                        </Button>
                                    </Grid>
                                    {showPortFields && AppType === 0 && (protocol === 0 || protocol === 1) && (
                                        <>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Port Number"
                                                    id="port_details_number"
                                                    name='port_details_number'
                                                    variant="outlined"
                                                    style={{ width: '400px' }}
                                                    value={portDetailsNumber}
                                                    helperText={portDetailsNumberHelperText}
                                                    error={!!portDetailsNumberHelperText}
                                                    onChange={handleOnChange}
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth error={!!protocolHelperText}>
                                                    <InputLabel id="protocol-Type-label" required>Protocol Type</InputLabel>
                                                    <Select
                                                        labelId="protocol-Type-label"
                                                        name="protocol_details_Type"
                                                        label="Protocol Type"
                                                        style={{ width: "400px" }}
                                                        value={protocolDetails}
                                                        onChange={handleOnChange}
                                                        required
                                                    >
                                                        <MenuItem value={2}>TCP</MenuItem>
                                                        <MenuItem value={3}>UDP</MenuItem>
                                                    </Select>
                                                    <FormHelperText error={!!protocolHelperText} sx={{ color: 'red' }}>{protocolHelperText}</FormHelperText>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="criticality-label" required>
                                                        Port Criticality
                                                    </InputLabel>
                                                    <Select
                                                        labelId="criticality-label"
                                                        id="port_criticality"
                                                        label="Port Criticality"
                                                        style={{ width: "400px" }}
                                                        value={portCriticalityDetails}
                                                        helperText={portCriticalityHelperText}
                                                        error={!!portCriticalityHelperText}
                                                        onChange={handlePortCriticalityDetailsChange}
                                                    >
                                                        <MenuItem value="HIGH">High</MenuItem>
                                                        <MenuItem value="LOW">Low</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>


                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Port Functionality"
                                                    id="port_function"
                                                    name='port_function'
                                                    variant="outlined"
                                                    style={{ width: '400px' }}
                                                    value={portFunction}
                                                    helperText={portFunctionHelperText}
                                                    error={!!portFunctionHelperText}
                                                    onChange={handleOnChange}
                                                    required
                                                />
                                            </Grid>




                                            <Grid item xs={12}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => {
                                                        if (!portDetailsNumber.match(portNoRegex)) {
                                                            setportDetailsNumberHelperText("Port Number Should be Valid");
                                                            return;
                                                        }
                                                        if (!portFunction.match(portFunctionRegex)) {
                                                            setPortFunctionHelperText("Port Function Should be Valid");
                                                            return;
                                                        }
                                                        setPorts([...ports, { port_number: portDetailsNumber, port_type: protocolDetails === 2 ? "TCP" : "UDP", port_criticality: portCriticalityDetails, port_functionality: portFunction }]);
                                                        setPortDetailsNumber('');
                                                        setPortFunction('');
                                                        setAtLeastOnePortAdded(true);
                                                    }}
                                                >
                                                    Ok
                                                </Button>
                                            </Grid>
                                        </>
                                    )}
                                </>
                            )}
                            <Grid item xs={6}>
                                <TextField
                                    label="Latency(in ms)*"
                                    id="latency"
                                    name="latency"
                                    value={latency}
                                    helperText={latencyHelperText}
                                    error={!!latencyHelperText}
                                    variant="outlined"
                                    style={{ width: "400px" }}
                                    onChange={handleOnChange1}
                                    required
                                />
                            </Grid>

                            {/* Jitter Field */}
                            <Grid item xs={6}>
                                <TextField
                                    label="Jitter(in ms)*"
                                    id="jitter"
                                    name="jitter"
                                    value={jitter}
                                    helperText={jitterHelperText}
                                    error={!!jitterHelperText}
                                    variant="outlined"
                                    style={{ width: "400px" }}
                                    onChange={handleOnChange1}
                                    required
                                />
                            </Grid>
                        </Grid>
                        <br />
                        {AppType === 0 && ports.map((port, index) => (
                            <div key={index} >
                                Port Number: {port.port_number}, Port Type: {port.port_type}, Port Criticality: {port.port_criticality},   Port Function: {port.port_functionality}
                                <Button
                                    variant="outlined"
                                    style={{ color: "primary", border: "none" }}
                                    onClick={() => {
                                        const updatedPorts = ports.filter((_, i) => i !== index);
                                        setPorts(updatedPorts);
                                    }}
                                >
                                    <CancelIcon />
                                </Button>
                            </div>
                        ))}
                        {AppType === 1 && ports.map((port, index) => (
                            <div key={index} >
                                Port Number: {port.port_number}, Port Type: {port.port_type}, Port Criticality: {port.port_criticality},   Port Function: {port.port_functionality}
                            </div>
                        ))}

                    </form>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={handleOnCancel}>Cancel</Button>
                    <Button variant="contained" color="primary" onClick={handleOnSubmit}  >Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}