// import axios from 'axios';

// const GetAppRegionTableAPI = async (setSaasAppData) => {
//   const url = `${process.env.REACT_APP_API_SERVER_URL}/get_application_global_default_metric.php`;

  
//   const payload = JSON.stringify({
//     auth_token: "99d8ba7c712005760b7376c26",
//     client_id: "5",
//     time: "12 HOUR",
//     app_id: "54",
//   });

//   try {
//     const response = await axios.post(url, payload, {
//       headers: { 'Content-Type': 'application/json' },
//     });

//     if (response.data && response.data.code === 1) {
//       const saasData = response.data.application_data;
//       setSaasAppData(saasData); 
//     } else {
//       setSaasAppData([]);
//     }
//   } catch (error) {
//     console.error("Error fetching application metrics:", error);
//     setSaasAppData([]); 
//   }
// };

// export default GetAppRegionTableAPI;

import axios from 'axios';

const GetAppRegionTableAPI = async (appID, time, setRegionData) => {
  const url = `${process.env.REACT_APP_API_SERVER_URL}/get_application_global_default_metric.php`;

    const payload = JSON.stringify({
        auth_token: localStorage.getItem("auth_token"),
        client_id: localStorage.getItem("cust_id"),
        time,
    app_id: appID,
  });

  try {
    const response = await axios.post(url, payload);
    if (response.data.code === 1) {
      const saasData = response.data.application_data;
      setRegionData(saasData); 
    } else {
        setRegionData(null); 
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    setRegionData(null); 
  }
};

export default GetAppRegionTableAPI;
