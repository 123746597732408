// import PropTypes from 'prop-types';
// import { useRef, useState, useEffect } from 'react';
// import { Navigate, Link as DownloadLink, useNavigate } from "react-router-dom";
// import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';

// // material

// import {
//   AppBar,
//   Box,
//   InputLabel,
//   IconButton,
//   Paper, Stack,
//   Toolbar,
//   Typography,
//   Snackbar,
//   Alert,
//   Badge,
//   Menu,
//   MenuItem,
//   Select,
//   FormControl,
//   Grid
// } from '@mui/material';
// import HelpIcon from '@mui/icons-material/Help';
// import NotificationsIcon from '@mui/icons-material/Notifications';

// import { alpha, styled } from '@mui/material/styles';
// import { makeStyles } from '@mui/styles';

// import SiteLogo from "../../Assets/site_logo_bg_removed.png";
// import GoodSiteLogo from "../../Assets/good_site_logo_bg_removed.png";
// import DownSiteLogo from "../../Assets/down_site_logo_bg_removed.png";
// import PausedSiteLogo from "../../Assets/bad_site_logo_bg_removed_black.png";
// import TimeLogo from "../../Assets/time_image.png";
// import ServerStatus from "../../Assets/server_status.png";

// // components
// import { SiteStatusCountAPICall } from './SiteStatusCountAPICall';
// import { GetNotificationsApiCall } from './GetNotificationsApiCall';
// import { UpdateIssueStatusApiCall } from './UpdateIssueStatusApiCall';
// import LogoutAlertDialog from './LogoutAlertDialog';
// import { GetMspMappedCustomersDetailsApiCall } from '../../components/CustomersComponents/Components/GetMspMappedCustomersDetailsApiCall';
// import { useGlobalState } from '../../globalHooks/GlobalState';
// // import {GetBusinessDetailsApiCall} from '../../components/BusinessUnitComponents/Components/GetBusinessDetailsApiCall';
// import GetBusinessDetailsApiCall from './GetBusinessDetailsApiCall';
// //
// import pdf from "../../Assets/LinkEye.pdf";
// import AccountPopover from './AccountPopover';
// import ChangeLogoPopover from './ChangeLogoPopover';


// // import LanguagePopover from './LanguagePopover';
// // import NotificationsPopover from './NotificationsPopover';

// // ----------------------------------------------------------------------

// const DRAWER_WIDTH = 280;
// const APPBAR_MOBILE = 80;
// const APPBAR_DESKTOP = 92;

// const RootStyle = styled(AppBar)(({ theme }) => ({
//   boxShadow: 'none',
//   // backdropFilter: 'blur(6px)',
//   WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
//   backgroundColor: '#f9fafb', // alpha(theme.palette.background.default, 0.72),
//   zIndex: theme.zIndex.drawer + 1,
//   [theme.breakpoints.up('lg')]: {
//     width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
//   },
// }));

// const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
//   minHeight: APPBAR_MOBILE,
//   [theme.breakpoints.up('lg')]: {
//     minHeight: APPBAR_DESKTOP,
//     padding: theme.spacing(0, 5),
//   },
// }));

// const useStyles = makeStyles({
//   boxPadding: {
//     padding: '12px',
//   },
//   boxItems: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'flex-start',
//   },
//   cardsContainer: {
//     display: 'flex',
//     gap: 16,
//     marginRight: '8px',
//     flexWrap: 'wrap',
//   },
// });

// // ----------------------------------------------------------------------

// DashboardNavbar.propTypes = {
//   onOpenSidebar: PropTypes.func,
// };

// export default function DashboardNavbar({ onOpenSidebar }) {
//   const classes = useStyles();

//   const [totalSites, setTotalSites] = useState(0);
//   const [upSites, setUpSites] = useState(0);
//   const [downSites, setDownSites] = useState(0);
//   const [locations, setLocations] = useState([]);
//   const [notAssignedLocations, setNotAssignedLocations] = useState([]);
//   const [updatedTime, setUpdatedTime] = useState("");
//   const [unAssignedSites, setUnAssignedSites] = useState(0);
//   const [intProbeStatus, setIntProbeStatus] = useState("");
//   const [locationData, setLocationdata] = useState(null);
//   const [logoutAlert, setLogoutAlert] = useState(false);
//   // const [image, setImage] = useState(null);
//   const [updateStatus, setUpdateStatus] = useState(0);
//   const [errorMessage, setErrorMessage] = useState("");
//   const [notifications, setNotifications] = useState([]);
//   const [notifiCount, setNotifiCount] = useState(0);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [anchorElNotMonitored, setAnchorElNotMonitored] = useState(null);
//   const [notifiAnchorEl, setNotifiAnchorEl] = useState(null);
//   const [issueStatus, setIssueStatus] = useState(false);
//   const [customers, setCustomers] = useState([]);
//   const [buCustomers, setBuCustomers] = useState([]);
//   const [selectedCustId, setSelectedCustId] = useState(parseInt(localStorage.getItem("reg_cust_id"), 10));

//   const { globalState, setGlobalState } = useGlobalState();
//   // =================BU==============
//   const { buState, setBuState } = useGlobalState();
//   const { buName, setBuName } = useGlobalState();
//   const [selectedBuId, setSelectedBuId] = useState(-1);

//   // =================================
//   const [isLoggingOut, setIsLoggingOut] = useState(false);
//   const [openNotMonitoredDialog, setopenNotMonitoredDialog] = useState(false);
//   const [openDialog, setOpenDialog] = useState(false);
//   const [clickedLocation, setClickedLocation] = useState("");

//   const open = Boolean(anchorEl);
//   const openNotMonitored = Boolean(anchorElNotMonitored)
//   const navigate = useNavigate();

//   const anchorRef = useRef(null);

//   const buStateRef = useRef(buState);
//   useEffect(() => {
//     buStateRef.current = buState;
//   }, [buState]);

//   // Handle click on notification icon
//   const handleNotificationClick = (event) => {
//     setNotifiAnchorEl(event.currentTarget);

//   };

//   // Handle close notification menu
//   const handleNotificationClose = (note) => {
//     setNotifiAnchorEl(null);
//     _SetIssueStatus(note.issue_id, setIssueStatus);
//   };


//   const handleSnackClose = (event, reason) => {
//     if (reason === 'clickaway') {
//       return;
//     }
//     setUpdateStatus(0);

//   };

//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);

//   };

//   const handleOnClick = (event) => {
//     setAnchorElNotMonitored(event.currentTarget);
//   }

//   const handleOnCloseNotMonitored = (event) => {
//     setAnchorElNotMonitored(null)
//     // if(typeof notAssignedLocations[event.target.value] !== 'undefined'){
//     //   handleOpenDialog();
//     // }
//     setAnchorElNotMonitored(null);
//     if (typeof notAssignedLocations[event.target.value] !== 'undefined') {
//       // Set the clicked location
//       setClickedLocation(notAssignedLocations[event.target.value].location);
//       // Open the dialog box
//       setOpenDialog(true);
//     }
//   }

//   const handleOpenDialog = () => {
//     setOpenDialog(true);
//   };

//   const handleCloseDialog = () => {
//     setOpenDialog(false);
//   };
//   const handleClose = (event) => {
//     setAnchorEl(null);
//     if (typeof locations[event.target.value] !== 'undefined') {
//       setLocationdata(locations[event.target.value]);
//       // console.log(locations[event.target.value])
//       navigate("/dashboard/site-down", { state: { location: locations[event.target.value] } })
//     }
//   };
//   const handleOnClose = () => {
//     setopenNotMonitoredDialog(false);
//   };

//   const _SiteStatusCountAPICall = async () => {
//     SiteStatusCountAPICall(setTotalSites, setUpSites, setDownSites, setLocations, setUpdatedTime, setLogoutAlert, setIntProbeStatus, setUnAssignedSites, setNotAssignedLocations, buStateRef.current);
//   }

//   const _GetNotifications = async () => {
//     GetNotificationsApiCall(setNotifiCount, setNotifications);
//   }

//   const _SetIssueStatus = async (issueId) => {
//     UpdateIssueStatusApiCall(issueId, setIssueStatus);
//   }

//   const _GetMspMappedCustomersDetailsApiCall = () => {
//     GetMspMappedCustomersDetailsApiCall(setCustomers);
//   }

//   const _GetBuDetailsApiCall = () => {
//     GetBusinessDetailsApiCall(setBuCustomers);
//   }

//   useEffect(() => {
//     _SiteStatusCountAPICall(); // Initial call

//     const intervalId = setInterval(() => {
//       _SiteStatusCountAPICall();
//     }, 60 * 1000);

//     return () => clearInterval(intervalId);
//   }, []);

//   useEffect(() => {
//     _SiteStatusCountAPICall();
//   }, [buState])

//   useEffect(() => {
//     _GetBuDetailsApiCall();
//   }, [])

//   useEffect(() => {
//     setGlobalState(parseInt(localStorage.getItem("reg_cust_id"), 10))
//     _SiteStatusCountAPICall();
//     _GetNotifications();
//     _GetMspMappedCustomersDetailsApiCall();
//     setIssueStatus(false)
//     setInterval(() => {
//       _SiteStatusCountAPICall();
//       _GetNotifications();
//       _GetMspMappedCustomersDetailsApiCall();
//       setIssueStatus(false)
//     }, 60 * 1000);
//     setSelectedCustId(parseInt(localStorage.getItem("reg_cust_id"), 10))
//   }, [issueStatus, localStorage.getItem("reg_cust_id")], globalState);

//   useEffect(() => {
//     if (!localStorage.getItem('auth_token'))
//       navigate('/', { replace: true });
//   }, []);

//   const afterLogout = () => {
//     if (isLoggingOut) return;
//     setIsLoggingOut(true);
//     localStorage.clear()
//     navigate('/', { replace: true });
//   }

//   const onChangeCustomer = (e) => {
//     setSelectedCustId(e.target.value);
//     localStorage.setItem("reg_cust_id", e.target.value);
//     setGlobalState(parseInt(e.target.value, 10))
//   }



//   const onChangeBu = (e) => {
//     setSelectedBuId(e.target.value);
//     localStorage.setItem("bu_id", e.target.value);
//     const selectedBu = buCustomers.find(bu => bu.bu_id === e.target.value);
//     if (selectedBu) {
//       setBuName(selectedBu.bu_name);
//     }

//     setBuState(e.target.value);
//   }


//   return (
//     <RootStyle >
//       <ToolbarStyle>
//         <div className={classes.cardsContainer} data-tut="linkeyetour_home_sitessummarynumbers">
//           <Paper sx={{ minWidth: 'fit-content', px: 2 }} elevation={15}>
//             <Box className={classes.boxPadding} >
//               <Box className={classes.boxItems}>
//                 <img src={SiteLogo} alt="logo" style={{ width: '50px', height: "40px", marginRight: "15px" }} />
//                 <div>
//                   <Typography variant="subtitle1" component="div">
//                     Total Sites
//                   </Typography>
//                   <Typography sx={{ color: 'green' }} variant="subtitle1" component="div">
//                     {totalSites}
//                   </Typography>
//                 </div>
//               </Box>
//             </Box>
//           </Paper>
//           <Paper sx={{ minWidth: 'fit-content', px: 2 }} elevation={15}>
//             <Box className={classes.boxPadding}>
//               <Box className={classes.boxItems}>

//                 <img src={GoodSiteLogo} alt="logo" style={{ width: '50px', height: "40px", marginRight: "15px" }} />
//                 <div>
//                   <Typography variant="subtitle1" component="div">
//                     Sites Up
//                   </Typography>
//                   <Typography sx={{ color: 'green' }} variant="subtitle1" component="div">
//                     {upSites}
//                   </Typography>
//                 </div>
//               </Box>
//             </Box>
//           </Paper>
//           <Paper sx={{ minWidth: 'fit-content', px: 2 }} elevation={15}  >
//             <Box className={classes.boxPadding}
//               id="basic-button"
//               aria-controls={open ? 'basic-menu' : undefined}
//               aria-haspopup="true"
//               aria-expanded={open ? 'true' : undefined}
//               onClick={handleClick}
//               sx={{ cursor: 'pointer' }}
//             >
//               <Box className={classes.boxItems}>

//                 <img src={DownSiteLogo} alt="logo" style={{ width: '50px', height: "40px", marginRight: "15px" }} />
//                 <div>
//                   <Typography variant="subtitle1" component="div">
//                     Sites Down
//                   </Typography>
//                   <Typography sx={{ color: 'red' }} variant="subtitle1" component="div">
//                     {downSites}
//                   </Typography>
//                 </div>
//               </Box>
//             </Box>
//           </Paper>

//           <Paper sx={{ minWidth: 'fit-content', px: 2, background:"#D3D3D3"  }} elevation={15}  >
//             <Box className={classes.boxPadding}
//               id="basic-button"
//               aria-controls={open ? 'basic-menu' : undefined}
//               aria-haspopup="true"
//               aria-expanded={open ? 'true' : undefined}
//               onClick={handleOnClick}
//               sx={{ cursor: 'pointer'}}
//             >
//               <Box className={classes.boxItems}>
//                 <img src={PausedSiteLogo} alt="logo" style={{ width: '50px', height: "40px", marginRight: "15px" }} />
//                 <div>
//                   <Typography variant="subtitle1" component="div">
//                     Sites Not Monitored
//                   </Typography>
//                   <Typography variant="subtitle1" component="div">
//                     {unAssignedSites}
//                   </Typography>
//                 </div>
//               </Box>
//             </Box>
//           </Paper>
//           <Paper sx={{ minWidth: 'fit-content', px: 2 }} elevation={15}>
//             <Box className={classes.boxPadding}>
//               <Box className={classes.boxItems}>

//                 <img src={TimeLogo} alt="logo" style={{ width: '40px', height: "40px", marginRight: "15px" }} />
//                 <div>
//                   <Typography variant="subtitle1" component="div">
//                     Last Updated
//                   </Typography>
//                   <Typography sx={{ color: 'green' }} variant="subtitle1" component="div">
//                     {updatedTime}
//                   </Typography>
//                 </div>
//               </Box>
//             </Box>
//           </Paper>

//           <Paper sx={{ minWidth: 'fit-content', px: 2 }} elevation={15}>
//             <Box className={classes.boxPadding}>
//               <Box className={classes.boxItems}>

//                 <img src={ServerStatus} alt="logo" style={{ width: '40px', height: "40px", marginRight: "15px" }} />
//                 <div>
//                   <Typography variant="subtitle1" component="div">
//                     Internal Probe Status
//                   </Typography>
//                   <Typography sx={{ color: intProbeStatus === "Connected" ? "green" : "red" }} variant="subtitle1" component="div">
//                     {intProbeStatus}
//                   </Typography>
//                 </div>
//               </Box>
//             </Box>
//           </Paper>

//           <Menu
//             id="basic-menu"
//             anchorEl={anchorEl}
//             open={open}
//             onClose={handleClose}
//           >
//             {locations.length !== 0 ? <MenuItem><strong>Down Sites</strong></MenuItem> : <MenuItem><strong>No Down Sites</strong></MenuItem>}
//             {locations.map((item, index) =>
//             (
//               <MenuItem key={index} value={index} onClick={handleClose}>{item.location}</MenuItem>
//             )
//             )}
//           </Menu>
//           <Menu
//             id="basic-menu"
//             anchorEl={anchorElNotMonitored}
//             open={openNotMonitored}
//             onClose={handleOnCloseNotMonitored}
//           >
//             {notAssignedLocations.length !== 0 ? <MenuItem><strong>Sites Not Monitored</strong></MenuItem> : <MenuItem><strong>Every Sites are Monitored</strong></MenuItem>}
//             {notAssignedLocations.map((item, index) =>
//             (
//               <MenuItem key={index} value={index} onClick={handleOnCloseNotMonitored}>{item.location}</MenuItem>
//             )
//             )}
//           </Menu>
//         </div>

//         <Dialog
//           open={openDialog}
//           onClose={handleCloseDialog}
//           aria-labelledby="alert-dialog-title"
//           aria-describedby="alert-dialog-description"
//           PaperProps={{
//             style: {
//               width: '700px',
//               height: '250px',
//             },
//           }}
//         >
//           <DialogContent>
//             <DialogTitle id="alert-dialog-title" style={{ fontWeight: 'bold', fontSize: '20px', padding: '10px' }}>
//               Status Info
//             </DialogTitle>
//             <hr />
//             <DialogContentText style={{ fontSize: '18px', padding: '10px' }} >
//               {`Links Not Assigned /There is No Link Data Coming for location/Site Paused: ${clickedLocation}.`}
//             </DialogContentText>
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleCloseDialog}>Ok</Button>
//           </DialogActions>
//         </Dialog>

//         <Box sx={{ flexGrow: 1 }} />

//         {localStorage.getItem("user_id") !== "1644" && localStorage.getItem("user_id") !== "220" && localStorage.getItem("user_id") !== "1873" && localStorage.getItem("user_id") !== "16" && localStorage.getItem("user_id") !== "1792" && localStorage.getItem("user_id") !== "1428" && <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
//           <ChangeLogoPopover />
//         </Stack>}

//         <IconButton variant="outlined" color="primary" onClick={handleNotificationClick} data-tut="linkeyetour_home_changelogo">
//           <Badge badgeContent={notifiCount} color="error">
//             <NotificationsIcon style={{ width: '50px', height: "40px" }} />
//           </Badge>
//         </IconButton>

//         <Menu
//           anchorEl={notifiAnchorEl}
//           open={Boolean(notifiAnchorEl)}
//           onClose={handleNotificationClose}
//         >
//           {notifiCount !== 0 && <MenuItem onClick={() => { handleNotificationClose({ "issue_id": -1 }) }} style={{ width: 200 }}>Clear All</MenuItem>}
//           {notifiCount === 0 && <MenuItem style={{ width: 200 }}>No Notifications</MenuItem>}
//           {notifications.map((note, index) =>
//           (
//             <MenuItem key={index} value={index} onClick={() => { handleNotificationClose(note) }} style={{ width: 200 }}>
//               <Typography variant="body1" sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
//                 {note.issue_details}
//               </Typography>
//             </MenuItem>
//           )
//           )}
//         </Menu>

//         <IconButton variant="outlined" color="primary" ref={anchorRef} data-tut="linkeyetour_home_producthelpdocument" >
//           <DownloadLink to={pdf} without rel="noopener noreferrer" target="_blank" style={{ textDecoration: 'none', color: "#3491FC", }}>
//             <HelpIcon style={{ width: '50px', height: "40px" }} />
//           </DownloadLink>
//         </IconButton>

//         <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
//           <AccountPopover setUpdateStatus={setUpdateStatus} setErrorMessage={setErrorMessage} />
//         </Stack>

//         {logoutAlert && <LogoutAlertDialog logoutAlert={logoutAlert} setLogoutAlert={setLogoutAlert} afterLogout={afterLogout} />}

//       </ToolbarStyle>
//       {localStorage.getItem("msp_status") === "1" &&
//         <Grid container>
//           <Grid item xs={8} sm={8} md={8} lg={8}>
//             {""}
//           </Grid>
//           <Grid item xs={3} sm={3} md={3} lg={3}>
//             <FormControl fullWidth>
//               <InputLabel id="Customer-label">Customer</InputLabel>
//               <Select
//                 labelId="Customer-label"
//                 id="Customer"
//                 style={{ width: "100%" }}
//                 label="Customer"
//                 value={selectedCustId}
//                 onChange={onChangeCustomer}
//               >
//                 <MenuItem value={parseInt(localStorage.getItem("cust_id"), 10)}>All</MenuItem>
//                 {
//                   customers.map((row, index) => {
//                     return <MenuItem value={row.cust_id}>{row.cust_name}</MenuItem>
//                   })
//                 }
//               </Select>
//             </FormControl>
//           </Grid>
//         </Grid>}
//       {localStorage.getItem("bu_status") === "1" && <Grid container>
//         <Grid item xs={8} sm={8} md={8} lg={8}>
//           {""}
//         </Grid>
//         <Grid item xs={3} sm={3} md={3} lg={3}>
//           <FormControl fullWidth>
//             <InputLabel id="BU-label">Select Business Unit</InputLabel>
//             <Select
//               labelId="BU-label"
//               id="Business Unit"
//               style={{ width: "100%" }}
//               label="Select Business Unit"
//               value={selectedBuId}
//               onChange={onChangeBu}
//             >
//               <MenuItem value={-1}>All</MenuItem>
//               {
//                 buCustomers.map((row, index) => {
//                   return <MenuItem value={row.bu_id}>{row.bu_name}</MenuItem>
//                 })
//               }
//             </Select>
//           </FormControl>
//         </Grid>

//       </Grid>}

//       {updateStatus === 1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
//         <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%', backgroundColor: '#C8E6C9', color: '#388E3C' }}>
//           {errorMessage}
//         </Alert>
//       </Snackbar>}
//       {updateStatus === 2 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
//         <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%', backgroundColor: '#FFEBEE', color: '#B71C1C' }}>
//           {errorMessage}
//         </Alert>
//       </Snackbar>}
//       {updateStatus === -1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
//         <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%', backgroundColor: '#FFEBEE', color: '#B71C1C' }}>
//           Error: {errorMessage}
//         </Alert>
//       </Snackbar>}
//     </RootStyle>
//   );
// }

// import PropTypes from 'prop-types';
// import { useRef, useState, useEffect } from 'react';
// import { Navigate, Link as DownloadLink, useNavigate } from "react-router-dom";
// import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';

// // material

// import {
//   AppBar,
//   Box,
//   InputLabel,
//   IconButton,
//   Paper, Stack,
//   Toolbar,
//   Typography,
//   Snackbar,
//   Alert,
//   Badge,
//   Menu,
//   MenuItem,
//   Select,
//   FormControl,
//   Grid,
//   Card
// } from '@mui/material';
// import HelpIcon from '@mui/icons-material/Help';
// import NotificationsIcon from '@mui/icons-material/Notifications';

// import { alpha, styled } from '@mui/material/styles';
// import { makeStyles } from '@mui/styles';

// import SiteLogo from "../../Assets/site_logo_bg_removed.png";
// import GoodSiteLogo from "../../Assets/good_site_logo_bg_removed.png";
// import DownSiteLogo from "../../Assets/down_site_logo_bg_removed.png";
// import PausedSiteLogo from "../../Assets/bad_site_logo_bg_removed_black.png";
// import TimeLogo from "../../Assets/time_image.png";
// import ServerStatus from "../../Assets/server_status.png";

// // components
// import { SiteStatusCountAPICall } from './SiteStatusCountAPICall';
// import { GetNotificationsApiCall } from './GetNotificationsApiCall';
// import { UpdateIssueStatusApiCall } from './UpdateIssueStatusApiCall';
// import LogoutAlertDialog from './LogoutAlertDialog';
// import { GetMspMappedCustomersDetailsApiCall } from '../../components/CustomersComponents/Components/GetMspMappedCustomersDetailsApiCall';
// import { useGlobalState } from '../../globalHooks/GlobalState';
// // import {GetBusinessDetailsApiCall} from '../../components/BusinessUnitComponents/Components/GetBusinessDetailsApiCall';
// import GetBusinessDetailsApiCall from './GetBusinessDetailsApiCall';
// //
// import pdf from "../../Assets/LinkEye.pdf";
// import AccountPopover from './AccountPopover';
// import ChangeLogoPopover from './ChangeLogoPopover';


// // import LanguagePopover from './LanguagePopover';
// // import NotificationsPopover from './NotificationsPopover';

// // ----------------------------------------------------------------------

// const DRAWER_WIDTH = 280;
// const APPBAR_MOBILE = 80;
// const APPBAR_DESKTOP = 92;

// // Styled AppBar
// const RootStyle = styled(AppBar)(({ theme }) => ({
//   boxShadow: 'none',
//   WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
//   backdropFilter: 'blur(6px)',
//   backgroundColor: '#f9fafb', // alpha(theme.palette.background.default, 0.72),
//   zIndex: theme.zIndex.drawer + 1,
//   width: '100%',
//   [theme.breakpoints.up('lg')]: {
//     width: `calc(100% - ${DRAWER_WIDTH}px)`,
//   },
// }));

// // Styled Toolbar
// const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
//   minHeight: APPBAR_MOBILE,
//   [theme.breakpoints.up('lg')]: {
//     minHeight: APPBAR_DESKTOP,
//     padding: theme.spacing(0, 5),
//   },
// }));

// // makeStyles with media queries
// const useStyles = makeStyles((theme) => ({
//   cardsContainer: {
//     display: 'flex',
//     justifyContent: 'space-between', // Ensures equal spacing between cards
//     gap: theme.spacing(1), // Reduced gap between cards
//     flexWrap: 'nowrap', // Prevents wrapping, ensures all cards are in one row
//     marginRight: theme.spacing(1),

//     // For responsiveness:
//     [theme.breakpoints.down('sm')]: {
//       flexDirection: 'row', // Keep cards in one row for small screens
//       overflowX: 'auto', // Adds horizontal scroll for small screens if cards overflow
//       gap: theme.spacing(0.5), // Smaller gap for mobile screens
//     },
//   },

//   boxPadding: {
//     padding: '12px',
//     [theme.breakpoints.down('sm')]: {
//       padding: '8px',
//     },
//   },
//   boxItems: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'flex-start',
//   },
//   statusText: {
//     [theme.breakpoints.down('sm')]: {
//       fontSize: '0.9rem',
//     },
//     [theme.breakpoints.up('sm')]: {
//       fontSize: '1rem',
//     },
//   },
//   statusNumber: {
//     [theme.breakpoints.down('sm')]: {
//       fontSize: '1rem',
//     },
//     [theme.breakpoints.up('sm')]: {
//       fontSize: '1.2rem',
//     },
//   },
//   iconImage: {
//     width: '50px',
//     height: '40px',
//     marginRight: '15px',
//     [theme.breakpoints.down('sm')]: {
//       width: '40px',
//       height: '30px',
//       marginRight: '10px',
//     },
//     [theme.breakpoints.up('md')]: {
//       width: '50px',
//       height: '40px',
//       marginRight: '15px',
//     },
//     [theme.breakpoints.up('xl')]: {
//       width: '60px',
//       height: '50px',
//       marginRight: '20px',
//     },
//   },
// }));

// // ----------------------------------------------------------------------

// DashboardNavbar.propTypes = {
//   onOpenSidebar: PropTypes.func,
// };

// export default function DashboardNavbar({ onOpenSidebar }) {
//   const classes = useStyles();

//   const [totalSites, setTotalSites] = useState(0);
//   const [upSites, setUpSites] = useState(0);
//   const [downSites, setDownSites] = useState(0);
//   const [locations, setLocations] = useState([]);
//   const [notAssignedLocations, setNotAssignedLocations] = useState([]);
//   const [updatedTime, setUpdatedTime] = useState("");
//   const [unAssignedSites, setUnAssignedSites] = useState(0);
//   const [intProbeStatus, setIntProbeStatus] = useState("");
//   const [locationData, setLocationdata] = useState(null);
//   const [logoutAlert, setLogoutAlert] = useState(false);
//   const [updateStatus, setUpdateStatus] = useState(0);
//   const [errorMessage, setErrorMessage] = useState("");
//   const [notifications, setNotifications] = useState([]);
//   const [notifiCount, setNotifiCount] = useState(0);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [anchorElNotMonitored, setAnchorElNotMonitored] = useState(null);
//   const [notifiAnchorEl, setNotifiAnchorEl] = useState(null);
//   const [issueStatus, setIssueStatus] = useState(false);
//   const [customers, setCustomers] = useState([]);
//   const [buCustomers, setBuCustomers] = useState([]);
//   const [selectedCustId, setSelectedCustId] = useState(parseInt(localStorage.getItem("reg_cust_id"), 10));

//   const { globalState, setGlobalState } = useGlobalState();
//   // =================BU==============
//   const { buState, setBuState } = useGlobalState();
//   const { buName, setBuName } = useGlobalState();
//   const [selectedBuId, setSelectedBuId] = useState(-1);

//   // =================================
//   const [isLoggingOut, setIsLoggingOut] = useState(false);
//   const [openNotMonitoredDialog, setopenNotMonitoredDialog] = useState(false);
//   const [openDialog, setOpenDialog] = useState(false);
//   const [clickedLocation, setClickedLocation] = useState("");

//   const open = Boolean(anchorEl);
//   const openNotMonitored = Boolean(anchorElNotMonitored);
//   const navigate = useNavigate();

//   const anchorRef = useRef(null);

//   const buStateRef = useRef(buState);
//   useEffect(() => {
//     buStateRef.current = buState;
//   }, [buState]);

//   // Handle click on notification icon
//   const handleNotificationClick = (event) => {
//     setNotifiAnchorEl(event.currentTarget);
//   };

//   // Handle close notification menu
//   const handleNotificationClose = (note) => {
//     setNotifiAnchorEl(null);
//     _SetIssueStatus(note.issue_id, setIssueStatus);
//   };

//   const handleSnackClose = (event, reason) => {
//     if (reason === 'clickaway') {
//       return;
//     }
//     setUpdateStatus(0);
//   };

//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleOnClick = (event) => {
//     setAnchorElNotMonitored(event.currentTarget);
//   }

//   const handleOnCloseNotMonitored = (event) => {
//     setAnchorElNotMonitored(null);
//     if (typeof notAssignedLocations[event.target.value] !== 'undefined') {
//       // Set the clicked location
//       setClickedLocation(notAssignedLocations[event.target.value].location);
//       // Open the dialog box
//       setOpenDialog(true);
//     }
//   }

//   const handleOpenDialog = () => {
//     setOpenDialog(true);
//   };

//   const handleCloseDialog = () => {
//     setOpenDialog(false);
//   };

//   const handleClose = (event) => {
//     setAnchorEl(null);
//     if (typeof locations[event.target.value] !== 'undefined') {
//       setLocationdata(locations[event.target.value]);
//       navigate("/dashboard/site-down", { state: { location: locations[event.target.value] } })
//     }
//   };

//   const handleOnClose = () => {
//     setopenNotMonitoredDialog(false);
//   };

//   const _SiteStatusCountAPICall = async () => {
//     SiteStatusCountAPICall(setTotalSites, setUpSites, setDownSites, setLocations, setUpdatedTime, setLogoutAlert, setIntProbeStatus, setUnAssignedSites, setNotAssignedLocations, buStateRef.current);
//   }

//   const _GetNotifications = async () => {
//     GetNotificationsApiCall(setNotifiCount, setNotifications);
//   }

//   const _SetIssueStatus = async (issueId) => {
//     UpdateIssueStatusApiCall(issueId, setIssueStatus);
//   }

//   const _GetMspMappedCustomersDetailsApiCall = () => {
//     GetMspMappedCustomersDetailsApiCall(setCustomers);
//   }

//   const _GetBuDetailsApiCall = () => {
//     GetBusinessDetailsApiCall(setBuCustomers);
//   }


//   useEffect(() => {
//     _SiteStatusCountAPICall(); // Initial call

//     const intervalId = setInterval(() => {
//       _SiteStatusCountAPICall();
//     }, 60 * 1000);

//     return () => clearInterval(intervalId);
//   }, []);

//   useEffect(() => {
//     _SiteStatusCountAPICall();
//   }, [buState]);

//   useEffect(() => {
//     _GetBuDetailsApiCall();
//   }, []);

//   useEffect(() => {
//     setGlobalState(parseInt(localStorage.getItem("reg_cust_id"), 10))
//     _SiteStatusCountAPICall();
//     _GetNotifications();
//     _GetMspMappedCustomersDetailsApiCall();
//     setIssueStatus(false)
//     const interval = setInterval(() => {
//       _SiteStatusCountAPICall();
//       _GetNotifications();
//       _GetMspMappedCustomersDetailsApiCall();
//       setIssueStatus(false)
//     }, 60 * 1000);
//     return () => clearInterval(interval);
//   }, [issueStatus, localStorage.getItem("reg_cust_id")], globalState);

//   useEffect(() => {
//     if (!localStorage.getItem('auth_token'))
//       navigate('/', { replace: true });
//   }, []);

//   const afterLogout = () => {
//     if (isLoggingOut) return;
//     setIsLoggingOut(true);
//     localStorage.clear()
//     navigate('/', { replace: true });
//   }

//   const onChangeCustomer = (e) => {
//     setSelectedCustId(e.target.value);
//     localStorage.setItem("reg_cust_id", e.target.value);
//     setGlobalState(parseInt(e.target.value, 10))
//   }

//   const onChangeBu = (e) => {
//     setSelectedBuId(e.target.value);
//     localStorage.setItem("bu_id", e.target.value);
//     const selectedBu = buCustomers.find(bu => bu.bu_id === e.target.value);
//     if (selectedBu) {
//       setBuName(selectedBu.bu_name);
//     }

//     setBuState(e.target.value);
//   }

//   // Responsive Grid breakpoints mapping
//   // xs: 0px
//   // sm: 600px (closest to 768px)
//   // md: 900px (closest to 1024px)
//   // lg: 1200px (closest to 1440px)
//   // xl: 1536px (closest to 2560px)

//   return (
//     <RootStyle>
//       <ToolbarStyle>
//         {/* Status Cards Container */}
//         <Grid container spacing={2} className={classes.cardsContainer} data-tut="linkeyetour_home_sitessummarynumbers">
//   {/* Status Cards Section */}
//   <Grid item xs={12}>
//     <Grid container spacing={2}>

//       {/* Total Sites */}
//       <Grid item xs={12} sm={6} md={4} lg={4}>
//         <Card elevation={15} sx={{ p: 1, width: '100%' }}>
//           <Box className={classes.boxItems}>
//             <img src={SiteLogo} alt="Total Sites" className={classes.iconImage} />
//             <Box>
//               <Typography variant="subtitle1" component="div" className={classes.statusText} noWrap>
//                 Total Sites
//               </Typography>
//               <Typography variant="subtitle1" component="div" className={classes.statusNumber} sx={{ color: 'green' }} noWrap>
//                 {totalSites}
//               </Typography>
//             </Box>
//           </Box>
//         </Card>
//       </Grid>

//       {/* Sites Up */}
//       <Grid item xs={12} sm={6} md={4} lg={4}>
//         <Card elevation={15} sx={{ p: 1, width: '100%' }}>
//           <Box className={classes.boxItems}>
//             <img src={GoodSiteLogo} alt="Sites Up" className={classes.iconImage} />
//             <Box>
//               <Typography variant="subtitle1" component="div" className={classes.statusText} noWrap>
//                 Sites Up
//               </Typography>
//               <Typography variant="subtitle1" component="div" className={classes.statusNumber} sx={{ color: 'green' }} noWrap>
//                 {upSites}
//               </Typography>
//             </Box>
//           </Box>
//         </Card>
//       </Grid>

//       {/* Sites Down */}
//       <Grid item xs={12} sm={6} md={4} lg={4}>
//         <Card elevation={15} sx={{ p: 1, width: '100%', cursor: 'pointer' }} onClick={handleClick}>
//           <Box className={classes.boxItems}>
//             <img src={DownSiteLogo} alt="Sites Down" className={classes.iconImage} />
//             <Box>
//               <Typography variant="subtitle1" component="div" className={classes.statusText} noWrap>
//                 Sites Down
//               </Typography>
//               <Typography variant="subtitle1" component="div" className={classes.statusNumber} sx={{ color: 'red' }} noWrap>
//                 {downSites}
//               </Typography>
//             </Box>
//           </Box>
//         </Card>
//       </Grid>

//       {/* Sites Not Monitored */}
//       <Grid item xs={12} sm={6} md={4} lg={4}>
//         <Card elevation={15} sx={{ p: 1, width: '100%', background: "#D3D3D3", cursor: 'pointer' }} onClick={handleOnClick}>
//           <Box className={classes.boxItems}>
//             <img src={PausedSiteLogo} alt="Sites Not Monitored" className={classes.iconImage} />
//             <Box>
//               <Typography variant="subtitle1" component="div" className={classes.statusText} noWrap>
//                 Sites Not Monitored
//               </Typography>
//               <Typography variant="subtitle1" component="div" className={classes.statusNumber} noWrap>
//                 {unAssignedSites}
//               </Typography>
//             </Box>
//           </Box>
//         </Card>
//       </Grid>

//       {/* Last Updated */}
//       <Grid item xs={12} sm={6} md={4} lg={4}>
//         <Card elevation={15} sx={{ p: 1, width: '100%' }}>
//           <Box className={classes.boxItems}>
//             <img src={TimeLogo} alt="Last Updated" className={classes.iconImage} />
//             <Box>
//               <Typography variant="subtitle1" component="div" className={classes.statusText} noWrap>
//                 Last Updated
//               </Typography>
//               <Typography variant="subtitle1" component="div" className={classes.statusNumber} sx={{ color: 'green' }} noWrap>
//                 {updatedTime}
//               </Typography>
//             </Box>
//           </Box>
//         </Card>
//       </Grid>

//       {/* Internal Probe Status */}
//       <Grid item xs={12} sm={6} md={4} lg={4}>
//         <Card elevation={15} sx={{ p: 1, width: '100%' }}>
//           <Box className={classes.boxItems}>
//             <img src={ServerStatus} alt="Internal Probe Status" className={classes.iconImage} />
//             <Box>
//               <Typography variant="subtitle1" component="div" className={classes.statusText} noWrap>
//                 Internal Probe Status
//               </Typography>
//               <Typography variant="subtitle1" component="div" className={classes.statusNumber} sx={{ color: intProbeStatus === "Connected" ? "green" : "red" }} noWrap>
//                 {intProbeStatus}
//               </Typography>
//             </Box>
//           </Box>
//         </Card>
//       </Grid>
//     </Grid>
//   </Grid>

//   {/* Logo and Notifications Section */}
//   <Grid item xs={12}>
//   <Box
//     display="flex"
//     justifyContent="space-between"
//     alignItems="center"
//     width="100%"
   
//     flexWrap="nowrap" // Prevent wrapping
//   >
//     {/* Business Unit Selector - Only Render if bu_status is 1 */}
//     {localStorage.getItem("bu_status") === "1" && (
//       <Box sx={{ flexGrow: 1, ml: 20 }}>
//         <FormControl fullWidth>
//           <InputLabel id="bu-label">Select Business Unit</InputLabel>
//           <Select
//             labelId="bu-label"
//             id="bu-select"
//             label="Select Business Unit"
//             value={selectedBuId}
//             onChange={onChangeBu}
//             sx={{ textAlign: 'left', width: '150%' }} // Take full width
//           >
//             <MenuItem value={-1}>All</MenuItem>
//             {buCustomers.map((row, index) => (
//               <MenuItem key={index} value={row.bu_id}>{row.bu_name}</MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//       </Box>
//     )}

//     {/* Icons Section */}
//     <Box
//       display="flex"
//       alignItems="center"
//       flexWrap="nowrap" // Ensure no wrapping
//       sx={{
//         justifyContent: 'flex-end',
//         flexGrow: 1,
//         ml: 2, // Adjust left margin for spacing
//       }}
//     >
//       {/* Conditional Render for ChangeLogoPopover */}
//       {["1644", "220", "1873", "16", "1792", "1428"].includes(localStorage.getItem("user_id")) ? null : (
//   <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}> {/* Reduced margin-right */}
//     <IconButton
//       variant="outlined"
//       color="primary"
//       disableRipple // Prevent ripple effect
//       sx={{ bgcolor: 'transparent', '&:hover': { bgcolor: 'transparent' } }} // Remove hover background color
//     >
//       <ChangeLogoPopover />
//     </IconButton>
//   </Box>
// )}


// {/* Notification Icon */}
// <IconButton
//   variant="outlined"
//   color="primary"
//   onClick={handleNotificationClick}
//   sx={{ ml: 1, disableRipple: true }} // Adjusted left margin to reduce space
// >
//   <Badge badgeContent={notifiCount} color="error">
//     <NotificationsIcon sx={{ width: { xs: 30, sm: 40 }, height: { xs: 30, sm: 40 } }} />
//   </Badge>
// </IconButton>

// {/* Help Icon */}
// <IconButton
//   variant="outlined"
//   color="primary"
//   ref={anchorRef}
//   disableRipple
//   sx={{ ml: 1, bgcolor: 'transparent', '&:hover': { bgcolor: 'transparent' } }} // Adjusted left margin to reduce space
// >
//   <DownloadLink to={pdf} rel="noopener noreferrer" target="_blank" style={{ textDecoration: 'none', color: "#3491FC" }}>
//     <HelpIcon sx={{ width: { xs: 30, sm: 40 }, height: { xs: 30, sm: 40 } }} />
//   </DownloadLink>
// </IconButton>

// {/* Account Popover */}
// <Stack direction="row" alignItems="center" spacing={0.5} sx={{ mr: 1}}> {/* Reduced spacing */}
//   <AccountPopover setUpdateStatus={setUpdateStatus} setErrorMessage={setErrorMessage} />
// </Stack>

     
//     </Box>
//   </Box>
// </Grid>








// </Grid>






//         {/* Menus for Sites Down and Sites Not Monitored */}
//         <Menu
//           id="sites-down-menu"
//           anchorEl={anchorEl}
//           open={open}
//           onClose={handleClose}
//           anchorOrigin={{
//             vertical: 'bottom',
//             horizontal: 'left',
//           }}
//           transformOrigin={{
//             vertical: 'top',
//             horizontal: 'left',
//           }}
//         >
//           {locations.length !== 0 ? <MenuItem disabled><strong>Down Sites</strong></MenuItem> : <MenuItem disabled><strong>No Down Sites</strong></MenuItem>}
//           {locations.map((item, index) => (
//             <MenuItem key={index} value={index} onClick={handleClose}>{item.location}</MenuItem>
//           ))}


//         </Menu>

//         <Menu
//           id="sites-not-monitored-menu"
//           anchorEl={anchorElNotMonitored}
//           open={openNotMonitored}
//           onClose={handleOnCloseNotMonitored}
//           anchorOrigin={{
//             vertical: 'bottom',
//             horizontal: 'left',
//           }}
//           transformOrigin={{
//             vertical: 'top',
//             horizontal: 'left',
//           }}
//         >
//           {notAssignedLocations.length !== 0 ? <MenuItem disabled><strong>Sites Not Monitored</strong></MenuItem> : <MenuItem disabled><strong>All Sites are Monitored</strong></MenuItem>}
//           {notAssignedLocations.map((item, index) => (
//             <MenuItem key={index} value={index} onClick={handleOnCloseNotMonitored}>{item.location}</MenuItem>
//           ))}
//         </Menu>
//       </ToolbarStyle>

//       {/* Status Info Dialog */}
//       <Dialog
//         open={openDialog}
//         onClose={handleCloseDialog}
//         aria-labelledby="status-info-dialog-title"
//         aria-describedby="status-info-dialog-description"
//         PaperProps={{
//           sx: {
//             width: { xs: '90%', sm: '80%', md: '700px' },
//             maxWidth: '100%',
//             height: { xs: 'auto', sm: '250px' },
//           },
//         }}
//       >
//         <DialogTitle id="status-info-dialog-title" sx={{ fontWeight: 'bold', fontSize: { xs: '1.2rem', sm: '1.5rem' }, padding: 2 }}>
//           Status Info
//         </DialogTitle>
//         <DialogContent>
//           <DialogContentText id="status-info-dialog-description" sx={{ fontSize: { xs: '1rem', sm: '1.2rem' }, padding: 2 }}>
//             {`Links Not Assigned / No Link Data Coming for location/Site Paused: ${clickedLocation}.`}
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseDialog} variant="contained">Ok</Button>
//         </DialogActions>
//       </Dialog>

//       {/* Right Side Icons and Buttons */}
//       <Box sx={{ flexGrow: 1 }} />

//       {/* Conditional ChangeLogoPopover */}


//       {/* Notifications Icon */}


//       {/* Notifications Menu */}
//       <Menu
//         anchorEl={notifiAnchorEl}
//         open={Boolean(notifiAnchorEl)}
//         onClose={handleNotificationClose}
//         anchorOrigin={{
//           vertical: 'bottom',
//           horizontal: 'right',
//         }}
//         transformOrigin={{
//           vertical: 'top',
//           horizontal: 'right',
//         }}
//       >
//         {notifiCount !== 0 ? (
//           <>
//             <MenuItem onClick={() => { handleNotificationClose({ "issue_id": -1 }) }} sx={{ width: 200 }}>Clear All</MenuItem>
//             {notifications.map((note, index) => (
//               <MenuItem key={index} value={index} onClick={() => { handleNotificationClose(note) }} sx={{ width: 200 }}>
//                 <Typography variant="body1" sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
//                   {note.issue_details}
//                 </Typography>
//               </MenuItem>
//             ))}
//           </>
//         ) : (
//           <MenuItem sx={{ width: 200 }}>No Notifications</MenuItem>
//         )}
//       </Menu>

//       {/* Help Icon */}


//       {/* Account Popover */}


//       {/* Logout Alert Dialog */}
//       {logoutAlert && <LogoutAlertDialog logoutAlert={logoutAlert} setLogoutAlert={setLogoutAlert} afterLogout={afterLogout} />}

//       {/* Customer Selection */}
//       {localStorage.getItem("msp_status") === "1" && (
//         <Grid container spacing={2} sx={{ mt: 2, px: { xs: 2, sm: 5, md: 10 } }}>
//           <Grid item xs={12} sm={8} md={8} lg={8}>
//             {/* Placeholder for alignment */}
//           </Grid>
//           <Grid item xs={12} sm={4} md={4} lg={4}>
//             <FormControl fullWidth>
//               <InputLabel id="customer-label">Customer</InputLabel>
//               <Select
//                 labelId="customer-label"
//                 id="customer-select"
//                 label="Customer"
//                 value={selectedCustId}
//                 onChange={onChangeCustomer}
//                 sx={{ textAlign: 'left' }}
//               >
//                 <MenuItem value={parseInt(localStorage.getItem("cust_id"), 10)}>All</MenuItem>
//                 {customers.map((row, index) => (
//                   <MenuItem key={index} value={row.cust_id}>{row.cust_name}</MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//           </Grid>
//         </Grid>
//       )}

//       {/* Business Unit Selection */}


//       {/* Snackbar Notifications */}
//       {updateStatus === 1 && (
//         <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
//           <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%', backgroundColor: '#C8E6C9', color: '#388E3C' }}>
//             {errorMessage}
//           </Alert>
//         </Snackbar>
//       )}
//       {updateStatus === 2 && (
//         <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
//           <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%', backgroundColor: '#FFEBEE', color: '#B71C1C' }}>
//             {errorMessage}
//           </Alert>
//         </Snackbar>
//       )}
//       {updateStatus === -1 && (
//         <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
//           <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%', backgroundColor: '#FFEBEE', color: '#B71C1C' }}>
//             Error: {errorMessage}
//           </Alert>
//         </Snackbar>
//       )}
//     </RootStyle>
//   );
// }


import PropTypes from 'prop-types';
import { useRef, useState, useEffect } from 'react';
import { Navigate, Link as DownloadLink, useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// material

import {
  AppBar,
  Box,
  InputLabel,
  IconButton,
  Paper, Stack,
  Toolbar,
  Typography,
  Snackbar,
  Alert,
  Badge,
  Menu,
  MenuItem,
  Select,
  FormControl,
  Grid
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { alpha, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import SiteLogo from "../../Assets/site_logo_bg_removed.png";
import GoodSiteLogo from "../../Assets/good_site_logo_bg_removed.png";
import DownSiteLogo from "../../Assets/down_site_logo_bg_removed.png";
import PausedSiteLogo from "../../Assets/bad_site_logo_bg_removed_black.png";
import TimeLogo from "../../Assets/time_image.png";
import ServerStatus from "../../Assets/server_status.png";

// components
import { SiteStatusCountAPICall } from './SiteStatusCountAPICall';
import { GetNotificationsApiCall } from './GetNotificationsApiCall';
import { UpdateIssueStatusApiCall } from './UpdateIssueStatusApiCall';
import LogoutAlertDialog from './LogoutAlertDialog';
import { GetMspMappedCustomersDetailsApiCall } from '../../components/CustomersComponents/Components/GetMspMappedCustomersDetailsApiCall';
import { useGlobalState } from '../../globalHooks/GlobalState';
// import {GetBusinessDetailsApiCall} from '../../components/BusinessUnitComponents/Components/GetBusinessDetailsApiCall';
import GetBusinessDetailsApiCall from './GetBusinessDetailsApiCall';
//
import pdf from "../../Assets/LinkEye.pdf";
import AccountPopover from './AccountPopover';
import ChangeLogoPopover from './ChangeLogoPopover';


// import LanguagePopover from './LanguagePopover';
// import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 80;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  // backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: '#f9fafb', // alpha(theme.palette.background.default, 0.72),
  zIndex: theme.zIndex.drawer + 1,
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const useStyles = makeStyles({
  boxPadding: {
    padding: '12px',
  },
  boxItems: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  cardsContainer: {
    display: 'flex',
    gap: 16,
    marginRight: '8px',
    flexWrap: 'wrap',
  },
});

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const classes = useStyles();

  const [totalSites, setTotalSites] = useState(0);
  const [upSites, setUpSites] = useState(0);
  const [downSites, setDownSites] = useState(0);
  const [locations, setLocations] = useState([]);
  const [notAssignedLocations, setNotAssignedLocations] = useState([]);
  const [updatedTime, setUpdatedTime] = useState("");
  const [unAssignedSites, setUnAssignedSites] = useState(0);
  const [intProbeStatus, setIntProbeStatus] = useState("");
  const [locationData, setLocationdata] = useState(null);
  const [logoutAlert, setLogoutAlert] = useState(false);
  // const [image, setImage] = useState(null);
  const [updateStatus, setUpdateStatus] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [notifiCount, setNotifiCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElNotMonitored, setAnchorElNotMonitored] = useState(null);
  const [notifiAnchorEl, setNotifiAnchorEl] = useState(null);
  const [issueStatus, setIssueStatus] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [buCustomers, setBuCustomers] = useState([]);
  const [selectedCustId, setSelectedCustId] = useState(parseInt(localStorage.getItem("reg_cust_id"), 10));

  const { globalState, setGlobalState } = useGlobalState();
  // =================BU==============
  const { buState, setBuState } = useGlobalState();
  const { buName, setBuName } = useGlobalState();
  const [selectedBuId, setSelectedBuId] = useState(-1);

  // =================================
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [openNotMonitoredDialog, setopenNotMonitoredDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [clickedLocation, setClickedLocation] = useState("");

  const open = Boolean(anchorEl);
  const openNotMonitored = Boolean(anchorElNotMonitored)
  const navigate = useNavigate();

  const anchorRef = useRef(null);

  const buStateRef = useRef(buState);
  useEffect(() => {
    buStateRef.current = buState;
  }, [buState]);

  // Handle click on notification icon
  const handleNotificationClick = (event) => {
    setNotifiAnchorEl(event.currentTarget);

  };

  // Handle close notification menu
  const handleNotificationClose = (note) => {
    setNotifiAnchorEl(null);
    _SetIssueStatus(note.issue_id, setIssueStatus);
  };


  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUpdateStatus(0);

  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

  };

  const handleOnClick = (event) => {
    setAnchorElNotMonitored(event.currentTarget);
  }

  const handleOnCloseNotMonitored = (event) => {
    setAnchorElNotMonitored(null)
    // if(typeof notAssignedLocations[event.target.value] !== 'undefined'){
    //   handleOpenDialog();
    // }
    setAnchorElNotMonitored(null);
    if (typeof notAssignedLocations[event.target.value] !== 'undefined') {
      // Set the clicked location
      setClickedLocation(notAssignedLocations[event.target.value].location);
      // Open the dialog box
      setOpenDialog(true);
    }
  }

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
    if (typeof locations[event.target.value] !== 'undefined') {
      setLocationdata(locations[event.target.value]);
      // console.log(locations[event.target.value])
      navigate("/dashboard/site-down", { state: { location: locations[event.target.value] } })
    }
  };
  const handleOnClose = () => {
    setopenNotMonitoredDialog(false);
  };

  const _SiteStatusCountAPICall = async () => {
    SiteStatusCountAPICall(setTotalSites, setUpSites, setDownSites, setLocations, setUpdatedTime, setLogoutAlert, setIntProbeStatus, setUnAssignedSites, setNotAssignedLocations, buStateRef.current);
  }

  const _GetNotifications = async () => {
    GetNotificationsApiCall(setNotifiCount, setNotifications);
  }

  const _SetIssueStatus = async (issueId) => {
    UpdateIssueStatusApiCall(issueId, setIssueStatus);
  }

  const _GetMspMappedCustomersDetailsApiCall = () => {
    GetMspMappedCustomersDetailsApiCall(setCustomers);
  }

  const _GetBuDetailsApiCall = () => {
    GetBusinessDetailsApiCall(setBuCustomers);
  }

  useEffect(() => {
    _SiteStatusCountAPICall(); // Initial call

    const intervalId = setInterval(() => {
      _SiteStatusCountAPICall();
    }, 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    _SiteStatusCountAPICall();
  }, [buState])

  useEffect(() => {
    _GetBuDetailsApiCall();
  }, [])

  useEffect(() => {
    setGlobalState(parseInt(localStorage.getItem("reg_cust_id"), 10))
    _SiteStatusCountAPICall();
    _GetNotifications();
    _GetMspMappedCustomersDetailsApiCall();
    setIssueStatus(false)
    setInterval(() => {
      _SiteStatusCountAPICall();
      _GetNotifications();
      _GetMspMappedCustomersDetailsApiCall();
      setIssueStatus(false)
    }, 60 * 1000);
    setSelectedCustId(parseInt(localStorage.getItem("reg_cust_id"), 10))
  }, [issueStatus, localStorage.getItem("reg_cust_id")], globalState);

  useEffect(() => {
    if (!localStorage.getItem('auth_token'))
      navigate('/', { replace: true });
  }, []);

  const afterLogout = () => {
    if (isLoggingOut) return;
    setIsLoggingOut(true);
    localStorage.clear()
    navigate('/', { replace: true });
  }

  const onChangeCustomer = (e) => {
    setSelectedCustId(e.target.value);
    localStorage.setItem("reg_cust_id", e.target.value);
    setGlobalState(parseInt(e.target.value, 10))
  }



  const onChangeBu = (e) => {
    setSelectedBuId(e.target.value);
    localStorage.setItem("bu_id", e.target.value);
    const selectedBu = buCustomers.find(bu => bu.bu_id === e.target.value);
    if (selectedBu) {
      setBuName(selectedBu.bu_name);
    }

    setBuState(e.target.value);
  }

  
  return (
    <RootStyle >
      <ToolbarStyle>
        <div className={classes.cardsContainer} data-tut="linkeyetour_home_sitessummarynumbers">
          <Paper sx={{ minWidth: 'fit-content', px: 2 }} elevation={15}>
            <Box className={classes.boxPadding} >
              <Box className={classes.boxItems}>
                <img src={SiteLogo} alt="logo" style={{ width: '50px', height: "40px", marginRight: "15px" }} />
                <div>
                  <Typography variant="subtitle1" component="div">
                    Total Sites
                  </Typography>
                  <Typography sx={{ color: 'green' }} variant="subtitle1" component="div">
                    {totalSites}
                  </Typography>
                </div>
              </Box>
            </Box>
          </Paper>
          <Paper sx={{ minWidth: 'fit-content', px: 2 }} elevation={15}>
            <Box className={classes.boxPadding}>
              <Box className={classes.boxItems}>

                <img src={GoodSiteLogo} alt="logo" style={{ width: '50px', height: "40px", marginRight: "15px" }} />
                <div>
                  <Typography variant="subtitle1" component="div">
                    Sites Up
                  </Typography>
                  <Typography sx={{ color: 'green' }} variant="subtitle1" component="div">
                    {upSites}
                  </Typography>
                </div>
              </Box>
            </Box>
          </Paper>
          <Paper sx={{ minWidth: 'fit-content', px: 2 }} elevation={15}  >
            <Box className={classes.boxPadding}
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              sx={{ cursor: 'pointer' }}
            >
              <Box className={classes.boxItems}>

                <img src={DownSiteLogo} alt="logo" style={{ width: '50px', height: "40px", marginRight: "15px" }} />
                <div>
                  <Typography variant="subtitle1" component="div">
                    Sites Down
                  </Typography>
                  <Typography sx={{ color: 'red' }} variant="subtitle1" component="div">
                    {downSites}
                  </Typography>
                </div>
              </Box>
            </Box>
          </Paper>

          <Paper sx={{ minWidth: 'fit-content', px: 2, background:"#D3D3D3"  }} elevation={15}  >
            <Box className={classes.boxPadding}
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleOnClick}
              sx={{ cursor: 'pointer'}}
            >
              <Box className={classes.boxItems}>
                <img src={PausedSiteLogo} alt="logo" style={{ width: '50px', height: "40px", marginRight: "15px" }} />
                <div>
                  <Typography variant="subtitle1" component="div">
                    Sites Not Monitored
                  </Typography>
                  <Typography variant="subtitle1" component="div">
                    {unAssignedSites}
                  </Typography>
                </div>
              </Box>
            </Box>
          </Paper>
          <Paper sx={{ minWidth: 'fit-content', px: 2 }} elevation={15}>
            <Box className={classes.boxPadding}>
              <Box className={classes.boxItems}>

                <img src={TimeLogo} alt="logo" style={{ width: '40px', height: "40px", marginRight: "15px" }} />
                <div>
                  <Typography variant="subtitle1" component="div">
                    Last Updated
                  </Typography>
                  <Typography sx={{ color: 'green' }} variant="subtitle1" component="div">
                    {updatedTime}
                  </Typography>
                </div>
              </Box>
            </Box>
          </Paper>

          <Paper sx={{ minWidth: 'fit-content', px: 2 }} elevation={15}>
            <Box className={classes.boxPadding}>
              <Box className={classes.boxItems}>

                <img src={ServerStatus} alt="logo" style={{ width: '40px', height: "40px", marginRight: "15px" }} />
                <div>
                  <Typography variant="subtitle1" component="div">
                    Internal Probe Status
                  </Typography>
                  <Typography sx={{ color: intProbeStatus === "Connected" ? "green" : "red" }} variant="subtitle1" component="div">
                    {intProbeStatus}
                  </Typography>
                </div>
              </Box>
            </Box>
          </Paper>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {locations.length !== 0 ? <MenuItem><strong>Down Sites</strong></MenuItem> : <MenuItem><strong>No Down Sites</strong></MenuItem>}
            {locations.map((item, index) =>
            (
              <MenuItem key={index} value={index} onClick={handleClose}>{item.location}</MenuItem>
            )
            )}
          </Menu>
          <Menu
            id="basic-menu"
            anchorEl={anchorElNotMonitored}
            open={openNotMonitored}
            onClose={handleOnCloseNotMonitored}
          >
            {notAssignedLocations.length !== 0 ? <MenuItem><strong>Sites Not Monitored</strong></MenuItem> : <MenuItem><strong>Every Sites are Monitored</strong></MenuItem>}
            {notAssignedLocations.map((item, index) =>
            (
              <MenuItem key={index} value={index} onClick={handleOnCloseNotMonitored}>{item.location}</MenuItem>
            )
            )}
          </Menu>
        </div>

        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              width: '700px',
              height: '250px',
            },
          }}
        >
          <DialogContent>
            <DialogTitle id="alert-dialog-title" style={{ fontWeight: 'bold', fontSize: '20px', padding: '10px' }}>
              Status Info
            </DialogTitle>
            <hr />
            <DialogContentText style={{ fontSize: '18px', padding: '10px' }} >
              {`Links Not Assigned /There is No Link Data Coming for location/Site Paused: ${clickedLocation}.`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Ok</Button>
          </DialogActions>
        </Dialog>

        <Box sx={{ flexGrow: 1 }} />

        {localStorage.getItem("user_id") !== "1644" && localStorage.getItem("user_id") !== "220" && localStorage.getItem("user_id") !== "1873" && localStorage.getItem("user_id") !== "16" && localStorage.getItem("user_id") !== "1792" && localStorage.getItem("user_id") !== "1428" && <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <ChangeLogoPopover />
        </Stack>}

        <IconButton variant="outlined" color="primary" onClick={handleNotificationClick} data-tut="linkeyetour_home_changelogo">
          <Badge badgeContent={notifiCount} color="error">
            <NotificationsIcon style={{ width: '50px', height: "40px" }} />
          </Badge>
        </IconButton>

        <Menu
          anchorEl={notifiAnchorEl}
          open={Boolean(notifiAnchorEl)}
          onClose={handleNotificationClose}
        >
          {notifiCount !== 0 && <MenuItem onClick={() => { handleNotificationClose({ "issue_id": -1 }) }} style={{ width: 200 }}>Clear All</MenuItem>}
          {notifiCount === 0 && <MenuItem style={{ width: 200 }}>No Notifications</MenuItem>}
          {notifications.map((note, index) =>
          (
            <MenuItem key={index} value={index} onClick={() => { handleNotificationClose(note) }} style={{ width: 200 }}>
              <Typography variant="body1" sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                {note.issue_details}
              </Typography>
            </MenuItem>
          )
          )}
        </Menu>

        <IconButton variant="outlined" color="primary" ref={anchorRef} data-tut="linkeyetour_home_producthelpdocument" >
          <DownloadLink to={pdf} without rel="noopener noreferrer" target="_blank" style={{ textDecoration: 'none', color: "#3491FC", }}>
            <HelpIcon style={{ width: '50px', height: "40px" }} />
          </DownloadLink>
        </IconButton>

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <AccountPopover setUpdateStatus={setUpdateStatus} setErrorMessage={setErrorMessage} />
        </Stack>

        {logoutAlert && <LogoutAlertDialog logoutAlert={logoutAlert} setLogoutAlert={setLogoutAlert} afterLogout={afterLogout} />}

      </ToolbarStyle>
      {localStorage.getItem("msp_status") === "1" &&
        <Grid container>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            {""}
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3}>
            <FormControl fullWidth>
              <InputLabel id="Customer-label">Customer</InputLabel>
              <Select
                labelId="Customer-label"
                id="Customer"
                style={{ width: "100%" }}
                label="Customer"
                value={selectedCustId}
                onChange={onChangeCustomer}
              >
                <MenuItem value={parseInt(localStorage.getItem("cust_id"), 10)}>All</MenuItem>
                {
                  customers.map((row, index) => {
                    return <MenuItem value={row.cust_id}>{row.cust_name}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
          </Grid>
        </Grid>}
      {localStorage.getItem("bu_status") === "1" && <Grid container>
        <Grid item xs={8} sm={8} md={8} lg={8}>
          {""}
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3}>
          <FormControl fullWidth>
            <InputLabel id="BU-label">Select Business Unit</InputLabel>
            <Select
              labelId="BU-label"
              id="Business Unit"
              style={{ width: "100%" }}
              label="Select Business Unit"
              value={selectedBuId}
              onChange={onChangeBu}
            >
              <MenuItem value={-1}>All</MenuItem>
              {
                buCustomers.map((row, index) => {
                  return <MenuItem value={row.bu_id}>{row.bu_name}</MenuItem>
                })
              }
            </Select>
          </FormControl>
        </Grid>

      </Grid>}

      {updateStatus === 1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%', backgroundColor: '#C8E6C9', color: '#388E3C' }}>
          {errorMessage}
        </Alert>
      </Snackbar>}
      {updateStatus === 2 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%', backgroundColor: '#FFEBEE', color: '#B71C1C' }}>
          {errorMessage}
        </Alert>
      </Snackbar>}
      {updateStatus === -1 && <Snackbar open autoHideDuration={6000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%', backgroundColor: '#FFEBEE', color: '#B71C1C' }}>
          Error: {errorMessage}
        </Alert>
      </Snackbar>}
    </RootStyle>
  );
}