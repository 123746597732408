import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Breadcrumbs, Container, Link, Divider, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button
} from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import BuildIcon from '@mui/icons-material/Build';
import Page from '../components/Page';
import AppMonitorDashboard from '../components/SAMonitorComponents/AppMonitorDashboard';

export default function AppMonitor() {

    return (
        <Page title="AppMonitor">
            <Container maxWidth={false}>
                <div role="presentation" style={{ cursor: 'pointer', paddingBottom: '20px' }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit">
                            <BuildIcon />
                        </Link>
                        <Link underline="hover" color="inherit" href="sa-monitor">
                            App Monitor
                        </Link>
                    </Breadcrumbs>
                </div>
                <Divider/>
                <Grid>
                    <Grid item container>
                        <Grid item xs={12} sm={12} lg={12} md={12}>
                            <AppMonitorDashboard />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
