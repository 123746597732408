import React, { useState, useEffect } from 'react'
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    MenuItem,
    Checkbox,
    FormControl,
    Autocomplete,
    FormHelperText,
    InputLabel,
    Select,
    Chip
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import GetSiteGroupDetailsApiCall from '../../SiteGroupComponents/Components/GetSiteGroupDetailsApiCall';
import { UpdateApplicationDeatilsAPICall } from './UpdateApplictionDetailsAPICall';
import GetRegionAppDropdownAPI from './GetRegionAppDropdownAPI';


export default function EditSaasDialog(props) {
    const { app, appType, openEditDialog, setOpenEditDialog, setUpdateStatus, setOpen, setSeverStatus, setUpdateStatusMessage } = props;

    const [AppUrl, setAppUrl] = useState(app.app_url);
    const [AppName, setAppName] = useState(app.app_name);
    const [previousAppName, setPreviousAppName] = useState(app.app_name);

    const [groups, setGroups] = useState([]);
    const [groupNames, setGroupNames] = useState([]);
    const [groupIds, setGroupIds] = useState([]);
    // const [selectedGroupIds, setSelectedGroupIds] = useState(app.group_id == null ? [] : app.group_id);
    const [selectedGroupIds, setSelectedGroupIds] = useState(
        app.group_id && Array.isArray(app.group_id) ? app.group_id.filter((id) => id !== null) : []
    );
    const [selectedGroupNames, setSelectedGroupNames] = useState(app.group_name);

    const [AppUrlHelperText, setAppUrlHelperText] = useState("");
    const [AppNameHelperText, setAppNameHelperText] = useState("");
    const [AppGroupNameHelperText, setAppGroupNameHelperText] = useState("");
    const [warning, setWarning] = useState("");
    const [critical, setCritical] = useState("");
    const [chosenRegion, setChosenRegion] = useState(app.region_names);
    const [selectedRegionIds, setSelectedRegionIds] = useState(app.selected_probe_region_ids);
    const [monitoringOptions, setMonitoringOptions] = useState(app.app_monitoring_type);
    // const [monitoringValue, setMonitoringValue] = useState([]);
    const [monitoringValue, setMonitoringValue] = useState(() => {
        if (app.app_monitoring_type?.includes("Both")) return 3;
        if (app.app_monitoring_type?.includes("Probe")) return 2;
        if (app.app_monitoring_type?.includes("Agent")) return 1;
        return null;
    });
    const [probeRegionsData, setProbeRegionsData] = useState([]);
    const [latency, setLatency] = useState(app.threshold_latency);
    const [jitter, setJitter] = useState(app.threshold_jitter);

    const [latencyHelperText, setLatencyHelperText] = useState('');
    const [jitterHelperText, setJitterHelperText] = useState('');
    // AddApplicationAPICall(selectedGroupIds, AppUrl, AppName, AppType, ports, setAddAppStatus, setSeverStatus, protocol, portNumber,monitoringValue,selectedRegionIds,latency,jitter, setAppStatusMessage); 

    // UpdateApplicationDeatilsAPICall(app.app_id, previousAppName, appType, AppUrl, AppName, selectedGroupIds,monitoringValue,selectedRegionIds,latency,jitter, setUpdateStatus, setSeverStatus,setUpdateStatusMessage);

    const appurlRegex = /^(www\.)?([-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})\b([-a-zA-Z0-9@:%_~#?&//=]*)$/;
    const appNameRegex = /^[^"']*[A-Za-z0-9]$/;

    const handleUpdateClick = (e) => {
        if (appType === 0) {
            if (!AppUrl.match(appurlRegex)) {
                setAppUrlHelperText("App Url Should be Valid e.g. linkeye.io or www.google.com or 8.8.8.8");
                return;
            }
        }
        if (!AppName.match(appNameRegex)) {
            setAppNameHelperText("App Name Should be Valid");
            return;
        }
        if (!selectedGroupNames) {
            setAppGroupNameHelperText("Select the Group Name from the dropdown");
            return;
        }
        if (
            (monitoringOptions.includes("Probe") || monitoringOptions.includes("Both")) &&
            (!selectedRegionIds || selectedRegionIds.length === 0)
        ) {
            setAppGroupNameHelperText("Please select at least one region.");
            return;
        }
    
        // Call the API
        UpdateApplicationDeatilsAPICall(
            app.app_id,
            previousAppName,
            appType,
            AppUrl,
            AppName,
            selectedGroupIds,
            monitoringValue,
            selectedRegionIds,
            latency,
            jitter,
            setUpdateStatus,
            setSeverStatus,
            setUpdateStatusMessage
        );
    
        setOpen(true);
        setOpenEditDialog(false);
    };
    

   
    
   
      

    const handleGroupSelectionChange = (event, values, reason) => {
        const selectedIds = values.map((groupName) => {
            const selectedGroup = groups.find((group) => group.group_name === groupName);
            return selectedGroup ? selectedGroup.group_id : [];
        });

        const filteredIds = selectedIds.filter((id) => id !== null);

        setSelectedGroupIds(filteredIds);
        setSelectedGroupNames(values);

        if (reason === 'select-option') {
            const clickedGroupName = values[values.length - 1];
            const clickedGroup = groups.find((group) => group.group_name === clickedGroupName);

            if (clickedGroup) {
                setSelectedGroupIds((prevIds) => [...prevIds, clickedGroup.group_id]);
            }

        }
        setAppGroupNameHelperText("");

    };

   
    

    const handleOnClose = () => {
        setOpenEditDialog(false);
    }
    const handleOnChange = (e) => {
        switch (e.target.id) {
            case "app_url":
                if (appType === 0) {
                    if (!e.target.value.match(appurlRegex)) {
                        setAppUrlHelperText("App Url Should be Valid e.g. linkeye.io or www.google.com or 8.8.8.8");
                    } else {
                        setAppUrlHelperText("");
                    }
                    setAppUrl(e.target.value);
                }
                break;

            case "app_name":
                if (!e.target.value.match(appNameRegex)) {
                    setAppNameHelperText("App Name Should be Valid");
                } else {
                    setAppNameHelperText("");
                }
                setAppName(e.target.value);
                break;
            case "group_name":
                if (!selectedGroupNames || selectedGroupNames.length === 0) {
                    setAppGroupNameHelperText("Select the Group Name from the dropdown");
                } else {
                    setAppGroupNameHelperText("");
                }
                selectedGroupNames(e.target.value);
                break;

            default:
                setAppName(e.target.value);
                break;
        }
    };

    const _GetSiteGroupDetailsApiCall = async () => {
        await GetSiteGroupDetailsApiCall(setGroups, setWarning, setCritical);

        setGroupNames(groups.map(group => group.group_name));
    }

    const handleRegionSelection = (event) => {
        const selectedRegions = event.target.value;
    
        
        setChosenRegion(selectedRegions);
        const selectedIds = probeRegionsData
            .filter((region) => selectedRegions.includes(region.region_name))
            .map((region) => region.id);
    
        setSelectedRegionIds(selectedIds);
        setAppGroupNameHelperText(""); 
    };
    

   
      

    const handleOnChange1 = (e) => {
        const { name, value } = e.target;
        if (name === 'latency') {
            setLatency(value);
        } else if (name === 'jitter') {
            setJitter(value);
        } else {
            // handle other fields
        }
    };

    useEffect(() => {
        // Fetch probe regions data on component mount
        GetRegionAppDropdownAPI(setProbeRegionsData);
    }, []);

    useEffect(() => {
        _GetSiteGroupDetailsApiCall();
    }, [])

    useEffect(() => {
        setGroupNames(groups.map(group => group.group_name));
    }, [groups, selectedGroupNames]);

    useEffect(() => {
        if (groups.length > 0) {
            setGroupNames(groups.map(group => group.group_name));
        }
    }, [groups])
    
    const handleMonitoringChange = (event) => {
        const value = event.target.value;


        if (value.includes("Agent") && value.includes("Probe")) {
            setMonitoringOptions(["Both"]);
            setMonitoringValue(3);
        } else if (value.includes("Probe")) {
            setMonitoringOptions(["Probe"]);
            setMonitoringValue(2);
        } else if (value.includes("Agent")) {
            setMonitoringOptions(["Agent"]);
            setMonitoringValue(1);
        } else {
            setMonitoringOptions([]);
            setMonitoringValue(null);
        }
    };

    return (
        <>
            <Dialog open={openEditDialog} onClose={handleOnClose} fullWidth maxWidth="md" aria-labelledby="responsive-dialog-title" >
                <DialogTitle sx={{ py: 2 }}>
                    {appType === 0 ? ' Update Internal Application' : 'Update SaaS Application'}
                    <IconButton sx={{ float: 'right' }} onClick={handleOnClose}>
                        <CloseIcon sx={{ fontSize: '1.5rem' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ py: 3, px: 3 }}>
                    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: '1px' }}>
                        <Grid item xs={6}>
                            <TextField
                                label="App Url"
                                id="app_url"
                                value={AppUrl}
                                helperText={AppUrlHelperText}
                                error={!!AppUrlHelperText}
                                variant="outlined"
                                style={{ width: "400px" }}
                                onChange={handleOnChange}
                                required
                                disabled
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                label="App Name"
                                id="app_name"
                                value={AppName}
                                helperText={AppNameHelperText}
                                error={!!AppNameHelperText}
                                variant="outlined"
                                style={{ width: "400px" }}
                                onChange={handleOnChange}
                                required
                                disabled={appType === 1}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="monitoring-options-label">Monitoring Type</InputLabel>
                                <Select
                                    labelId="monitoring-options-label"
                                    label="Monitoring Type"
                                    id="monitoring-options"
                                    multiple
                                    disabled
                                    style={{ width: "400px" }}
                                    value={monitoringOptions?.includes("Both") ? ["Agent", "Probe"] : monitoringOptions}
                                    onChange={handleMonitoringChange}
                                    renderValue={(selected) => (
                                        <div>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} style={{ margin: 2 }} />
                                            ))}
                                        </div>
                                    )}
                                >
                                    <MenuItem value="Agent">
                                        <Checkbox checked={monitoringOptions?.includes("Agent") || monitoringOptions?.includes("Both")} />
                                        Agent
                                    </MenuItem>
                                    <MenuItem value="Probe">
                                        <Checkbox checked={monitoringOptions?.includes("Probe") || monitoringOptions?.includes("Both")} />
                                        Probe
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {(monitoringOptions.includes("Agent") || monitoringOptions.includes("Both")) && (
                        <Grid item xs={6}>
                            <FormControl fullWidth error={!!AppGroupNameHelperText}>
                                <Autocomplete
                                    id="group_name"
                                    multiple
                                    options={groupNames}
                                    disableCloseOnSelect
                                    value={selectedGroupNames}
                                    helperText={AppGroupNameHelperText}
                                    error={!!AppGroupNameHelperText}
                                    onChange={handleGroupSelectionChange}
                                    getOptionLabel={(option) => option}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                style={{ marginRight: 8 }}
                                                key={option}
                                                value={option}
                                                checked={selected}
                                            />
                                            {option}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Select Site Group"
                                            error={!!AppGroupNameHelperText}
                                        />
                                    )}
                                />
                                <FormHelperText>{AppGroupNameHelperText}</FormHelperText>
                            </FormControl>
                        </Grid>
                        )}
                        
                        {(monitoringOptions.includes("Probe") || monitoringOptions.includes("Both")) && (
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="region-select-label">Select Regions</InputLabel>
                                <Select
                                    labelId="region-select-label"
                                    label="Select Regions"
                                    id="region-select"
                                    multiple
                                    value={chosenRegion}
                                    onChange={handleRegionSelection}
                                    renderValue={(selected) => (
                                        <div style={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
                                            {selected.map((region) => (
                                                <Chip key={region} label={region} style={{ margin: 2 }} />
                                            ))}
                                        </div>
                                    )}
                                >
                                    {probeRegionsData.map((region) => (
                                        <MenuItem key={region.id} value={region.region_name}>
                                            <Checkbox checked={chosenRegion.includes(region.region_name)} />
                                            {region.region_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        )}
                        <Grid item xs={6}>
                            <TextField
                                label="Latency(in ms)*"
                                id="latency"
                                name="latency"
                                value={latency}
                                helperText={latencyHelperText}
                                error={!!latencyHelperText}
                                variant="outlined"
                                style={{ width: "400px" }}
                                onChange={handleOnChange1}
                                required
                            />
                        </Grid>

                        {/* Jitter Field */}
                        <Grid item xs={6}>
                            <TextField
                                label="Jitter(in ms)*"
                                id="jitter"
                                name="jitter"
                                value={jitter}
                                helperText={jitterHelperText}
                                error={!!jitterHelperText}
                                variant="outlined"
                                style={{ width: "400px" }}
                                onChange={handleOnChange1}
                                required
                            />
                        </Grid>



                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnClose}>Cancel</Button>
                    
                    <Button onClick={handleUpdateClick} >Update</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}