// import axios from 'axios';

// const GetApplicationRegionTableAPI = async (setSaasAppData) => {
//   const url = 'https://tb-api.linkeye.io/api/get_application_global_default_metric.php';

//   const payload = JSON.stringify({
//     auth_token: localStorage.getItem("auth_token"),
//     client_id: localStorage.getItem("cust_id")
//   });

//   try {
//     const response = await axios.post(url, payload, {
//       headers: { 'Content-Type': 'application/json' },
//     });

//     if (response.data && response.data.code === 1) {
//       const saasData = response.data.saas_app_data;
//       setSaasAppData(saasData);
//     } else {
//       setSaasAppData([]);  
//     }
//   } catch (error) {
//     console.error("Error fetching application metrics:", error);
//     setSaasAppData([]);  
//   }
// };

// export default GetApplicationMetricsAPICall;

// import axios from 'axios';

// const GetApplicationRegionTableAPI = async( setSaasAppData) => {

//  const url = `${process.env.REACT_APP_API_SERVER_URL}/get_application_global_present_metric.php`;


//    const payload = JSON.stringify({
//     "auth_token": localStorage.getItem("auth_token"),
//     "client_id": localStorage.getItem("cust_id"),
   
// });
    
    
//     return axios.post(url, payload).then((response) => {
//         if (response.data.code === 1) {
//             const saasdata = response.data.app_data;
//             setSaasAppData(saasdata);
//         }
//         else {
//             setSaasAppData(0)
//         }
//     });
// }
// export default GetApplicationRegionTableAPI;

import axios from 'axios';

const GetApplicationRegionTableAPI = async (setSaasAppData) => {
  const url = `${process.env.REACT_APP_API_SERVER_URL}/get_application_global_present_metric.php`;

  const payload = JSON.stringify({
    auth_token: localStorage.getItem("auth_token"),
    client_id: localStorage.getItem("cust_id"),
  });

  try {
    const response = await axios.post(url, payload);
    if (response.data.code === 1) {
      const saasData = response.data.application_data;
      setSaasAppData(saasData); 
    } else {
      setSaasAppData(null); 
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    setSaasAppData(null); 
  }
};

export default GetApplicationRegionTableAPI;




  
