
// import React, { useState, useEffect } from "react";
// import {
//   Grid,
//   Card,
//   CardContent,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   Button,
//   Typography,
// } from "@mui/material";
// import PlaceIcon from "@mui/icons-material/Place";
// import GetApplicationRegionTableAPI from "./GetApplicationRegionTableAPI";
// import GetAppRegionTableAPI from "./GetAppRegionTableAPI";

// export default function ApplicationRegionTable() {
//   const [saasAppData, setSaasAppData] = useState([]);
//   const [regionData, setRegionData] = useState(null);
//   const [selectedApp, setSelectedApp] = useState(null);
//   const [selectedRegion, setSelectedRegion] = useState(null);
//   const [appDialogOpen, setAppDialogOpen] = useState(false);
//   const [regionDialogOpen, setRegionDialogOpen] = useState(false);

//   useEffect(() => {
//     const fetchAppData = async () => {
//       await GetApplicationRegionTableAPI(setSaasAppData);
//     };
//     fetchAppData();
//   }, []);

//   const fetchRegionData = async (appId) => {
//     await GetAppRegionTableAPI(appId, setRegionData);
//   };

//   const handleAppViewRegions = (app) => {
//     setSelectedApp(app);
//     fetchRegionData(app.app_id);
//     setAppDialogOpen(true);
//   };

//   const handleRegionClick = (region) => {
//     setSelectedRegion(region);
//     setRegionDialogOpen(true);
//   };

//   const closeAppDialog = () => {
//     setAppDialogOpen(false);
//     setSelectedApp(null);
//   };

//   const closeRegionDialog = () => {
//     setRegionDialogOpen(false);
//     setSelectedRegion(null);
//   };

//   return (
//     <div style={{ padding: 16 }}>
//       <Typography variant="h4" gutterBottom>
//         Applications
//       </Typography>
//       <Grid container spacing={2}>
//         {saasAppData?.length > 0 ? (
//           saasAppData.map((app) => (
//             <Grid item xs={12} sm={6} md={4} lg={3} key={app.app_id}>
//               <Card
//                 style={{
//                   height: "100%",
//                   backgroundColor:
//                     selectedApp?.app_id === app.app_id ? "#d0e6f8" : "#fff",
//                   border:
//                     selectedApp?.app_id === app.app_id
//                       ? "2px solid #007BFF"
//                       : "1px solid #ccc",
//                   boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
//                   transition: "transform 0.3s, background-color 0.3s",
//                   cursor: "pointer",
//                 }}
//               >
//                 <CardContent style={{ textAlign: "center" }}>
//                   <Typography
//                     variant="h6"
//                     style={{
//                       marginBottom: 12,
//                       fontWeight: selectedApp?.app_id === app.app_id ? "bold" : "normal",
//                     }}
//                   >
//                     {app.app_name}
//                   </Typography>
//                   <Button
//                     variant="outlined"
//                     size="small"
//                     onClick={() => handleAppViewRegions(app)}
//                     style={{
//                       color: "#007BFF",
//                       border: "none",
//                       fontWeight: "bold",
//                       textTransform: "none",
//                       transition: "background-color 0.3s",
//                     }}
//                   >
//                     View Regions
//                   </Button>
//                 </CardContent>
//               </Card>
//             </Grid>
//           ))
//         ) : (
//           <Typography>No Applications Available</Typography>
//         )}
//       </Grid>


//       <Dialog
//         open={appDialogOpen}
//         onClose={closeAppDialog}
//         maxWidth="md"
//         fullWidth
//       >
//         <DialogTitle>
//           Regions for {selectedApp?.app_name || "Selected App"}
//         </DialogTitle>
//         <DialogContent>
//           {regionData && regionData.regions?.length > 0 ? (
//             <Grid container spacing={2}>
//               {regionData.regions.map((region) => (
//                 <Grid item xs={12} sm={6} md={4} key={region.region_id}>
//                   <Card
//                     onClick={() => handleRegionClick(region)}
//                     style={{
//                       cursor: "pointer",
//                       padding: 16,
//                       textAlign: "center",
//                       backgroundColor:
//                         selectedRegion?.region_id === region.region_id
//                           ? "#d1f2d5"
//                           : "#fff",
//                       border:
//                         selectedRegion?.region_id === region.region_id
//                           ? "2px solid #28a745"
//                           : "1px solid #ccc",
//                       transition: "transform 0.3s, background-color 0.3s",
//                       boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
//                     }}
//                   >
//                     <CardContent>
//                       <Typography
//                         variant="h6"
//                         style={{
//                           display: "flex",
//                           alignItems: "center",
//                           justifyContent: "center",
//                         }}
//                       >
//                         <PlaceIcon
//                           style={{
//                             marginRight: 8,
//                             color:
//                               selectedRegion?.region_id === region.region_id
//                                 ? "#28a745"
//                                 : "#0000FF",
//                           }}
//                         />
//                         {region.region_name || "No region available"}
//                       </Typography>
//                       <Button
//                         variant="outlined"
//                         size="small"
//                         style={{
//                           marginTop: 8,
//                           color: "#28a745",
//                           border: "none",
//                           fontWeight: "bold",
//                           textTransform: "none",
//                           transition: "background-color 0.3s",
//                         }}
//                       >
//                         View Graphs
//                       </Button>
//                     </CardContent>
//                   </Card>
//                 </Grid>
//               ))}
//             </Grid>
//           ) : (
//             <Typography>No Regions Available</Typography>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={closeAppDialog} color="primary">
//             Close
//           </Button>
//         </DialogActions>
//       </Dialog>


//       {selectedRegion && (
//         <Dialog
//           open={regionDialogOpen}
//           onClose={closeRegionDialog}
//           maxWidth="lg"
//           fullWidth
//         >
//           <DialogTitle>
//             Graphs for {selectedRegion.region_name || "Selected Region"}
//           </DialogTitle>
//           <DialogContent>
//             <Grid container spacing={2}>
//               <Grid item xs={12}>
//                 <Card>
//                   <CardContent>
//                     <Typography variant="h5">Graph 1</Typography>
//                     <div
//                       style={{
//                         height: "300px",
//                         backgroundColor: "#f5f5f5",
//                         display: "flex",
//                         alignItems: "center",
//                         justifyContent: "center",
//                         color: "#999",
//                         fontSize: "18px",
//                       }}
//                     >
//                       Graph 1 Content
//                     </div>
//                   </CardContent>
//                 </Card>
//               </Grid>
//               <Grid item xs={12}>
//                 <Card>
//                   <CardContent>
//                     <Typography variant="h5">Graph 2</Typography>
//                     <div
//                       style={{
//                         height: "300px",
//                         backgroundColor: "#f5f5f5",
//                         display: "flex",
//                         alignItems: "center",
//                         justifyContent: "center",
//                         color: "#999",
//                         fontSize: "18px",
//                       }}
//                     >
//                       Graph 2 Content
//                     </div>
//                   </CardContent>
//                 </Card>
//               </Grid>
//             </Grid>
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={closeRegionDialog} color="primary">
//               Close
//             </Button>
//           </DialogActions>
//         </Dialog>
//       )}
//     </div>
//   );
// }



import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
  Popover,
  Tooltip,
  TextField,
  InputAdornment,
  Divider,
  Box,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
  CircularProgress, // For global search
} from "@mui/material";
import PlaceIcon from "@mui/icons-material/Place";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import VerifiedUser from '@mui/icons-material/VerifiedUser';
import SearchIcon from '@mui/icons-material/Search';
import Event from '@mui/icons-material/Event';
import { AccessTime, Speed, ShowChart, Dns } from '@mui/icons-material';
import GetApplicationRegionTableAPI from "./GetApplicationRegionTableAPI";
import GetAppRegionTableAPI from "./GetAppRegionTableAPI";
import AppRegionGraph from "./AppRegionGraph";
import timeArray from './TimeData';

export default function ApplicationRegionTable() {
  const [saasAppData, setSaasAppData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [regionData, setRegionData] = useState(null);
  const [selectedApp, setSelectedApp] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [appDialogOpen, setAppDialogOpen] = useState(false);
  const [regionDialogOpen, setRegionDialogOpen] = useState(false);
  const [popoverContent, setPopoverContent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [time, setTime] = useState(timeArray[0].value);

  const selectedAppId = selectedApp?.app_id || "N/A";
  const selectedRegionId = selectedRegion?.region_id || "N/A";


  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    const fetchAppData = async () => {
      try {
        await GetApplicationRegionTableAPI(setSaasAppData);
      } catch (error) {
        console.error("Error fetching application data:", error);
      }
    };
    fetchAppData();
  }, []);

  useEffect(() => {
    setFilteredData(
      saasAppData.filter((app) =>
        app.app_name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [saasAppData, searchTerm]);

  const fetchRegionData = async (appId, selectedTime) => {
    try {
      await GetAppRegionTableAPI(appId, selectedTime || time, setRegionData);
    } catch (error) {
      console.error("Error fetching region data:", error);
    }
  };

  const handleAppViewRegions = (app) => {
    setSelectedApp(app);
    fetchRegionData(app.app_id);
    setAppDialogOpen(true);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePopoverOpen = (event, app) => {
    setPopoverContent(app);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverContent(null);
  };

  const open = Boolean(anchorEl);

  const handleRegionClick = (region) => {
    setSelectedRegion(region);
    setRegionDialogOpen(true);
  };

  const closeAppDialog = () => {
    setAppDialogOpen(false);
    setSelectedApp(null);
  };

  const closeRegionDialog = () => {
    setRegionDialogOpen(false);
    setSelectedRegion(null);
  };

  const handleTimeChange = (event) => {
    const selectedTime = event.target.value;
    setTime(selectedTime);

    if (selectedApp) {
      fetchRegionData(selectedApp.app_id, selectedTime);
    }
  };

  function parsedReason(reason) {
    const metricsString = reason.match(/Latency: [\d.]+ms?,? Packet_loss: [\d.]+%,? Jitter: [\d.]+ms?/);
    if (!metricsString) {
      return {};
    }
    const result = {};
    metricsString[0].split(',').forEach((part) => {
      const [key, value] = part.split(':').map((str) => str.trim());
      if (key && value) {
        let parsedValue = value;
        if (parsedValue.includes('ms')) {
          parsedValue = parsedValue.replace('ms', '').trim();
        } else if (parsedValue.includes('%')) {
          parsedValue = parsedValue.replace('%', '').trim();
        }
        result[key.toLowerCase().replace(/_/g, '')] = parseFloat(parsedValue);
      }
    });

    return result;
  }

console.log(regionData,"regionData");


  return (
    <div style={{ padding: 16 }}>



      <div
        style={{
          height: "500px",
          overflowY: "auto",
          border: "1px solid #ccc",
          borderRadius: "8px",
          padding: "24px",

        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 16 }}>
          <Typography variant="h4" gutterBottom>
            Applications
          </Typography>
          <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
            <TextField
              variant="outlined"
              placeholder="Search Applications"
              size="small"
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              style={{ width: "300px" }}
            />
          </div>
        </div>
        <Divider />

        {/* <Grid container spacing={2} sx={{ mt: '5px' }}>
          {filteredData?.length > 0 ? (
            filteredData.map((app) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={app.app_id}>
                <Card
                  style={{
                    height: "100%",
                    backgroundColor:
                      selectedApp?.app_id === app.app_id ? "#d0e6f8" : "#fff",
                    border:
                      selectedApp?.app_id === app.app_id
                        ? "2px solid #007BFF"
                        : "1px solid #ccc",
                    boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
                    transition: "transform 0.3s, background-color 0.3s",
                    cursor: "pointer",
                    position: "relative",
                  }}
                >
                  <Tooltip title="More Details" arrow>
                    <IconButton
                      size="small"
                      onClick={(e) => handlePopoverOpen(e, app)}
                      style={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                      }}
                    >
                      <InfoIcon color="primary" />
                    </IconButton>
                  </Tooltip>

                  <CardContent style={{ textAlign: "center" }}>
                    <Typography
                      variant="h6"
                      style={{
                        marginBottom: 12,
                        fontWeight:
                          selectedApp?.app_id === app.app_id ? "bold" : "normal",
                      }}
                    >
                      {app.app_name}
                    </Typography>
                    <Tooltip title="Click here to view regions" arrow>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleAppViewRegions(app)}
                        style={{
                          color: "#007BFF",
                          border: "none",
                          fontWeight: "bold",
                          textTransform: "none",
                          transition: "background-color 0.3s",
                        }}
                      >
                        View Regions
                      </Button>
                    </Tooltip>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px", // Adjust height as needed
              }}
            >
              <Typography
                variant="h6"
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  color: "red",
                }}
              >
                No Applications Available
              </Typography>
            </Grid>
          )}
        </Grid> */}
        <Grid container spacing={2} sx={{ mt: '5px' }}>
  {isLoading && (
    <Grid
      item
      xs={12}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "200px", 
      }}
    >
      <CircularProgress color="primary" />
    </Grid>
  )}

  {!isLoading && filteredData?.length > 0 && (
    filteredData.map((app) => (
      <Grid item xs={12} sm={6} md={4} lg={3} key={app.app_id}>
        <Card
          style={{
            height: "100%",
            backgroundColor:
              selectedApp?.app_id === app.app_id ? "#d0e6f8" : "#fff",
            border:
              selectedApp?.app_id === app.app_id
                ? "2px solid #007BFF"
                : "1px solid #ccc",
            boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
            transition: "transform 0.3s, background-color 0.3s",
            cursor: "pointer",
            position: "relative",
          }}
        >
          <Tooltip title="More Details" arrow>
            <IconButton
              size="small"
              onClick={(e) => handlePopoverOpen(e, app)}
              style={{
                position: "absolute",
                top: 8,
                right: 8,
              }}
            >
              <InfoIcon color="primary" />
            </IconButton>
          </Tooltip>

          <CardContent style={{ textAlign: "center" }}>
            <Typography
              variant="h6"
              style={{
                marginBottom: 12,
                fontWeight:
                  selectedApp?.app_id === app.app_id ? "bold" : "normal",
              }}
            >
              {app.app_name}
            </Typography>
            <Tooltip title="Click here to view regions" arrow>
              <Button
                variant="outlined"
                size="small"
                onClick={() => handleAppViewRegions(app)}
                style={{
                  color: "#007BFF",
                  border: "none",
                  fontWeight: "bold",
                  textTransform: "none",
                  transition: "background-color 0.3s",
                }}
              >
                View Regions
              </Button>
            </Tooltip>
          </CardContent>
        </Card>
      </Grid>
    ))
  )}

  {!isLoading && filteredData?.length === 0 && (
    <Grid
      item
      xs={12}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "200px", 
      }}
    >
      <Typography
        variant="h6"
        style={{
          fontWeight: "bold",
          textAlign: "center",
          color: "red",
        }}
      >
        No Applications Available
      </Typography>
    </Grid>
  )}
</Grid>


      </div>




      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div style={{ padding: 16 }}>
          {saasAppData
            ?.filter((app) => app.app_id === popoverContent?.app_id)
            ?.map((app) => (
              <div key={app.app_id}>
                {app?.regions?.map((region, regionIndex) => {

                  let statusLabel = "Unknown";
                  let statusColor = "default";

                  switch (region.status) {
                    case 1:
                      statusLabel = "Good";
                      statusColor = "success";
                      break;
                    case 2:
                      statusLabel = "Partial Good";
                      statusColor = "warning";
                      break;
                    case 3:
                      statusLabel = "Poor";
                      statusColor = "orange";
                      break;
                    case 4:
                      statusLabel = "Bad";
                      statusColor = "error";
                      break;
                    default:
                      statusLabel = "Unknown";
                      statusColor = "default";
                  }

                  return (
                    <div key={regionIndex} style={{ marginBottom: 16 }}>
                      <Typography variant="body1">
                        <strong>Status:</strong>{" "}
                        <Chip label={statusLabel}
                          color={statusColor}
                          sx={{
                            fontSize: "0.75rem",
                            padding: "4px 8px",
                            height: "24px",
                          }}
                        />
                      </Typography>
                      <Typography variant="body1">
                        <strong>Analysis:</strong> {region.analysis || "Not available"}
                      </Typography>
                      <Typography variant="body1">
                        <strong>Reason: </strong>
                        {region?.reason ? (
                          <>
                            {(() => {
                              const parsedReasonData = parsedReason(region.reason);
                              return (
                                <>
                                  <div style={{ display: 'inline', marginRight: '16px' }}>
                                    <strong>Latency:</strong> {parsedReasonData?.latency || "N/A"} ms
                                  </div>
                                  <div style={{ display: 'inline', marginRight: '16px' }}>
                                    <strong>Packet Loss:</strong> {parsedReasonData?.packetloss}%
                                  </div>
                                  <div style={{ display: 'inline' }}>
                                    <strong>Jitter:</strong> {parsedReasonData?.jitter || "N/A"} ms
                                  </div>
                                </>
                              );
                            })()}
                          </>
                        ) : "No reason provided"}
                      </Typography>

                      <hr style={{ margin: "8px 0" }} />
                    </div>
                  );
                })}
              </div>
            ))}
        </div>
      </Popover>


      <Dialog open={appDialogOpen} onClose={closeAppDialog} maxWidth="md" fullWidth>
        <DialogTitle style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          Regions for {selectedApp?.app_name || "Selected App"}
          <Tooltip title="Close" arrow>
            <IconButton onClick={closeAppDialog} style={{ color: "#000" }}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          {regionData && regionData.regions?.length > 0 ? (
            <Grid container spacing={2}>
              {regionData.regions.map((region) => (
                <Grid item xs={12} sm={6} md={4} key={region.region_id}>
                  <Card
                    onClick={() => handleRegionClick(region)}
                    style={{
                      cursor: "pointer",
                      padding: 16,
                      textAlign: "center",
                      backgroundColor:
                        selectedRegion?.region_id === region.region_id
                          ? "#d1f2d5"
                          : "#fff",
                      border:
                        selectedRegion?.region_id === region.region_id
                          ? "2px solid #28a745"
                          : "1px solid #ccc",
                      transition: "transform 0.3s, background-color 0.3s",
                      boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h6"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <PlaceIcon
                          style={{
                            marginRight: 8,
                            color:
                              selectedRegion?.region_id === region.region_id
                                ? "#28a745"
                                : "#0000FF",
                          }}
                        />
                        {region.region_name || "No region available"}
                      </Typography>
                      <Tooltip title="Click here to view graphs" arrow>
                        <Button
                          variant="outlined"
                          size="small"
                          style={{
                            marginTop: 8,
                            color: "#28a745",
                            border: "none",
                            fontWeight: "bold",
                            textTransform: "none",
                            transition: "background-color 0.3s",
                          }}
                        >
                          View Graphs
                        </Button>
                      </Tooltip>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px", 
              }}
            >
              <Typography
                variant="h6"
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  color: "red",
                }}
              >
                No Regions Available
              </Typography>
            </Grid>
          )}
        </DialogContent>
      </Dialog>


      {selectedRegion && (
        <Dialog open={regionDialogOpen} onClose={closeRegionDialog} maxWidth="lg" fullWidth>
          <DialogTitle style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            Graphs for {selectedRegion.region_name || "Selected Region"}
            <Tooltip title="Close" arrow>
              <IconButton onClick={closeRegionDialog} style={{ color: "#000" }}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>

                <Card>
                  <CardContent>
                    <div
                      style={{
                        height: "550px",
                        backgroundColor: "white",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-between",
                        color: "#999",
                        fontSize: "18px",
                        padding: "10px",
                        position: "relative",
                      }}
                    >


                      <Box
                        sx={{
                          position: "absolute",
                          top: 10,
                          right: 10,
                          display: "flex",
                          flexDirection: "row",
                          gap: 2,
                        }}
                      >

                        {saasAppData
                          ?.filter((app) => app.app_id === selectedAppId)
                          ?.flatMap((app) =>
                            app.regions.filter((region) => region.region_id === selectedRegionId)
                          )
                          ?.map((region) => (
                            [
                              { icon: AccessTime, label: "Lasted Updated", value: region?.ent },
                              { icon: VerifiedUser, label: "SSL Issuer Name", value: region?.ssl_certificate_issuer },
                              { icon: Event, label: "SSL Expiry Date", value: region?.ssl_expiration_date },
                              { icon: Dns, label: "Response Time", value: region?.response_time },
                            ].map((card, index) => (
                              <Card
                                key={index}
                                sx={{
                                  width: 120,
                                  padding: 1,
                                  textAlign: "center",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <card.icon sx={{ fontSize: 14, color: index % 2 === 0 ? "#007BFF" : "#FF6F61" }} />
                                <Typography variant="body2" sx={{ fontSize: 10 }}>
                                  {card.label}
                                </Typography>
                                <Typography sx={{ fontSize: "0.7rem", color: "primary.main", lineHeight: 1 }}>
                                  {card.value || "N/A"}
                                </Typography>
                              </Card>
                            ))
                          ))}
                      </Box>
                      <Box sx={{ mb: 1, width: "100%" }}>
                        <FormLabel sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>Select Time</FormLabel>
                        <RadioGroup
                          row
                          name="time-radio-group"
                          value={time}
                          onChange={handleTimeChange}
                          sx={{
                            "& .MuiFormControlLabel-root": { m: 0.5 },
                            "& .MuiRadio-root": { p: 0.5 },
                          }}
                        >
                          {timeArray.map((data) => (
                            <FormControlLabel
                              key={data.value}
                              value={data.value}
                              control={<Radio size="small" />}
                              label={<Typography sx={{ fontSize: "0.7rem" }}>{data.label}</Typography>}
                            />
                          ))}
                        </RadioGroup>
                      </Box>


                      <Box sx={{ flex: 1, width: "100%" }}>
                        <AppRegionGraph regionData={regionData} selectedRegion={selectedRegion} time={time} />
                      </Box>
                    </div>
                  </CardContent>
                </Card>

              </Grid>

            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
