import { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
// @mui
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, IconButton, TableRow } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
// components

import Scrollbar from '../../Scrollbar';
// mock
import devices from '../../../_mock/devices';
import ISPDownTableToolbar from './ISPDownTableToolbar';
import ISPDownTableHead from './ISPDownTableHead ';
import TroubleshootComponent from './TroubleshootComponents/TroubleshootComponent';
import { ISPDownTableDataAPI } from '../ISPManagamentRestCalls/ISPDownTableDataAPI';
import TroubleshootInsightsDialog from './DialogComponents/TroubleshootInsightsDialog';
import { useGlobalState } from '../../../globalHooks/GlobalState';

const TABLE_HEAD = [
  { id: 'no', label: 'No', alignRight: false },
  { id: 'custName', label: 'Customer ', alignRight: false },
  { id: 'Circuit', label: 'Circuit ID', alignRight: false },
  { id: 'Site', label: 'Site', alignRight: false },
  { id: 'Vendor', label: 'Vendor', alignRight: false },
  { id: 'IP', label: 'Public IP', alignRight: false },
  { id: 'Internal IP', label: 'Internal IP', alignRight: false },
  { id: 'DG IP', label: 'DG IP', alignRight: false },
  { id: 'Uptime', label: 'Down-Time', alignRight: false },
  { id: 'insights', label: 'Co-Pilot Summary', alignRight: false },
  { id: 'Analysis', label: 'Insights', alignRight: false },
  { id: 'View', label: 'View', alignRight: false },
];

export default function ISPDownTable() {

  const { globalState, setGlobalState } = useGlobalState();
  const { buState, setBuState } = useGlobalState();

  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [downIspData, setDownIspData] = useState([]);
  const [openInsightDialog, setOpenInsightDialog] = useState(false);
  const [value, setValue] = useState(null);

  const mspClientId = localStorage.getItem("cust_id")
  const regClientId = localStorage.getItem("reg_cust_id")
  const mspStatus = localStorage.getItem("msp_status")

  const onInsightClick = (e, val) => {
    setValue(val);
    setOpenInsightDialog(true);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = devices.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const _ISPDownTableDataAPI = async () => {
    await ISPDownTableDataAPI(setDownIspData, buState);
  }
  useEffect(() => {
    _ISPDownTableDataAPI();
    setInterval(() => {
      _ISPDownTableDataAPI();
    }, 1000 * 60);
  }, [globalState, buState]);

  useEffect(() => {
    _ISPDownTableDataAPI();
  }, [])

  const convertMinToHourMinutes = (minutes) => {
    const d = Math.round(Math.floor(minutes / (24 * 60)));
    const h = Math.round(Math.floor((minutes % (24 * 60)) / 60));
    const m = Math.round((minutes % (24 * 60)) % 60);
    return `${d}days, ${h}hours, ${m}minutes`;
  }


  return (
    <Grid item lg={12} sm={12} xs={12} md={12}>
      <Paper elevation={8}>
        <ISPDownTableToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <ISPDownTableHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD.filter(tableCell => {
                  return (
                    (mspClientId === regClientId || tableCell.id !== 'custName') &&
                    (mspStatus !== "0" || tableCell.id !== 'custName')
                  );
                })}
                rowCount={devices.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {downIspData.map((list, index) => (
                  <TableRow key={index} hover>
                    <TableCell>{index + 1}</TableCell>
                    {mspClientId === regClientId && mspStatus === "1" ? <TableCell>{list.cust_name}</TableCell> : null}
                    <TableCell>{list.circuit_id}</TableCell>
                    <TableCell>{list.location}</TableCell>
                    <TableCell>{list.vendor}</TableCell>
                    <TableCell>{list.public_ip}</TableCell>
                    <TableCell>{list.internal_ip}</TableCell>
                    <TableCell>{list.default_gateway}</TableCell>
                    <TableCell>{convertMinToHourMinutes(list.down_cnt)}</TableCell>
                    <TableCell>
                      {list.troubleshoot_action === '' || list.troubleshoot_action === null ? (
                        'Analysing...'
                      ) : (
                        list.troubleshoot_action.split('.').map((line, index) => {
                          const trimmedLine = line.trim();
                          if (index === 0) {
                            return (
                              <Fragment key={index}>
                                <strong>RCA:</strong> {trimmedLine}
                                <br />
                              </Fragment>
                            );
                          } if (index === 1) {
                            return (
                              <Fragment key={index}>
                                <strong>Plan Of Action:</strong> {trimmedLine}
                              </Fragment>
                            );
                          }
                          return null;
                        })
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton variant="outlined" color="primary" onClick={(e) => { onInsightClick(e, list) }}>
                        <TipsAndUpdatesIcon style={{ color: '#FFD400' }} />
                      </IconButton>
                    </TableCell>
                    <TableCell><Link to={{ pathname: `/dashboard/isp-management/isp-down/${list.isp_wan_id}` }}><IconButton variant="outlined" color="primary"><VisibilityIcon /></IconButton></Link></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Paper>
      {openInsightDialog && <TroubleshootInsightsDialog value={value} openInsightDialog={openInsightDialog} setOpenInsightDialog={setOpenInsightDialog} />}
    </Grid>
  );
}
