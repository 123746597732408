import axios from 'axios';

// API CALL CODE HERE

const CheckWanIpReachableStatusApiCall = async(ispWanId, setWanIpLanStatus, setGotWanIpLanStatus, setWanIpReachableMessage, setContinueInternetRechStatus, setDetailWanIpReachableMessage, setRcaWanIpReachableMessage,setWanPriority,setWanCode) =>  {
  const baseUrl = `${process.env.REACT_APP_API_SERVER_URL}/check_wan_ip_configuration_troubleshoot.php`;

  const payload = JSON.stringify({
    "auth_token": localStorage.getItem("auth_token"),
    "client_id":localStorage.getItem("cust_id"),
    "isp_wan_id": ispWanId
  });

  return axios.post(baseUrl, payload).then((response)=>{
    
    if(response.data.code === 1 ){
        setWanIpLanStatus(true)
        setContinueInternetRechStatus(true)
        setWanCode(1)

    }
    else if(response.data.code === 3)
      {
        setWanIpLanStatus(false)
        setContinueInternetRechStatus(true)
        setWanPriority(true)
        setWanCode(3)
        // setRedFailInternet(true)
        // setRcaWanIpReachableMessage(response.data.rca_message)

    }
    else
    {
        setWanIpLanStatus(false)
        setContinueInternetRechStatus(false)
        setRcaWanIpReachableMessage(response.data.rca_message)

    }
    setGotWanIpLanStatus(true)
    setWanIpReachableMessage(response.data.message)
    setDetailWanIpReachableMessage(response.data.detailed_message)
    // setRcaWanIpReachableMessage(response.data.rca_message)
  })
}

export default CheckWanIpReachableStatusApiCall;
